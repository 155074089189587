import request from '@/common/request'
import { ConfiguracaoDaConta, ConfiguracaoDaContaForm } from '@/models/ConfiguracaoDaConta'

export const putConfiguracaoDaConta = (configuracaoDaContaForm: ConfiguracaoDaContaForm): Promise<ConfiguracaoDaConta> =>
	request.put(`/configuracoes-da-conta`, configuracaoDaContaForm)

export const findConfiguracaoDaConta = async (): Promise<ConfiguracaoDaConta> =>
	request.get(`/configuracoes-da-conta`)

export const validarConta = (email?: string, subdominio?: string): Promise<string> =>
	request.get(`/validation`, {
		params: {
			email: email,
			subdominio: subdominio,
		},
	})