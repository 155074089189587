import { Inject } from 'inversify-props'
import type { RegraDeImpostoServiceAdapter } from '../adapter'

export class DeleteRegraDeImpostoUseCase {
	@Inject('RegraDeImpostoServiceAdapter')
	private regraService!: RegraDeImpostoServiceAdapter

	delete = async (id: string) => await this.regraService.delete(id)

	deleteItem = async (idItem: string) =>
		await this.regraService.deleteItem(idItem)
}
