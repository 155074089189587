import 'reflect-metadata'
import { injectable } from 'inversify-props'
import { CESTServiceAdapter } from '@/usecases'
import { getCEST, findCEST } from '@/api/impostos'
import { AxiosRequestConfig } from 'axios'

@injectable()
export class CESTService implements CESTServiceAdapter {
	get = async (id: string) => await getCEST(id)
	find = async (params?: any, config?: AxiosRequestConfig) =>
		await findCEST(params, config)
}
