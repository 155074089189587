import { injectable } from "inversify-props";
import { buscarPdvSemTurno, editarSituacaoPdv  } from '@/api/pdv'
import { PdvServiceAdapter } from "@/usecases";
import { PontoDeVenda, PontoDeVendaComTurno } from "@/models";

@injectable()
export class PdvService implements PdvServiceAdapter {

	editarSituacaoPdv = async (pdvId: string, aberto: boolean): Promise<PontoDeVenda> =>
		await editarSituacaoPdv(pdvId, aberto)

	buscarPdvSemTurno = async (lojaId: string): Promise<PontoDeVendaComTurno[]> =>
		await buscarPdvSemTurno(lojaId)
}