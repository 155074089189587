import "reflect-metadata"
import { injectable } from 'inversify-props'
import { Usuario } from '@/models';
import {
	get,
	find,
	update,
	create,	
	patch,
	remove,
	getUsuarioLogado,
	findAllVendedores,
	existeSomenteUmaLojaVinculadaComUsuario,
} from '@/api/usuarios';
import { UsuarioServiceAdapter } from '@/usecases';
import { AxiosRequestConfig } from 'axios';

@injectable()
export class UsuarioService implements UsuarioServiceAdapter {	
	get = async (id: string) => await get(id)
	find = async(params: any, config?: AxiosRequestConfig) => await find(params, config)
	findNew = async(params: any, config?: AxiosRequestConfig) => await find(params, config)
	create = async (usuario: Usuario) => await create(usuario)
	update = async (usuario: Usuario) => await update(usuario)	
	patch = async (usuario: Usuario) => await patch(usuario)
	remove = async (id: string) => await remove(id)
	getUsuarioLogado = async (token?: string) => await getUsuarioLogado(token)
	findAllVendedores = async(params: any, config?: AxiosRequestConfig) => await findAllVendedores(params, config)
	existeSomenteUmaLojaVinculadaComUsuario = async (usuarioId: string) => await existeSomenteUmaLojaVinculadaComUsuario(usuarioId)
}