import 'reflect-metadata'
import { injectable } from 'inversify-props'
import { NCMServiceAdapter } from '@/usecases'
import { getNCM, findNCM } from '@/api/impostos'
import { AxiosRequestConfig } from 'axios'

@injectable()
export class NCMService implements NCMServiceAdapter {
	get = async (id: string) => await getNCM(id)
	find = async (params?: any, config?: AxiosRequestConfig) => await findNCM(params, config)
	create = async () => { throw new Error("Não implementado") }
	patch = async () => { throw new Error("Não implementado") }
	remove = async () => { throw new Error("Não implementado") }
	update = async () => { throw new Error("Não implementado") }
}
