import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import pdfMake from 'pdfmake/build/pdfmake'
import JsBarcode from 'jsbarcode'
import type { Content, TDocumentDefinitions } from 'pdfmake/interfaces'
import vfs from 'pdfmake/build/vfs_fonts.js';
import { formatarCnpjOuCpf } from './formatadores';
import { ValeTroca } from '@/models/venda/ValeTroca';


window.pdfMake.vfs = vfs.pdfMake.vfs;

function textToBase64Barcode(text: string){
	const canvas = document.createElement("canvas");
	JsBarcode(canvas, text, {
		height: 60,
		width: 1,
		displayValue: false,
	});
	return canvas.toDataURL("image/png");
}

export async function gerarValeTrocas(valeTrocas: ValeTroca[]): Promise<string> {
	let valeTrocasPorQuant: Content[] = []
	valeTrocas.forEach(valeTroca => {
		const umValeTroca = Array.from(
			{ length: valeTroca.quantidade},
			() => gerarValeTroca(valeTroca),
		)
		valeTrocasPorQuant = [
			...valeTrocasPorQuant,
			...umValeTroca,
			
		]
	})
	let linhaValeTrocas: Content[][] = []
	const tamanho = 1;
	for(let i = 0; i < valeTrocasPorQuant.length; i += tamanho) {
		const algunsValeTroca = valeTrocasPorQuant.slice(i, i + tamanho)
		const espacosVazios: Content[] = Array.from(
			{ length: tamanho - algunsValeTroca.length },
			() => [],
		)
		linhaValeTrocas = [
			...linhaValeTrocas,
			[
				...algunsValeTroca,
				...espacosVazios,
			],
		]
	}

	const docDefinition: TDocumentDefinitions = {
		pageSize: {
			width: 230,
			height: 'auto',
		},
		pageMargins: [1, 1, 1, 1],
		pageOrientation: 'portrait',
		content: {
			table: {
				body: linhaValeTrocas,
			},
			layout: 'noBorders',
		},
		
	}
	return new Promise(resolve => {
		pdfMake.createPdf(docDefinition).getBase64((result) => resolve(result))
	})
}




export function gerarValeTroca (valeTroca: ValeTroca): Content[] {
	return [
		{
			columns: [
				{ text: `CNPJ ${formatarCnpjOuCpf(valeTroca.loja.cnpj)}\n\n`, fontSize: 9, italics: true,  alignment: 'center'},
			
			],
		},
		{
			columns: [
				{ text: 'Vale Troca\n', fontSize: 15, alignment: 'center'},
				
			],
		},
		{
			columns: [
				{ text: 'Venda N°: ' + valeTroca.venda.identificador, alignment: 'center', fontSize: 11},

			],
		},
		{
			columns: [
				{ text: valeTroca.venda.dataHora ? dateTimeToPtBrFormat(valeTroca.venda.dataHora) : '', alignment: 'center', fontSize: 9},
				
			],
		},
		{
			columns: [
				{ image: textToBase64Barcode(valeTroca.venda.identificador || ''), alignment: 'center'},
			],
		},

	]

}
