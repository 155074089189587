import { Inject } from 'inversify-props';

export interface CepResponse {
	cep: string
	logradouro: string
	complemento: string
	bairro: string
	localidade: string
	uf: string
	ibge: string
}

export interface CepResponseError {
	erro: boolean
}

export interface BuscaCepServiceAdapter {
	busca: (cep: string) => Promise<CepResponse>
}
export class BuscaCepUseCase {
	@Inject("BuscaCepServiceAdapter")
	private buscaCepService!: BuscaCepServiceAdapter

	busca = async (cep: string): Promise<CepResponse> => {
		const cepNormalizado = cep.replace(/\D/g, '')
		return await this.buscaCepService.busca(cepNormalizado)
	}
}

