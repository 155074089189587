import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotasDeLojas: RouteConfig[] = [
	{
		path: 'lojas/novo',
		name: 'Nova Loja',
		components: {
			default: () =>
				import('@/views/application/lojas/EdicaoDeLoja/EdicaoDeLoja.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			default: { novo: true },
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
	},
	{
		path: 'lojas/:id',
		name: 'Loja',
		components: {
			default: () =>
				import('@/views/application/lojas/EdicaoDeLoja/EdicaoDeLoja.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			default: true,
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
	},
	{
		path: 'lojas',
		name: 'Lojas',
		components: {
			default: () =>
				import('@/views/application/lojas/TelaDeLojas.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
	},
]

export default rotasDeLojas