import request from "@/common/request"
import { Page, NotaEmTransito } from "@/models"
import { AxiosRequestConfig } from "axios"

const BASE_URL = "/notas-em-transito"

export const findNotasEmTransito = async (params?: any, config?: AxiosRequestConfig): Promise<Page<NotaEmTransito>> =>
	request.get(`${BASE_URL}`, { params, ...config })

export const receberNotaEmTransito = async (nota: NotaEmTransito): Promise<NotaEmTransito> =>
	request.post(`${BASE_URL}/receber-nota`, nota)

export const remover = async (params: any): Promise<void> =>
	request.delete(`${BASE_URL}/remover`, { params }) 