import request from "@/common/request"
import { Faq, FormFaq, Page } from "@/models"
import { AxiosRequestConfig } from "axios"

const PATH = "/faq"

export const find = (params?: any, config?: AxiosRequestConfig): Promise<Page<Faq>> =>
	request.get(`${PATH}`, {
		params, 
		...config,
	})

export const get = (id: string): Promise<Faq> =>
	request.get(`${PATH}/${id}`)

export const create = (faq: Faq): Promise<FormFaq> =>
	request.post(`${PATH}`, faq)

export const update = (data: Faq): Promise<FormFaq> =>
	request.put(`${PATH}/${data.id}`, data)

export const remove = async (id: string): Promise<void> =>
	request.delete(`${PATH}/${id}`)