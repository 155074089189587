import { Module, VuexModule, Mutation, getModule } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { getRequestErrorMessage } from '@/helpers/axios-helpers'
import store from '@/store/vuex/index'

@Module({
	name: 'alert',
	store,
	dynamic: true,
	namespaced: true,
})
class AlertModule extends VuexModule {
	message: Message = {
		text: '',
		type: 'success',
	}
	show = false

	@Mutation
	setShow(show: boolean) {
		this.show = show
	}

	@Mutation
	setMessage(message: Message) {
		this.message = message
		this.show = true
	}

	get getErrorMsg() {
		return this.message.text
	}

	@Mutation
	setError(error: SetMessageParams | unknown) {
		this.message = createMessageFromParams(null, 'error', error as SetMessageParams)
		this.show = true
	}

	@Mutation
	setErrorComPrefixo(error: ErroComPrefixo) {
		this.message = createMessageFromParams(error.prefixo, 'error', error.error as SetMessageParams)
		this.show = true
	}

	@Mutation
	setSuccess(text: SetMessageParams) {
		this.message = createMessageFromParams(null, 'success', text)
		this.show = true
	}

	@Mutation
	setInfo(text: SetMessageParams) {
		this.message = createMessageFromParams(null, 'info', text)
		this.show = true
	}

	@Mutation
	setWarning(text: SetMessageParams) {
		this.message = createMessageFromParams(null, 'warning', text)
		this.show = true
	}
}

export type Message = {
	text: string
	type: 'success' | 'info' | 'warning' | 'error'
	timeout?: number
}

function createMessageFromParams(prefixo: string | null, type: Message['type'], content: string | AxiosError | {
	text: AxiosError | string
	timeout?: number
}): Message {
	if (typeof content === 'string') {
		return {
			type,
			text: content,
		}
	}
	if (content instanceof Error) {
		return {
			type,
			text: getRequestErrorMessage(prefixo, content),
			timeout: -1,
		}
	}
	if (content.text instanceof Error) {
		return {
			...content,
			type,
			text: getRequestErrorMessage(prefixo, content.text),
		}
	}
	return {
		text: content.text as string,
		type,
		timeout: content.timeout,
	}
}

export type SetMessageParams = string | {
	text: string
	timeout?: number
}

export type ErroComPrefixo = {
	error: SetMessageParams | unknown
	prefixo: string | null
}

export type SetErrorMessageParams = string | AxiosError | {
	text: string | AxiosError
	timeout?: number
}

export default getModule(AlertModule)