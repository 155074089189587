import { FechamentoDeCaixaServiceAdapter } from '@/usecases'
import {
	FechamentoDeCaixa,
	FormDeFechamentoDeCaixa,
	Page,
	VendaDoFechamento,
	TotalPorBandeiraDoFechamento,
	FechamentoDeCaixaDetalhado,
	ItemDeFechamento,
} from '@/models'
import { AxiosRequestConfig } from 'axios'
import request from '@/common/request'

const PATH = '/fechamentos'
export class FechamentoDeCaixaService implements FechamentoDeCaixaServiceAdapter {

	async update(data: FormDeFechamentoDeCaixa): Promise<FechamentoDeCaixa> {
		return request.put(`${PATH}/${data.id}`, data)
	}

	async get(id: string): Promise<FechamentoDeCaixaDetalhado> {
		return request.get(`${PATH}/${id}`)
	}
	
	async find(params?: any, config?: AxiosRequestConfig): Promise<Page<FechamentoDeCaixaDetalhado>> {
		return request.get(`${PATH}`, {
			params,
			...config,
		})
	}

	async getVendas(id: string, params?: any, config?: AxiosRequestConfig): Promise<Page<VendaDoFechamento>> {
		return request.get(`${PATH}/${id}/vendas`, {
			params,
			...config,
		})
	}

	async getTotaisPorBandeira(id: string, config?: AxiosRequestConfig): Promise<TotalPorBandeiraDoFechamento[]> {
		return request.get(`${PATH}/${id}/totais-por-bandeira`, {
			...config,
		})
	}

	async atualizaItemDeFechamento(id: string, item: Partial<ItemDeFechamento>): Promise<ItemDeFechamento> {
		return request.put<any, ItemDeFechamento>(`/itens-de-fechamento/${id}`, item)
	}

	async findValorContadoEmDinheiroByPdvId(pdvId: string): Promise<number> {
		return request.get(`${PATH}/valor-contado-em-dinheiro/${pdvId}`)
	}
}
