import { CapaDaNotaServiceAdapter } from '@/usecases'
import request from '@/common/request';
import { CapaDaNota, CapaDaNotaParaCartaDeCorrecao, FiltroDeCapaDaNota, Page } from '@/models';
import { AxiosRequestConfig } from 'axios';
import { getXmls } from '@/api/notasFiscais'

const BASE_URL = '/notas-fiscais'
export class CapaDaNotaService implements CapaDaNotaServiceAdapter {
	find = async (params?: FiltroDeCapaDaNota, config?: AxiosRequestConfig): Promise<Page<CapaDaNota>> =>
		request.get(`${BASE_URL}`, {
			params,
			...config,
		})

	async downloadXml(params: FiltroDeCapaDaNota, config?: AxiosRequestConfig): Promise<any> {
		return request.get(`${BASE_URL}/exportar-xmls`, {
			params,
			responseType: 'arraybuffer',
			...config,
		})
	}

	getXmls = async (params: FiltroDeCapaDaNota): Promise<string[]> => getXmls(params)
	
	findByChNFe = async (chNFe: string): Promise<CapaDaNotaParaCartaDeCorrecao> =>
		request.get(`${BASE_URL}/${chNFe}`)
}
