import { Inject } from 'inversify-props'
import type { TipoDeClienteServiceAdapter } from '../adapter'
import { FormTipoDeCliente, TipoDeCliente } from '@/models'

export class SaveTipoDeClienteUseCase {
	@Inject('TipoDeClienteServiceAdapter')
	private clienteService!: TipoDeClienteServiceAdapter

	create = async (cliente: FormTipoDeCliente): Promise<TipoDeCliente> =>
		await this.clienteService.create(cliente)

	update = async (cliente: FormTipoDeCliente): Promise<TipoDeCliente> =>
		await this.clienteService.update(cliente)
}
