import { DownloadModule, PREFIXO_ITEM } from "@/store/vuex/fiscal/DownloadStore";
import { del, keys } from "idb-keyval";

export class LimparDownloadsUseCase {

	/**
	 * Limpa os dados de download incluindo arquivos que tenham sido salvos 
	 * e estejam "órfãos" no indexedDB
	 * @returns void
	 */
	limparDadosDownload = async () => {
		await DownloadModule.removerTodosDownloads()
		DownloadModule.axiosTokens.forEach(({token}) => token.cancel())
		return keys().then((keys) => keys.forEach(key => {
			if (key.toString().startsWith(PREFIXO_ITEM)) {
				del(key)
			}
		}));
	}
}