import { Inject } from 'inversify-props';
import type { PerfilDeUsuarioServiceAdapter } from './adapter';
import { Perfil, Page } from '@/models';
import { AxiosRequestConfig } from 'axios';


export class FindPerfilUseCase {
	@Inject("PerfilDeUsuarioServiceAdapter")
	private perfilService!: PerfilDeUsuarioServiceAdapter

	findById = async (id: string): Promise<Perfil> => 
		await this.perfilService.get(id)

	findByNome = async (nome: string): Promise<Perfil> => 
		await this.perfilService.findByNome(nome)

	findAll = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Perfil>> => 
		await this.perfilService.find(params, config)		
}