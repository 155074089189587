import request from "@/common/request"
import { CapaDashboard, CapaDashboardPerfil, DashGeral, ViewPerfilDashboard } from "@/models/views/CapaDashboard"
import { AxiosRequestConfig } from "axios"

const PATH = '/dashboards'

export const listarDashs = (): Promise<CapaDashboard[]> =>
	request.get(`${PATH}/listar`)

export const listarDashPorPerfil = (idPerfil: string): Promise<ViewPerfilDashboard[]> =>
	request.get(`${PATH}/perfil/${idPerfil}/listar`)

export const findDashGeral = async (params?: any, config?: AxiosRequestConfig): Promise<DashGeral> =>
	request.get(`${PATH}/dashGeral`, {
		params,
		...config,
	})

export const salvarDashsPerfis = (dashboard: CapaDashboardPerfil[]): Promise<void> =>
	request.post(`${PATH}/perfil/salvar`, dashboard)