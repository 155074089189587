import { TipoDeRecurso } from '@/models/Recurso'
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds'
import { RouteConfig } from 'vue-router'

const rotasDeEstoques: RouteConfig [] = [
	{
		path: '/estoques/:idDeposito/itens/novo',
		name: 'Criação de Item de Estoque',
		components: {
			default: () =>
				import('@/views/application/estoque/EdicaoDeItemDeEstoque.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			default: ({ params }) => {
				return { novo: true, idDeposito: params.idDeposito }
			},
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'depositos' as TipoDeRecurso,
		},
	},
	{
		path: '/estoques/:idDeposito/itens/:id',
		name: 'Edição de Item de Estoque',
		components: {
			default: () =>
				import('@/views/application/estoque/EdicaoDeItemDeEstoque.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			default: true,
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'depositos' as TipoDeRecurso,
		},
	},
	{
		path: 'estoques',
		name: 'Estoques',
		components: {
			default: () =>
				import('@/views/application/estoque/TelaDeEstoques.vue'),
			toolbar: () =>
				import('@/views/application/estoque/ToolbarDeEstoques.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'depositos' as TipoDeRecurso,
		},
	},
]

export default rotasDeEstoques