import { noAuthGuard } from '@/shareds/router-guards';
import { RouteConfig } from 'vue-router';

const rotasDeLogin: RouteConfig [] = [
	{
		path: '/entrar',
		name: 'Entrar',
		component: () => 
			import('@/views/login/Login.vue'),
		beforeEnter: noAuthGuard,
	},
]

export default rotasDeLogin