import { TipoDeRecurso } from '@/models/Recurso';
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotas: RouteConfig[] = [
	{
		path: 'atributos',
		name: 'Atributos',
		components: {
			default: () =>
				import('@/views/application/produtos/TelaDeAtributos.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'atributos' as TipoDeRecurso,
		},
	},
]

export default rotas
