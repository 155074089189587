import { Inject } from 'inversify-props'
import type { RegraDeImpostoServiceAdapter } from '../adapter/RegraDeImpostoServiceAdapter'
import { CapaRegraImpostoInput, RegraDeAplicacao } from '@/models'

export class SaveRegraDeImpostoUseCase {
	@Inject('RegraDeImpostoServiceAdapter')
	private regraService!: RegraDeImpostoServiceAdapter

	create = async (regra: CapaRegraImpostoInput) =>
		await this.regraService.create(regra)

	updateCapa = async (regra: CapaRegraImpostoInput) =>
		await this.regraService.update(regra)

	addItem = async (idRegra: string, item: RegraDeAplicacao) =>
		await this.regraService.addItem(idRegra, item)
}
