import request from "@/common/request"
import { Page } from "@/models"
import { BaixaDePagamento, BaixaDePagamentoForm, BaixaDePagamentoMultiploForm, BaixaDePagamentoAuditada } from "@/models/financeiro/BaixaDePagamento"
import { AxiosRequestConfig } from "axios"

const basePath = '/baixaDePagamento'

export const findBaixaDePagamento = async (params?: any, config?: AxiosRequestConfig): Promise<Page<BaixaDePagamentoAuditada>> =>
	request.get(`${basePath}`, { params, ...config })

export const create = async (baixa: BaixaDePagamentoForm): Promise<any> =>
	request.post(`${basePath}`, baixa,
		{
			headers: {
				'Content-Type': 'application/json',
			},
			responseType: 'arraybuffer',
		},
	)

export const createMultiplo = async (baixaDePagamentoForm: BaixaDePagamentoMultiploForm): Promise<any> =>
	request.post(`${basePath}/multipla`,  baixaDePagamentoForm, 
		{
			headers: {
				'Content-Type': 'application/json',
			},
			responseType: 'arraybuffer',
		},
	)

export const deleteById = async (id: string): Promise<void> =>
	request.delete(`${basePath}/${id}`)

export const update = async (id: string, formaDePagamento: string): Promise<void> => {
	const payload = {
		id: id,
		formaDePagamento: formaDePagamento,
	};
	return request.put(`${basePath}/atualizar`, payload, {
		headers: {
			'Content-Type': 'application/json',
		},
		responseType: 'arraybuffer',
	});
};


export const imprimirBaixaDePagamento = async (baixaDePagamento: BaixaDePagamento): Promise<any> =>
	request.post(`${basePath}/imprimir`, baixaDePagamento,
		{
			headers: {
				'Content-Type': 'application/json',
			},
			responseType: 'arraybuffer',
		},
	)