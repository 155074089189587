import { TipoDeRecurso } from '@/models/Recurso'
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds'
import { RouteConfig } from 'vue-router'

const rotas: RouteConfig[] = [
	{
		path: 'cashback',
		name: 'Cashback',
		components: {
			default: () =>
				import('@/views/application/cashback/TelaDeCashback.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'cashback' as TipoDeRecurso,
		},
	},
	{
		path: 'campanha-de-cashback',
		name: 'Campanha de Cashback',
		components: {
			default: () =>
				import('@/views/application/cashback/TelaDeCampanhaDeCashback.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'campanha-de-cashback' as TipoDeRecurso,
		},
	},
	{
		path: 'manutencao-de-cashback',
		name: 'Manutenção de Cashback',
		components: {
			default: () =>
				import('@/views/application/cashback/TelaDeManutencaoCashback.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'manutencao-de-cashback' as TipoDeRecurso,
		},
	},
	{
		path: 'acao-de-cashback',
		name: 'Ação de Cashback',
		components: {
			default: () =>
				import('@/views/application/cashback/TelaDeAcaoDeCashback.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'acao-de-cashback' as TipoDeRecurso,
		},
	},
]

export default rotas