import request from '@/common/request'
import { DetalhesDoPagamento, FormPagamento, FormaDePagamento, Pagamento, TipoDePagamento } from '@/models'

const basePath = '/pagamentos'

export const efetuarPagamentoTef = (pdvId: string, pagamento: FormPagamento, identificadorDaVenda: string): Promise<DetalhesDoPagamento> =>
	request.post(`${basePath}/${pdvId}/controlpay`, { ...pagamento, identificadorDaVenda: identificadorDaVenda})

export const consultarPagamentoTef = (pdvId: string, detalhesDoPagamentoId: string): Promise<DetalhesDoPagamento> =>
	request.get(`${basePath}/${pdvId}/controlpay/detalhes?detalhesDoPagamentoId=${detalhesDoPagamentoId}`)

export const cancelarPagamentoTef = (pdvId: string, detalhesDoPagamentoId: string): Promise<DetalhesDoPagamento> =>
	request.post(`${basePath}/${pdvId}/controlpay/cancelar?detalhesDoPagamentoId=${detalhesDoPagamentoId}`)

export const buscarDetalhesDoPagamento = (detalhesDoPagamentoId: string): Promise<DetalhesDoPagamento> =>
	request.get(`${basePath}/detalhes/${detalhesDoPagamentoId}`)

export const consultarUltimoPagamentoTefPelaVenda = (identificadorDaVenda: string): Promise<DetalhesDoPagamento> =>
	request.get(`${basePath}/detalhes/pendente/${identificadorDaVenda}`)

export const buscarTipoDePagamentoPorForma = (formaDePagamento: FormaDePagamento, lojaId: string): Promise<TipoDePagamento[]> =>
	request.get(`${basePath}/${lojaId}/tipo-pagamento?formaDePagamento=${formaDePagamento}`)

export const salvarPagamento = (pagamento: Pagamento): Promise<void> =>
	request.post(`${basePath}/editar`, pagamento)

export const podeAlterarQtdDeParcelas = (pagamentoId: string): Promise<boolean> =>
	request.get(`${basePath}/${pagamentoId}`)

export const findPagamentosDetalhadosByVendaId = (vendaId: string): Promise<Pagamento[]> =>
	request.get(`${basePath}/venda/${vendaId}`)
