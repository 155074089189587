import { Inject } from 'inversify-props'
import type { UsuarioServiceAdapter } from '@/usecases'
import { Usuario } from '@/models'

const ERRO_NA_REQUISICAO = 'Erro ao salvar o usuário'

export class SaveUsuarioUseCase {
	@Inject("UsuarioServiceAdapter")
	private usuarioService!: UsuarioServiceAdapter

	async save(usuario: Usuario): Promise<Usuario> {
		try {
			if (usuario.id) {
				const userResponse = await this.usuarioService.update(usuario)
				if (userResponse) {
					return userResponse
				}
				throw new Error(userResponse)
			} else {
				const userResponse = await this.usuarioService.create(usuario)
				if (userResponse) {
					return userResponse
				}
				throw new Error(userResponse)
			}
		} catch (e: any) {
			throw new Error(`${ERRO_NA_REQUISICAO}: ${e.response.data[0]}`)
		}
	}
}
