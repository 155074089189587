
import { ConsultaNFServiceAdapter } from '@/usecases/consulta/nf/adapter/ConsultaNFServiceAdapter';
import { injectable } from 'inversify-props'
import {
	consulta,
} from '@/api/consultaNota'
import { DadosConsultaNF } from '@/models/DadosConsultaNF'

@injectable()
export class ConsultaNFService implements ConsultaNFServiceAdapter {
		consultarNota = async (chaveConsulta: string): Promise<DadosConsultaNF> => consulta(chaveConsulta)
}