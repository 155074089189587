import { create, find, update } from "@/api/adquirente"
import { Adquirente } from "@/models"
import { AdquirenteServiceAdapter } from "@/usecases/venda/adapter/AdquirenteServiceAdapter"
import { injectable } from "inversify-props"

@injectable()
export class AdquirenteService implements AdquirenteServiceAdapter {
	find = async (params?: any) => await find(params)
	create = async (bandeira: Adquirente) => await create(bandeira)
	update = async (bandeira: Adquirente) => await update(bandeira)
}