import { ClienteTabelaDePreco, Estoque, ItemDaTabelaDePreco, Loja } from "@/models";
import { inject } from "inversify-props";
import type { EstoqueServiceAdapter } from "../deposito";
import { FindItemDaTabelaDePrecoUseCase } from "../tabela-de-precos";

export class ConsultarProdutoUseCase {
	@inject('EstoqueServiceAdapter')
	private estoqueService!: EstoqueServiceAdapter
	private findItemTabelaDePrecoUseCase = new FindItemDaTabelaDePrecoUseCase()

	async executar({ produtoId, loja }: ParametrosDeConsultarUmProduto): Promise<ConsultaDeProduto> {
		const precos = (await Promise.all(loja.configuracaoDaLoja.tabelasDePreco
			.map(async (tabela: ClienteTabelaDePreco) => {
				try {
					const itemDaTabela = await this.findItemTabelaDePrecoUseCase.getByIdDoProduto(
						tabela.tabelaDePreco.id, produtoId,
					)
					return {
						nomeDaTabela: tabela.tabelaDePreco.nome,
						item: itemDaTabela,
					}
				} catch (error: any) {
					if (error?.response.status === 404) return undefined
					throw error
				}
			}),
		)).filter(preco => !!preco) as { nomeDaTabela: string; item: ItemDaTabelaDePreco }[]
		const paginaDeEstoque = await this.estoqueService.findEstoques({
			produtoId,
			lojaId: loja.id,
		})

		return {
			precos,
			estoque: paginaDeEstoque.content[0],
		}
	}
}

export type ParametrosDeConsultarUmProduto = {
	produtoId: string
	loja: Loja
}

export type ConsultaDeProduto = {
	precos: {
		nomeDaTabela: string
		item: ItemDaTabelaDePreco
	}[]
	estoque?: Estoque
}