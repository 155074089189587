import { TipoDeRecurso } from '@/models/Recurso';
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotasDeMotivosDeCancelamentoFiscal: RouteConfig[] = [
	{
		path: 'motivos-de-cancelamento-fiscal',
		name: 'Motivos de Cancelamento Fiscal',
		components: {
			default: () =>
				import('@/views/application/venda/TelaDeMotivosDeCancelamentoFiscal.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'motivos-de-cancelamento-fiscal' as TipoDeRecurso,
		},
	},
]

export default rotasDeMotivosDeCancelamentoFiscal