import { Inject } from 'inversify-props'
import type { CategoriaServiceAdapter } from './adapter/CategoriaServiceAdapter'
import { Categoria, Page } from '@/models'
import { AxiosRequestConfig } from 'axios'

export class FindCategoriaUseCase {
	@Inject('CategoriaServiceAdapter')
	private categoriaAdapter!: CategoriaServiceAdapter

	findAll = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Categoria>> =>
		this.categoriaAdapter.find(params, config)

	findAllList = async (config?: AxiosRequestConfig): Promise<string[]> =>
		this.categoriaAdapter.findAllList(config)

}