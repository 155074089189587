import { Titulo, Page, TotalizadoresDeTitulo } from '@/models';
import { AxiosRequestConfig } from 'axios';
import { Inject } from 'inversify-props';
import type { TituloServiceAdapter } from '../titulos/adapter/TituloServiceAdapter';

export class FindTituloUseCase {
	@Inject("TituloServiceAdapter")
	private tituloServiceAdapter!: TituloServiceAdapter

	findAll = async (params?: any): Promise <Titulo[]> =>
		(await this.tituloServiceAdapter.find(params)).content

	findById = async (id: string | null): Promise<Titulo> =>
		await this.tituloServiceAdapter.get(id)
	
	find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Titulo>> =>
		await this.tituloServiceAdapter.find(params, config)

	findTotalizador = async (params?: any, config?: AxiosRequestConfig): Promise<TotalizadoresDeTitulo> =>
		await this.tituloServiceAdapter.findTotalizador(params, config)

	findParaBaixa = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Titulo>> =>
		await this.tituloServiceAdapter.findParaBaixa(params, config)

	exportCsv = async (params?: any, config?: AxiosRequestConfig): Promise<any> =>
		await this.tituloServiceAdapter.exportCsv(params, config)
}