import { injectable } from 'inversify-props'
import { GeneroServiceAdapter } from '@/usecases/produto/genero/adapter'
import { Genero } from '@/models'
import { findGenero, createGenero, updateGenero } from '@/api/produtos'
import { AxiosRequestConfig } from 'axios'

@injectable()
export class GeneroService implements GeneroServiceAdapter {
	find = async (params?: any, config?: AxiosRequestConfig) => await findGenero(params, config)
	create = async (genero: Genero) => await createGenero(genero)
	update = async (genero: Genero) => await updateGenero(genero)
}
