import request from '@/common/request'
import { Perfil, Page } from '@/models'
import { AxiosRequestConfig } from 'axios'

const ROTA_BASE = '/v2/perfis' 

export const get = async (id: string): Promise<Perfil> =>
	request.get(`${ROTA_BASE}/${id}`)

export const findByNome = async (nome: string): Promise<Perfil> =>
	request.get(`/perfis/${nome}/nome`)	

export const find = (params: any, config?: AxiosRequestConfig): Promise<Page<Perfil>> =>
	request.get(`${ROTA_BASE}`, {
		params,
		...config,
	})

export const update = async (data: Perfil): Promise<Perfil> =>
	request.put(`${ROTA_BASE}/${data.id}`, data)

export const create = async (data: Perfil): Promise<Perfil> =>
	request.post(`${ROTA_BASE}`, data)

export const remove = async (id: string): Promise<void> =>
	request.delete(`${ROTA_BASE}/${id}`)
