import { Inject } from 'inversify-props'
import { CodigoEDescricao } from '@/models/shareds/CodigoEDescricao'
import { Page } from '@/models'
import type { ServiceIPIAdapter } from '../adapter/ServiceIPIAdapter'

export class FindIPIUseCase {
	@Inject('ServiceIPIAdapter')
	private ipiService!: ServiceIPIAdapter

	findById = async (id: string): Promise<CodigoEDescricao> =>
		await this.ipiService.get(id)

	find = async (params?: any): Promise<Page<CodigoEDescricao>> =>
		await this.ipiService.find(params)

}
