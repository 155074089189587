import { NavigationGuard } from 'vue-router'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import AppStore from '@/store/vuex/aplicacao/AppStore'
import { montarOperacaoDeRecurso } from './permissoes-shareds'
import { TipoDeOperacao } from '@/models/Recurso'

// Previne entrar na rota se não está logado
export const authGuard: NavigationGuard = (to, from, next) => {
	if (!UserLoginStore.isLoggedIn) {
		next('/entrar')
	} else {
		const operacao: TipoDeOperacao = to.meta?.operacao || 'ler'
		const requerimento = montarOperacaoDeRecurso(operacao, to.meta?.recurso)
		if (to.meta?.recurso && !UserLoginStore.temAutorizacao(requerimento)) {
			return next('/')
		}
		
		onlyRouterGuard(to, from, next)
	}
}

export const onlyRouterGuard = (to, from, next) => {
	const unicaRotaPossivel = AppStore.temSomenteUmMenuPermitido &&
		AppStore.itensDeRecursosPermitidos[0].route
	if (unicaRotaPossivel && (typeof unicaRotaPossivel !== 'object'
		? unicaRotaPossivel !== to.name
		: unicaRotaPossivel.name !== to.name)
	) {
		return next(unicaRotaPossivel)
	}
	next()
}

// Previne entrar na rota se já está logado
export const noAuthGuard: NavigationGuard = (to, from, next) => {
	if (UserLoginStore.isLoggedIn) {
		next('/')
	} else {
		next()
	}
}