import { AxiosRequestConfig } from "axios";
import { injectable } from "inversify-props";
import { findBaixaDePagamento, create, deleteById, createMultiplo, imprimirBaixaDePagamento, update } from "@/api/baixaDePagamento";
import { BaixaDePagamentoServiceAdapter } from "@/usecases/baixaDePagamento/adapter";
import { BaixaDePagamento, BaixaDePagamentoForm, BaixaDePagamentoMultiploForm } from "@/models/financeiro/BaixaDePagamento";

@injectable()
export class BaixaDePagamentoService implements BaixaDePagamentoServiceAdapter {
	find = async (params?: any, config?: AxiosRequestConfig) => await findBaixaDePagamento(params, config)
	create = async(baixa: BaixaDePagamentoForm) => await create(baixa)
	createMultiplo = async(baixa: BaixaDePagamentoMultiploForm) => await createMultiplo(baixa)
	delete = async(id: string) => await deleteById(id)
	update = async(id: string, formaDePagamento: string) => await update(id, formaDePagamento)
	imprimirBaixaDePagamento = async(baixaDePagamento: BaixaDePagamento) => await imprimirBaixaDePagamento(baixaDePagamento)
}