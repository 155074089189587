import { create, update, find, get, remove, findMotivosDeMovimentacoesByLojaId, listaMotivosDeMovimentacoesByUser } from '@/api/motivoDaMovimentacao'
import { FormMotivoDaMovimentacao } from '@/models'
import { AxiosRequestConfig } from 'axios'
import { MotivoDaMovimentacaoServiceAdapter } from '@/usecases'

export class MotivoDaMovimentacaoService implements MotivoDaMovimentacaoServiceAdapter {
	create = async (motivo: FormMotivoDaMovimentacao) => await create(motivo)
	update = async (motivo: FormMotivoDaMovimentacao) => await update(motivo)
	get = async (id: string) => await get(id)
	find = async (params?: any, config?: AxiosRequestConfig) => await find(params, config)
	delete = async (id: string) => remove(id)
	findMotivosDeMovimentacoesByLojaId = async (lojaId: string, config?: AxiosRequestConfig) => await findMotivosDeMovimentacoesByLojaId(lojaId, config)
	listaMotivosDeMovimentacoesByUser = async (params?: any, config?: AxiosRequestConfig) => await listaMotivosDeMovimentacoesByUser(params, config)
}
