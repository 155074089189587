import { Inject } from 'inversify-props'
import { CodigoEDescricao, Page } from '@/models'
import type { NCMServiceAdapter } from '..'

export class FindNCMUseCase {
	@Inject('NCMServiceAdapter')
	private ncmService!: NCMServiceAdapter

	findById = async (id: string): Promise<CodigoEDescricao> =>
		await this.ncmService.get(id)

	find = async (params?: any): Promise<Page<CodigoEDescricao>> =>
		await this.ncmService.find(params)
}
