import { TipoDeRecurso } from '@/models/Recurso';
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotas: RouteConfig[] = [
	{
		path: 'produtos',
		name: 'Produtos',
		components: {
			default: () =>
				import('@/views/application/produtos/TelaDeProdutos.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		children: [
			{
				path: 'editar',
				name: 'Editar Produtos',
				component: () =>
						import('@/views/application/produtos/EdicaoMultiplaDeProdutos.vue'),
				props: { novo: true },
			},
			{
				path: 'novo',
				name: 'Novo Produto',
				component: () =>
					import('@/views/application/produtos/EdicaoDeProduto/EdicaoDeProduto.vue'),
				props: { novo: true },
			},
			{
				path: ':id',
				name: 'Produto',
				component: () =>
					import('@/views/application/produtos/EdicaoDeProduto/EdicaoDeProduto.vue'),
				props: true,
			},
		],
		meta: {
			recurso: 'produtos' as TipoDeRecurso,
		},
	},
]

export default rotas