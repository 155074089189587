import { TipoDeRecurso } from "@/models/Recurso";
import { tituloDaToolbarPeloNomeDaRota } from "@/shareds/router-shareds";
import { RouteConfig } from "vue-router";

const rotasDeCategoria: RouteConfig[] = [
	{
		path : 'categoria',
		name: 'Categorias',
		components: {
			default : () =>
				import ('@/views/application/produtos/EdicaoDeProduto/TelaDeCategoria.vue'),
			toolbar: () => 
				import ('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota},
		meta: {
			recurso: 'categoria' as TipoDeRecurso,
		},
	},
]

export default rotasDeCategoria