import 'reflect-metadata'
import { injectable } from 'inversify-props'
import { Deposito } from '@/models'
import {
	create,
	find,
	get,
	patch,
	remove,
	update,
	findProdutosDeposito,
} from '@/api/deposito'
import { DepositoServiceAdapter } from '@/usecases/deposito'

@injectable()
export class DepositoService implements DepositoServiceAdapter {
	findProdutos = async (idDeposito: string, params?: any) =>
		await findProdutosDeposito(idDeposito, params)

	get = async (id: string) => await get(id)
	find = async (params: any) => await find(params)
	create = async (deposito: Deposito) => await create(deposito)
	update = async (deposito: Deposito) => await update(deposito)
	patch = async (deposito: Deposito) => await patch(deposito)
	remove = async (id: string) => await remove(id)
}
