import { Inject } from 'inversify-props'
import { AxiosRequestConfig } from 'axios'
import { Page, ItemDoRankingDeProdutos } from '@/models'
import type { DadosDeProdutoServiceAdapter } from '@/usecases'
import { Pageable } from '@/models/Pageable'

export class FindRankingDeProdutosUseCase {
	@Inject('DadosDeProdutoServiceAdapter')
	private dadosDeProdutoService!: DadosDeProdutoServiceAdapter

	find = async (params: ParametrosDoRankingDeProdutos, config?: AxiosRequestConfig): Promise<Page<ItemDoRankingDeProdutos>> =>
		await this.dadosDeProdutoService.findRanking(params, config)
}

export type ParametrosDoRankingDeProdutos = {
	lojas?: string[]
	pdvs?: string[]
	dataInicial: string
	dataFinal?: string
	page?: Pageable
}

