
import { Meta, Page } from '@/models'
import { AxiosRequestConfig } from 'axios'
import { Inject } from 'inversify-props'
import type { MetaServiceAdapter } from './adapter'


export class FindMetasUseCase {
	@Inject('MetaServiceAdapter')
	private metaService!: MetaServiceAdapter


	findById = async (id: string): Promise<Meta> => 
		await this.metaService.get(id)
	
	findMetas = async (params?: any, config?: AxiosRequestConfig) => { 
		return await this.metaService.find(params, config)
	}
	
	private buscaDescricaoMeta(metaService: Meta[], id: string) {
		const meta = metaService.find(meta => meta.id === id)
		return meta?.descricao || ''
	}
		
	findAll = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Meta>> => {
		const pageMetas = await this.metaService.find(params, config)
		pageMetas.content.map(meta => {
			meta.descricao,
			meta.vendedorAtribuido
			meta.valor
			return meta
		})
		return pageMetas
	}

}