import { createCategoria, findCategoria, updateCategoria, findCategoriaList } from "@/api/produtos"
import { Categoria } from "@/models"
import { CategoriaServiceAdapter } from "@/usecases/produto/categoria/adapter/CategoriaServiceAdapter"
import { AxiosRequestConfig } from "axios"
import { injectable } from "inversify-props"

@injectable()
export class CategoriaService implements CategoriaServiceAdapter {
	find = async (params?: any, config?: AxiosRequestConfig) => await findCategoria(params, config)
	create = async (categoria: Categoria) => await createCategoria(categoria)
	update = async (categoria: Categoria) => await updateCategoria(categoria)
	findAllList = async (config?: AxiosRequestConfig) => await findCategoriaList(config)
}