import { Inject } from 'inversify-props'
import type { FechamentoDeCaixaServiceAdapter } from './adapter'
import { FechamentoDeCaixa, FechamentoDeCaixaDetalhado, Page } from '@/models'
import { AxiosRequestConfig } from 'axios'

export class FindFechamentoDeCaixaUseCase {
	@Inject('FechamentoDeCaixaServiceAdapter')
	private fechamentoDeCaixaServiceAdapter!: FechamentoDeCaixaServiceAdapter

	find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<FechamentoDeCaixa>> =>
		await this.fechamentoDeCaixaServiceAdapter.find(params, config)
	
	get = async (id: string): Promise<FechamentoDeCaixaDetalhado> =>
		await this.fechamentoDeCaixaServiceAdapter.get(id)

	findValorContadoEmDinheiroByPdvId = async (pdvId: string): Promise<number> =>
		await this.fechamentoDeCaixaServiceAdapter.findValorContadoEmDinheiroByPdvId(pdvId)

}
