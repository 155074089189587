import { Adquirente } from "@/models";
import type { AdquirenteServiceAdapter } from "./adapter";
import { Inject } from "inversify-props";

export class SaveAdquirenteUseCase {
	@Inject('AdquirenteServiceAdapter')
	private adquirenteServiceAdapter!: AdquirenteServiceAdapter

	create = async (adquirente: Adquirente) => await this.adquirenteServiceAdapter.create(adquirente)
	update = async (adquirente: Adquirente) => await this.adquirenteServiceAdapter.update(adquirente)
}