import { Usuario } from "@/models";
import {Inject} from "inversify-props";
import {ResponseStatus, SignUpResponse} from "@/models/SignUpResponse";
import type { UsuarioServiceAdapter } from '@/usecases';

export class CadastroDeUsuarioUseCase {
	@Inject("UsuarioServiceAdapter")
	private usuarioServiceAdapter!: UsuarioServiceAdapter

	async signUp(usuario: Usuario): Promise<SignUpResponse> {
		const response = { status: '', erro: ''}
		try {
			const exists = await this.usuarioServiceAdapter.find(usuario.email)
			if (exists) {
				response.status = ResponseStatus.ERRO
				response.erro = 'Email já cadastrado'
			} else {
				const user = await this.usuarioServiceAdapter.create(usuario)
				if (user) {
					response.status = ResponseStatus.SUCCESS
					response.erro = ''
				}
			}
		} catch (e) {
			response.status = ResponseStatus.ERRO
			response.erro = 'Sistema Indisponível, tente novamente mais tarde'
		}
		return Promise.resolve(response)
	}
}
