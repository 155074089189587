import { Inject } from "inversify-props";
import type { ClienteServiceAdapter } from "./adapter";
export class ImportaClientesUseCase {
	@Inject('ClienteServiceAdapter')
	private clienteService!: ClienteServiceAdapter

	async execute(urlDoArquivoCsv: string, arquivoContemCabecalho: boolean, grupoEconomicoId: string, lojasIds: any[] ): Promise<any> {
		this.clienteService.importaItens(urlDoArquivoCsv, arquivoContemCabecalho, grupoEconomicoId, lojasIds)
	}
	
}