export interface Cadastro {
	email: string
	senha: string
	nome: string
	subdominio: string
	isDemonstracao: boolean | null
	comoConheceu: string | null
	telefones: string[]
	tokenRecaptcha: string
	ambiente: string
}

export const comoConheceu = [
	'Indicação de amigo',
	'Conteúdo educacional',
	'Busca na internet',
	'Anúncio de TV ou rádio',
	'Influenciadores ou redes sociais',
	'Anúncio na internet',
	'Outro',
]
