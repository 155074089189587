import { TipoDeRecurso } from '@/models/Recurso';
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotasDePedidos: RouteConfig[] = [
	
	{
		path: 'pedidos',
		name: 'Pedidos',
		components: {
			default: () =>
				import('@/views/application/pedidos/TelaDePedidos.vue'),
			toolbar: () =>
				import('@/views/application/pedidos/ToolbarDePedidos.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'pedidos' as TipoDeRecurso,
		},
	},
	{
		path: 'manifesto',
		name: 'Manifesto de Transporte',
		components: {
			default: () =>
				import('@/views/application/pedidos/EdicaoDeManifesto.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'pedidos' as TipoDeRecurso,
		},
	},
	{
		path: 'logs-de-pedidos',
		name: 'Logs de Pedidos',
		components: {
			default: () => 
				import('@/views/application/pedidos/TelaDeLogsDePedidos.vue'),
			toolbar: () => 
				import ('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota},
		meta: {
			recurso: 'pedidos' as TipoDeRecurso,
		},
	},
	{
		path: 'cadastro-de-motivos',
		name: 'Motivos de Cancelamento',
		components: {
			default: () =>
				import('@/views/application/pedidos/TelaDeCadastrosDeMotivos.vue'),
			toolbar: () =>
				import ('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota},
		meta: {
			recurso: 'pedidos' as TipoDeRecurso,
		},
	},
]
export default rotasDePedidos