import { AxiosError } from 'axios'

export const getRequestErrorMessage = (prefixo: string | null, error: AxiosError<{message?: string | string[]}>): string => {
	switch (error.response?.status) {
		case 401:
			return 'Recurso apenas para usuários autenticados'
		case 403:
			return 'Não tem permissão'
		case 405:
			return 'Método não permitido'
		case 500:
		case 503:
			return 'Ocorreu um erro interno, contate o suporte'
		case 504:
			return 'Tempo limite excedido'
		case 502:
			return 'O sistema está sendo atualizado, aguarde alguns segundos'
	}
	if (error.response && error.response.data) {
		const message = error.response.data.message
		if (!message) return (prefixo || '') + error.response.data.toString()
		return Array.isArray(message)
			? (prefixo || '') + message.join('; ')
			: (prefixo || '') + message
	}
	return (prefixo || '') + error.message
}


export const getRequestError = (error: AxiosError<{message?: string | string[]}>): string => {
	switch (error.response?.status) {
		case 401:
			return 'Recurso apenas para usuários autenticados'
		case 403:
			return 'Não tem permissão'
		case 405:
			return 'Método não permitido'
		case 500:
		case 503:
			return 'Ocorreu um erro interno, contate o suporte'
		case 504:
			return 'Tempo limite excedido'
		case 502:
			return 'O sistema está sendo atualizado, aguarde alguns segundos'
	}
	if (error.response && error.response.data) {
		const message = error.response.data.message
		if (!message) return error.response.data.toString()
		return Array.isArray(message)
			? message.join('; ')
			: message
	}
	return error.message
}