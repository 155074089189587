import "reflect-metadata"
import { injectable } from 'inversify-props';
import { CSOSNServiceAdapter } from '@/usecases/impostos/adapter/CSOSNServiceAdapter';
import { getCSOSN, findCSOSN } from '@/api/impostos';


@injectable()
export class CSOSNService implements CSOSNServiceAdapter {
	get = async (id: string) => await getCSOSN(id)
	find = async(params: any) => await findCSOSN(params)
}