import { createOuUpdate, createOuUpdatePublico, findAnexoByIdentificador, findAnexoIdByIdentificador, findAnexoIdByIdentificadorPublico } from "@/api/anexo"
import { AnexoDosProcessos } from "@/models/anexo/AnexoDosProcessos"
import { AnexoServiceAdapter } from "@/usecases/anexo/adapter/AnexoServiceAdapter"
import { injectable } from "inversify-props"


@injectable()
export class AnexoService implements AnexoServiceAdapter {
	createOuUpdate = async (anexo: AnexoDosProcessos) => await createOuUpdate(anexo)
	findAnexoByIdentificador = async (identificador: string) => await findAnexoByIdentificador(identificador)
	findAnexoIdByIdentificador = async (identificador: string) => await findAnexoIdByIdentificador(identificador)
	createOuUpdatePublico = async (anexo: AnexoDosProcessos, usuarioId: string) => await createOuUpdatePublico(anexo, usuarioId)
	findAnexoIdByIdentificadorPublico = async (identificador: string, usuarioId: string): Promise<string> => await findAnexoIdByIdentificadorPublico(identificador, usuarioId)
}