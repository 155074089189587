export interface PromiseAdiavel<T> extends Promise<T> {
    resolve: (value: unknown) => void
    reject: (reason?: any) => void
}

export default function criarPromiseAdiavel() {
	let resolve, reject
	
	const promise = new Promise(function(_resolve, _reject) {
		resolve = _resolve
		reject = _reject
	}) as PromiseAdiavel<any>

	promise.resolve = resolve
	promise.reject = reject
	return promise
}
