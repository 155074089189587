import request from "@/common/request";
import { MotivoCancelamento, Page } from "@/models";

export const findMotivo = async (params?: any): Promise<Page<MotivoCancelamento>> =>
	request.get(`/motivosCancelamento`, {
		params: {
			...params,
		},
	})


export const createMotivo = async (motivo: MotivoCancelamento): Promise<MotivoCancelamento> =>
	request.post(`/motivosCancelamento`, motivo)

export const updateMotivo = async (motivo: MotivoCancelamento): Promise<MotivoCancelamento> =>
	request.put(`/motivosCancelamento/${motivo.id}`, motivo)