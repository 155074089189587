import { TipoDeRecurso } from '@/models/Recurso';
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotasDeSituacaoDosProdutos: RouteConfig[] = [

	{
		path: 'situacao-produtos',
		name: 'Dashboard de Produtos',
		components: {
			default: () =>
				import('@/views/application/produtos/PainelDaSituacaoDosProdutos.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'situacao-produtos' as TipoDeRecurso,
		},
	},
]
export default rotasDeSituacaoDosProdutos
