import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotasDeAnalytics: RouteConfig[] = [
	{
		path: 'analytics/3',
		name: 'Analytics Vendas',
		components: {
			default: () =>
				import('@/views/application/analytics/TelaInicialAnalytics.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
	},
	{
		path: 'analytics/4',
		name: 'Analytics Estoque',
		components: {
			default: () =>
				import('@/views/application/analytics/TelaInicialAnalytics.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
	},
	{
		path: 'analytics/5',
		name: 'Analytics Financeiro',
		components: {
			default: () =>
				import('@/views/application/analytics/TelaInicialAnalytics.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
	},
	{
		path: 'analytics/6',
		name: 'Analytics Clientes',
		components: {
			default: () =>
				import('@/views/application/analytics/TelaInicialAnalytics.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
	},
]

export default rotasDeAnalytics