import { Inject } from 'inversify-props'
import type { GeneroServiceAdapter } from './adapter/GeneroServiceAdapter'
import { Genero } from '@/models'

export class SaveGeneroUseCase {
	@Inject('GeneroServiceAdapter')
	private generoService!: GeneroServiceAdapter

	create = async (genero: Genero) => await this.generoService.create(genero)

	update = async (genero: Genero) => await this.generoService.update(genero)
}
