import "reflect-metadata"
import { injectable } from 'inversify-props';
import { COFINSServiceAdapter } from '@/usecases/impostos/adapter/COFINSServiceAdapter';
import { getCOFINS, findCOFINS } from '@/api/impostos';


@injectable()
export class CofinsService implements COFINSServiceAdapter {
	get = async (id: string) => await getCOFINS(id)
	find = async(params: any) => await findCOFINS(params)
}