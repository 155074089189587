import { Inject } from 'inversify-props';
import type { ManifestoServiceAdapter } from './adapter';
import { ParamsFindManifesto } from './adapter';
import { Page, Manifesto } from '@/models';
import { AxiosRequestConfig } from 'axios';


export class FindManifestoUseCase {
	@Inject("ManifestoServiceAdapter")
	private manifestoService!: ManifestoServiceAdapter

	findBySerieNota = async (lojaId: string, nnf: string, serie: string): Promise<Page<Manifesto>> => 
		await this.manifestoService.findBySerieNota(lojaId, nnf, serie)

	findByMotorista = async (motoristaId: string): Promise<Page<Manifesto>> => 
		await this.manifestoService.findByMotorista(motoristaId)

	find = async (params?: ParamsFindManifesto, config?: AxiosRequestConfig): Promise<Page<Manifesto>> => 
		await this.manifestoService.find(params, config)		
	
}