import { ItemDaVenda, ItemDaTabelaDePreco, Loja, TipoDeCliente, Venda } from '@/models'
import { displayNomeCompletoDoProduto } from '@/shareds/produto-shareds'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { FindItemDaTabelaDePrecoUseCase } from '@/usecases'
import moment from 'moment'

type UseCaseParams = {
	tipoDeCliente: TipoDeCliente | string | null
	loja: Loja
	itens: ItemDaVenda[]
	vendaAtual: Venda
	validarLimiteDeProduto: boolean
	descontarValor?: number
	findItemTabelaDePrecoUseCase?: FindItemDaTabelaDePrecoUseCase
}

export default async ({
	loja,
	itens,
	tipoDeCliente,
	vendaAtual,
	validarLimiteDeProduto,
	descontarValor,
	findItemTabelaDePrecoUseCase = new FindItemDaTabelaDePrecoUseCase(),
}: UseCaseParams): Promise<ItemDaVenda[]> => {

	const cpfOuCnpjDoCliente = vendaAtual.cpfDoCliente

	const tipoDeClienteNormalizado = typeof tipoDeCliente === 'string'
		? tipoDeCliente
		: tipoDeCliente?.nome

	// ? Procura tabela pro cliente
	const tabelaDoCliente = tipoDeClienteNormalizado
		? loja.configuracaoDaLoja.tabelasDePreco
			.find(tabela => tabela.tabelaDePreco.tipoDeCliente?.nome.toLocaleUpperCase() === tipoDeClienteNormalizado.toLocaleUpperCase() && !tabela.tabelaDePreco.isTabelaDePromocao)
				?.tabelaDePreco
		: undefined

	// ? Procura tabela sem cliente
	const tabelaPadrao = loja.configuracaoDaLoja.tabelasDePreco
		.find(tabela => !tabela.tipoDeCliente && !tabela.tabelaDePreco.isTabelaDePromocao)?.tabelaDePreco

	const tabelasDePromocao = tipoDeClienteNormalizado && tabelaDoCliente
		? loja.configuracaoDaLoja.tabelasDePreco
			.filter(({ tabelaDePreco }) => tabelaDePreco.tipoDeCliente?.nome.toLocaleUpperCase() === tipoDeClienteNormalizado.toLocaleUpperCase() && tabelaDePreco.isTabelaDePromocao)
			.filter(({ tabelaDePreco }) => {
				return moment().isSameOrAfter(moment(`${tabelaDePreco.inicioVigencia} 00:00:00`, 'YYYY-MM-DD HH:mm:ss'))
						&& moment().isSameOrBefore(moment(`${tabelaDePreco.fimVigencia} 23:59:59`, 'YYYY-MM-DD HH:mm:ss'))
			})
			.map(({ tabelaDePreco }) => tabelaDePreco) 
		: loja.configuracaoDaLoja.tabelasDePreco
			.filter(({ tabelaDePreco }) => !tabelaDePreco.tipoDeCliente && tabelaDePreco.isTabelaDePromocao)
			.filter(({ tabelaDePreco }) => {
				return moment().isSameOrAfter(moment(`${tabelaDePreco.inicioVigencia} 00:00:00`, 'YYYY-MM-DD HH:mm:ss'))
						&& moment().isSameOrBefore(moment(`${tabelaDePreco.fimVigencia} 23:59:59`, 'YYYY-MM-DD HH:mm:ss'))
			})
			.map(({ tabelaDePreco }) => tabelaDePreco)

	const itensPromise = itens.map(async item => {
		if (!item.produto.id) throw new Error(`Produto ${displayNomeCompletoDoProduto(item.produto)} não cadastrado`)

		let itemDaTabelaDePreco: ItemDaTabelaDePreco | null = null
		let percentualCashbackDaTabela = 0
		let diasParaEfetivarCashbackDaTabela = 0
		let diasParaExpirarCashbackDaTabela = 0

		if (tabelasDePromocao && tabelasDePromocao.length) {
			const itemEmPromocoesDiferentes = await Promise.all(
				tabelasDePromocao.map(async tabelaDePromocao => {
					const itemDaTabela = await findItemTabelaDePrecoUseCase
						.getByIdDoProduto(tabelaDePromocao.id, item.produto.id)
						.catch(() => null)
					percentualCashbackDaTabela = tabelaDePromocao.percentualCashback
					diasParaEfetivarCashbackDaTabela = tabelaDePromocao.diasParaEfetuarCashback
					diasParaExpirarCashbackDaTabela = tabelaDePromocao.diasParaExpirarCashback
					return itemDaTabela
				}),
			)
			
			const itemComMenorPreco = itemEmPromocoesDiferentes
				.filter(item => item !== null)
				.sort((itemA, itemB) => (itemA?.precoCompra || 0) - (itemB?.precoCompra || 0))
			itemDaTabelaDePreco = itemComMenorPreco[0] || null
		}

		// ? Testa e aplica para Tipo de Cliente
		if (!itemDaTabelaDePreco && tabelaDoCliente) {
			percentualCashbackDaTabela = tabelaDoCliente.percentualCashback
			diasParaEfetivarCashbackDaTabela = tabelaDoCliente.diasParaEfetuarCashback
			diasParaExpirarCashbackDaTabela = tabelaDoCliente.diasParaExpirarCashback
			itemDaTabelaDePreco = await findItemTabelaDePrecoUseCase.getByIdDoProduto(tabelaDoCliente.id, item.produto.id)
				.catch(() => null)
		}
		if (!itemDaTabelaDePreco && tabelaPadrao) {
			percentualCashbackDaTabela = tabelaPadrao.percentualCashback
			diasParaEfetivarCashbackDaTabela = tabelaPadrao.diasParaEfetuarCashback
			diasParaExpirarCashbackDaTabela = tabelaPadrao.diasParaExpirarCashback
			itemDaTabelaDePreco = await findItemTabelaDePrecoUseCase.getByIdDoProduto(tabelaPadrao.id, item.produto.id)
				.catch(() => null)
		}
		const itemComPrecoAplicado: ItemDaVenda = itemDaTabelaDePreco
			? aplicarTabelaNoItemDaVenda(itemDaTabelaDePreco, item, percentualCashbackDaTabela, diasParaEfetivarCashbackDaTabela, diasParaExpirarCashbackDaTabela)
			: aplicarPrecoBaseDoProdutoNoItemDaVenda(item)
		
		if((vendaAtual.isConsignado || vendaAtual.isDemonstracao) && vendaAtual.isVendaReaberta) return itemComPrecoAplicado

		if (itemComPrecoAplicado?.preco === 0)
			throw new Error(`Produto ${displayNomeCompletoDoProduto(item.produto)} está com preço zerado`)
		
		if(!cpfOuCnpjDoCliente && itemDaTabelaDePreco && itemDaTabelaDePreco.qtdeLimitadaDoProduto > 0 && itemDaTabelaDePreco.limitarProduto)
			throw new Error('Produto possui limite, favor informar CPF')
		
	
		if(itemDaTabelaDePreco && itemDaTabelaDePreco.qtdeLimitadaDoProduto > 0 && cpfOuCnpjDoCliente && itemDaTabelaDePreco.limitarProduto && validarLimiteDeProduto) {
			const numeroDeVendaPorCliente = await findItemTabelaDePrecoUseCase.buscarQuantidadeCompradaDoItemHaDoisDias(itemDaTabelaDePreco.id, cpfOuCnpjDoCliente)
			const quantidadeDeItensNaVenda = vendaAtual ? vendaAtual?.itens
				.filter(itemVenda => itemVenda.produto.id == item.produto.id)
				.reduce((total, { quantidade }) => total + quantidade, 0) + item.quantidade 
				: 0
			const totalDeItens = descontarValor 
				? numeroDeVendaPorCliente + quantidadeDeItensNaVenda - descontarValor 
				: numeroDeVendaPorCliente + quantidadeDeItensNaVenda
			if(totalDeItens > itemDaTabelaDePreco.qtdeLimitadaDoProduto)
				throw new Error(`Cliente já esgotou limite de compras desse produto no dia atual`)
		}

		if(VendaModule.descontoEmSerie) {
			itemComPrecoAplicado.desconto = {
				isPercentual: true,
				valor: VendaModule.descontoEmSerie.valor,
			}
		}

		return itemComPrecoAplicado
	})
	
	return Promise.all(itensPromise)

}

function aplicarTabelaNoItemDaVenda(itemDaTabelaDePreco: ItemDaTabelaDePreco, itemDaVenda: ItemDaVenda, 
	percentualCashbackDaTabela: number, diasParaEfetivarCashbackDaTabela: number, diasParaExpirarCashbackDaTabela: number): ItemDaVenda {
	return {
		...itemDaVenda,
		preco: itemDaTabelaDePreco.precoFinalVarejo,
		precoDeCusto: itemDaTabelaDePreco.precoCompra || 0,

		desconto: { 
			isPercentual: itemDaTabelaDePreco.descontoVarejo && itemDaTabelaDePreco.descontoVarejo > 0 ? true :  false,
			valor: itemDaTabelaDePreco.descontoVarejo ? itemDaTabelaDePreco.descontoVarejo : 0,
		},
    
		percentualCashback: itemDaTabelaDePreco.percentualCashback && itemDaTabelaDePreco.percentualCashback != 0
			? itemDaTabelaDePreco.percentualCashback 
			: percentualCashbackDaTabela,
		diasParaEfetivarCashback: itemDaTabelaDePreco.diasParaEfetuarCashback && itemDaTabelaDePreco.diasParaEfetuarCashback != 0
			? itemDaTabelaDePreco.diasParaEfetuarCashback 
			: diasParaEfetivarCashbackDaTabela,

		diasParaExpirarCashback: itemDaTabelaDePreco.diasParaExpirarCashback && itemDaTabelaDePreco.diasParaExpirarCashback != 0
			? itemDaTabelaDePreco.diasParaExpirarCashback
			: diasParaExpirarCashbackDaTabela,
		possuiDescontoPorTabela: itemDaTabelaDePreco.descontoVarejo && itemDaTabelaDePreco.descontoVarejo > 0 ? true : false,
	}
}

function aplicarPrecoBaseDoProdutoNoItemDaVenda(itemDaVenda: ItemDaVenda): ItemDaVenda {
	return {
		...itemDaVenda,
		preco: itemDaVenda.produto.preco,
		precoDeCusto: 0,
		desconto: {
			isPercentual: itemDaVenda.desconto && itemDaVenda.desconto.valor > 0 ? itemDaVenda.desconto.isPercentual : itemDaVenda.produto.descontoVarejo || itemDaVenda.desconto.isPercentual 
				? true 
				: false,
			valor: itemDaVenda.desconto.valor > 0 ?
				itemDaVenda.desconto.valor : itemDaVenda.desconto.valor == 0 || itemDaVenda.desconto.valor == null ? !itemDaVenda.produto.descontoVarejo ? 0 : itemDaVenda.produto.descontoVarejo : itemDaVenda.desconto.valor,
		},
		possuiDescontoPorTabela: false,
	}
}
