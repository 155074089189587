import { injectable } from 'inversify-props'
import request from '@/common/request';
import { ImportacaoDeNotaServiceAdapter } from '@/usecases';
import { ImportacaoDeNota } from '@/models/ImportacaoDeNota';

const PATH = '/importacoes-de-nota'

@injectable()
export class ImportacaoDeNotaService implements ImportacaoDeNotaServiceAdapter {
	async importar(arquivoNotaXml: File): Promise<ImportacaoDeNota> {
		return await request.post(`${PATH}/gerar`, arquivoNotaXml, { headers: { 'Content-Type': 'application/xml' } })
	}

	async finalizarImportacao(urlDoArquivoJson: string, naoDeveMovimentarEstoque: boolean): Promise<ImportacaoDeNota> {
		return await request.post(`${PATH}/finalizar?urlDoArquivoCsv=${urlDoArquivoJson}&naoDeveMovimentarEstoque=${naoDeveMovimentarEstoque}`)
	}
}
