import { createMotivo, findMotivo, updateMotivo } from "@/api/motivosDeCancelamento"
import { MotivoCancelamento } from "@/models"
import { MotivosDeCancelamentoServiceAdapter } from "@/usecases"
import { injectable } from "inversify-props"

@injectable()
export class MotivosDeCancelamentoService implements MotivosDeCancelamentoServiceAdapter {
	find = async (params?: any) => await findMotivo(params)
	create = async (motivo: MotivoCancelamento) => await createMotivo(motivo)
	update = async (motivo: MotivoCancelamento) => await updateMotivo(motivo)
}