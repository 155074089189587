import { AxiosRequestConfig } from "axios";
import { Inject } from "inversify-props";
import type { ProfissaoServiceAdapter } from "./adapter/ProfissaoServiceAdapter";
import { Profissao, Page } from "@/models";

export class FindProfissaoUseCase {
    @Inject('ProfissaoServiceAdapter')
    private profissaoServiceAdapter!: ProfissaoServiceAdapter;

	findProfissoes = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Profissao>> => {
		const response = await this.profissaoServiceAdapter.findProfissao(params, config)
		return response;
	}
   
}