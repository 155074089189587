import { salvar, remover, findPreVendaByIdentificador, updateSituacao, findPreVendaByFiltro, findPreVendas, findPreVendaListagem } from "@/api/prevenda"
import { PreVenda } from "@/models/pre-venda/PreVenda"
import { PreVendaServiceAdapter } from "@/usecases"
import { AxiosRequestConfig } from "axios"
import { injectable } from "inversify"
@injectable()
export class PreVendaService implements PreVendaServiceAdapter {
	findPreVendaByFiltro = async (params?: any, config?: AxiosRequestConfig) => await findPreVendaByFiltro(params, config)
	findPreVendas = async (params?: any, config?: AxiosRequestConfig) => await findPreVendas(params, config)
	findPreVendaByIdentificador = async (identificadorDaPreVenda: string) => await findPreVendaByIdentificador(identificadorDaPreVenda)
	remover = async (idPreVenda: string) => await remover(idPreVenda)
	salvar = async (preVenda: PreVenda) => await salvar(preVenda)
	updateSituacao = async (preVenda: PreVenda) => await updateSituacao(preVenda)
	findPreVendaListagem = async (params?: any, config?: AxiosRequestConfig) => await findPreVendaListagem(params, config)

}