import { TipoDeRecurso } from '@/models/Recurso'
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds'
import { RouteConfig } from 'vue-router'

const rotas: RouteConfig[] = [
	{
		path: 'tags',
		name: 'Tags',
		components: {
			default: () =>
				import('@/views/application/produtos/TelaDeTags.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'tags' as TipoDeRecurso,
		},
	},
]

export default rotas