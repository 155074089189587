import request from '@/common/request'
import { Usuario, Page, Credenciais, TokenJwt } from '@/models'
import { AxiosRequestConfig } from 'axios'

export const authentication = async (data: Credenciais): Promise<TokenJwt> => {
	try {
		return await request.post(`/auth`, data)
	} catch (e: any) {
		throw new Error(e.response.data[0])
	}
}

export const signUp = async (data: any): Promise<Usuario> =>
	request.post(`/usuarios`, data)

export const find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Usuario>> => {
	return request.get(`/usuarios`, {
		params,
		...config,
	})
}

export const findNew = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Usuario>> => {
	return request.get(`/new`, {
		params,
		...config,
	})
}

export const get = async (id: string): Promise<Usuario> =>
	request.get(`/usuarios/${id}`)

export const getUsuarioLogado = async (token?: string): Promise<Usuario> =>
	request.get(`/usuarios/me`, {
		params: {
			token,
		},
	})

export const update = async (data: Usuario): Promise<Usuario> =>
	request.put(`/usuarios/${data.id}`, data)

export const create = async (data: Usuario): Promise<Usuario> =>
	request.post(`/usuarios`, data)

export const patch = async (usuario: Usuario): Promise<Usuario> =>
	request.patch(`/usuarios/${usuario.id}`, usuario)

export const remove = async (id: string): Promise<void> =>
	request.delete(`usuarios/${id}`)

export const findAllVendedores = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Usuario>> => {
	return request.get(`/usuarios/vendedores`, {
		params,
		...config,
	})
}

export const existeSomenteUmaLojaVinculadaComUsuario = async (id: string): Promise<boolean> =>
	request.get(`usuarios/${id}/existe-somente-uma-loja-vinculada`)
