import { MotivoCancelamento, Page } from "@/models"
import { AxiosRequestConfig } from "axios"
import type { MotivosDeCancelamentoServiceAdapter } from "./adapter"
import { Inject } from "inversify-props"

export class FindMotivosDeCancelamentoUseCase {
	@Inject('MotivosDeCancelamentoServiceAdapter')
	private motivosDeCancelamentoAdapter!: MotivosDeCancelamentoServiceAdapter

	findAll = async (params?: any, config?: AxiosRequestConfig): Promise<Page<MotivoCancelamento>> =>
		this.motivosDeCancelamentoAdapter.find(params, config)
}