import { Fornecedor, FormFornecedor } from './../models/Fornecedor';
import {  Page } from '@/models'
import request from '@/common/request'
import { AxiosRequestConfig } from 'axios'

const PATH = '/fornecedores'

export const create = async (data: FormFornecedor): Promise<Fornecedor> =>
	await request.post(`${PATH}`, data)

export const update = async (data: FormFornecedor): Promise<Fornecedor> =>
	await request.put(`${PATH}/${data.id}`, data)

export const get = async (id: string): Promise<Fornecedor> =>
	await request.get(`${PATH}/${id}`)

export const find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Fornecedor>> =>
	await request.get(`${PATH}`, {
		params,
		...config,
	})

export const remove = async (id: string): Promise<void> =>
	request.delete(`${PATH}/${id}`)