import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds'
import { RouteConfig } from 'vue-router'

const rotasDeLojas: RouteConfig[] = [
	{
		path: 'custos',
		name: 'Custos',
		components: {
			default: () =>
				import('@/views/application/custos/TelaDeRegistrosDeCustos.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			default: { novo: true },
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
	},
]

export default rotasDeLojas