import "reflect-metadata";
import { injectable } from "inversify-props";
import { OrigemServiceAdapter } from "@/usecases";
import { getOrigem, findOrigem } from '@/api/impostos';

@injectable()
export class OrigemService implements OrigemServiceAdapter {
	get = async (id: string) => await getOrigem(id);
	find = async (params?: any) => await findOrigem(params);
}
