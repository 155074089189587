import request from '@/common/request'
import { Page, Manifesto, FormManifesto } from '@/models'

const BASE_URL = '/manifesto'

interface ParamsFindManifesto {	
	busca?: string
}

export const create = async (data: FormManifesto): Promise<Manifesto> =>
	request.post(`${BASE_URL}`, data)

export const update = async (data: FormManifesto): Promise<Manifesto> =>
	await request.put(`${BASE_URL}/${data.id}`, data)	

export const findBySerieNota = (lojaId: string, nnf: string, serie: string): Promise<Page<Manifesto>> => 
	request.get(`${BASE_URL}/${lojaId}/${nnf}/${serie}/nota`)

export const findByMotorista = (motoristaId: string): Promise<Page<Manifesto>> => 
	request.get(`${BASE_URL}/${motoristaId}/motorista`)	

export const find = (params?: ParamsFindManifesto): Promise<Page<Manifesto>> =>
	request.get(`${BASE_URL}`, {
		params,
	})