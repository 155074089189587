import { RouteConfig } from 'vue-router';

const rotasDeAnexo: RouteConfig [] = [
	{
		path: '/anexo/:tipoDeProcesso/:chaveOuIdentificador/:usuarioId',
		name: 'anexo',
		component: () =>
			import('@/views/anexo/AnexarArquivo.vue'),
	},
]

export default rotasDeAnexo