import request from "@/common/request";
import { Page, Cashback, ItemCashback, CashbackListagem } from "@/models";
import { AcaoDeCashback } from "@/models/AcaoDeCashback";
import { CampanhaCashback } from "@/models/CampanhaCashback";
import { AxiosRequestConfig } from "axios";

const basePath = '/cashback'

export const findCashbackPorCliente = (params?: any, config?: AxiosRequestConfig): Promise<Page<Cashback>> =>
	request.get(`${basePath}`, { params, ...config })

export const findItensCashback = (params?: any, config?: AxiosRequestConfig): Promise<Page<ItemCashback>> =>
	request.get(`${basePath}/itens`, { params, ...config })

export const cancelar = (id: string): Promise<Cashback> =>
	request.put(`${basePath}/${id}`)

export const findCampanhas = (params?: any, config?: AxiosRequestConfig): Promise<Page<CampanhaCashback>> =>
	request.get(`${basePath}/campanhas`, { params, ...config })

export const criarCampanha = (campanhaCashback: CampanhaCashback): Promise<CampanhaCashback> =>
	request.post(`${basePath}/campanhas`, campanhaCashback)

export const atualizarCampanha = (campanhaCashback: CampanhaCashback): Promise<CampanhaCashback> =>
	request.put(`${basePath}/campanhas/${campanhaCashback.id}`, campanhaCashback)

export const findCampanhasAplicaveis = (tipoClienteId: string | null, lojaId: string): Promise<CampanhaCashback[]> =>
	request.get(`${basePath}/campanhas/aplicaveis?lojaId=${lojaId}${!tipoClienteId ? '' : '&tipoClienteId=' + tipoClienteId}`)

export const findListagemDeCashback = (params?: any, config?: AxiosRequestConfig): Promise<Page<CashbackListagem>> =>
	request.get(`${basePath}/listagem`, { params, ...config })

export const get = (id: string): Promise<Cashback> =>
	request.get(`${basePath}/${id}`)

export const importarCashback = async (
	arquivoContemCabecalho: boolean,
	urlDoArquivoCsv: string,
): Promise<void> =>
	request.post(`${basePath}/importacao?arquivoContemCabecalho=${arquivoContemCabecalho}&urlDoArquivoCsv=${urlDoArquivoCsv}`)

export const criarAcaoDeCashback = (acaoDeCashback: AcaoDeCashback): Promise<AcaoDeCashback> => 
	request.post(`${basePath}/criar-acao`, acaoDeCashback)

export const findAcoesDeCashback = (params?: any, config?: AxiosRequestConfig): Promise<Page<AcaoDeCashback>> => 
	request.get(`${basePath}/listar-acoes`, {params, ...config})

export const atualizarAcaoDeCashback = (acaoDeCashback: AcaoDeCashback): Promise<AcaoDeCashback> =>
	request.put(`${basePath}/atualizar-acao/${acaoDeCashback.id}`, acaoDeCashback)
