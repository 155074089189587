import request from '@/common/request'
import { Page, Pageable } from '@/models'
import { LogsDePedidos } from '@/models/venda/LogsDePedidos'
import { PedidoDeVenda } from '@/models/venda/PedidoDeVenda'
import { AxiosRequestConfig } from 'axios'

const basePath = '/pedidos'

export const update = (pedido: PedidoDeVenda): Promise<PedidoDeVenda> =>
	request.put(`${basePath}/${pedido.id}`, pedido)

export const findLogsByIdDoPedido = (id: string, page: Pageable, config?: AxiosRequestConfig): Promise<Page<LogsDePedidos>> =>
	request.get(`${basePath}/${id}/logs`, {
		params: {
			...page,
		},
		...config,
	})


export const atualizarSituacao = (pedido: PedidoDeVenda): Promise<PedidoDeVenda> =>
	request.patch(`${basePath}/${pedido.id}`, {
		situacaoDoPedido: pedido.situacao,
		observacao: pedido.observacao,
	})