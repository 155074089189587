import { Inject } from 'inversify-props'
import type { FechamentoDeCaixaServiceAdapter } from './adapter'
import { FechamentoDeCaixa, FormDeFechamentoDeCaixa } from '@/models'

export class SaveFechamentoDeCaixaUseCase {
	@Inject('FechamentoDeCaixaServiceAdapter')
	private fechamentoDeCaixaService!: FechamentoDeCaixaServiceAdapter
	
	update = async (fechamentoDeCaixa: FormDeFechamentoDeCaixa): Promise<FechamentoDeCaixa> =>
		await this.fechamentoDeCaixaService.update(fechamentoDeCaixa)
}
