import request from '@/common/request'
import { ambiente } from '@/shareds/utils'
import { FormPlugin, Page, Plugin } from '@/models'

const PATH = '/plugins'

export const create = async (data: FormPlugin): Promise<Plugin> =>
	await request.post(`${PATH}`, data, { params: { env: getEnv() } })

export const update = async (data: FormPlugin): Promise<Plugin> =>
	await request.put(`${PATH}/${data.id}`, data, { params: { env: getEnv() } })

export const get = async (id: string): Promise<Plugin> =>
	await request.get(`${PATH}/${id}`, { params: { env: getEnv() } })

export const remove = async (id: string): Promise<void> =>
	await request.delete(`${PATH}/${id}`, { params: { env: getEnv() } })

export const find = async (): Promise<Page<Plugin>> =>
	await request.get(`${PATH}`, { params: { env: getEnv() } })

function getEnv() {
	switch (ambiente) {
		case 'Produção':
			return 'production'
		case 'Staging':
			return 'staging'
		case '': {
			if (process.env.NODE_ENV === 'production') {
				return 'on-premise'
			}
			return 'development'
		}
		default:
			return undefined
	}
}
