import { Inject } from 'inversify-props'
import type { ConfiguracaoDeCupomServiceAdapter } from './adapter'
import { ConfiguracaoDeCupom } from '@/models'

export class SaveConfiguracaoDeCupomUseCase {
	@Inject('ConfiguracaoDeCupomServiceAdapter')
	private configuracaoDeCupomServiceAdapter!: ConfiguracaoDeCupomServiceAdapter

	create = async (configuracaoDeCupom: ConfiguracaoDeCupom): Promise<ConfiguracaoDeCupom> =>
		await this.configuracaoDeCupomServiceAdapter.create(configuracaoDeCupom)
	
	update = async (configuracaoDeCupom: ConfiguracaoDeCupom): Promise<ConfiguracaoDeCupom> =>
		await this.configuracaoDeCupomServiceAdapter.update(configuracaoDeCupom)

	criarCupons = async (configuracaoDeCupom: ConfiguracaoDeCupom): Promise<void> =>
		await this.configuracaoDeCupomServiceAdapter.criarCupons(configuracaoDeCupom)
}
