import { TipoDeRecurso } from '@/models/Recurso';
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotasDeMetas: RouteConfig[] = [
	{
		path: 'tela-metas',
		name: 'Metas',
		components: {
			default: () =>
				import('@/views/application/metas/TelaDeMetas.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		children: [
			{
				path:'nova-meta',
				name:'Nova Meta',
				component: () =>
					import('@/views/application/metas/EdicaoDeMetas.vue'),
				props: { novo: true },
			},
			{
				path:':id',
				name:'Meta',
				component: () =>
					import('@/views/application/metas/EdicaoDeMetas.vue'),
				props: true,
			},
		],
		meta:{
			recurso: 'tela-de-metas' as TipoDeRecurso,
		},
	},
]
export default rotasDeMetas