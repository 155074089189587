import { Inject } from "inversify-props"
import type { CashbackServiceAdapter } from "./adapter/CashbackServiceAdapter"
import { CampanhaCashback } from "@/models/CampanhaCashback"
import { AcaoDeCashback } from "@/models/AcaoDeCashback"

export class SaveCashbackUseCase {
	@Inject('CashbackServiceAdapter')
	private cashBackServiceAdapter!: CashbackServiceAdapter

	criar = async (campanhaCashback: CampanhaCashback): Promise<CampanhaCashback> =>
		await this.cashBackServiceAdapter.criarCampanha(campanhaCashback)

	atualizar = async (campanhaCashback: CampanhaCashback): Promise<CampanhaCashback> =>
		await this.cashBackServiceAdapter.atualizarCampanha(campanhaCashback)

	criarAcaoDeCashback = async (acaoDeCashback: AcaoDeCashback): Promise<AcaoDeCashback> =>
		await this.cashBackServiceAdapter.criarAcaoDeCashback(acaoDeCashback)

	atualizarAcaoDeCashback = async (acaoDeCashback: AcaoDeCashback): Promise<AcaoDeCashback> =>
		await this.cashBackServiceAdapter.atualizarAcaoDeCashback(acaoDeCashback)
}