import { CodigoEDescricao } from '@/models/shareds/CodigoEDescricao'
import request from '@/common/request'
import { Page, RegraDeEstado, RegraDeImposto, CEST, CapaRegraImposto, CapaRegraImpostoInput, RegraDeAplicacao, RegraPorEstado } from '@/models'
import { AxiosRequestConfig } from 'axios'

export const getCOFINS = (id: string): Promise<CodigoEDescricao> =>
	request.get(`/impostos/cofins/${id}`)

export const findCOFINS = (params?: any): Promise<Page<CodigoEDescricao>> =>
	request.get(`/impostos/cofins`, {
		params: {
			...params,
		},
	})

export const getCEST = (id: string): Promise<CEST> =>
	request.get(`/impostos/cest/${id}`)

export const findCEST = (params?: any, config?: AxiosRequestConfig): Promise<Page<CEST>> =>
	request.get(`/impostos/cest`, {
		params: {
			...params,
		},
		...config,
	})

export const getCFOP = (id: string): Promise<CodigoEDescricao> =>
	request.get(`/impostos/cfop/${id}`)

export const findCFOP = (params?: any): Promise<Page<CodigoEDescricao>> =>
	request.get(`/impostos/cfop`, {
		params: {
			...params,
		},
	})

export const getCSOSN = (id: string): Promise<CodigoEDescricao> =>
	request.get(`/impostos/csosn/${id}`)

export const findCSOSN = (params?: any): Promise<Page<CodigoEDescricao>> =>
	request.get(`/impostos/csosn`, {
		params: {
			...params,
		},
	})

export const getICMS = (id: string): Promise<CodigoEDescricao> =>
	request.get(`/impostos/icms/${id}`)

export const findICMS = (params?: any): Promise<Page<CodigoEDescricao>> =>
	request.get(`/impostos/icms`, {
		params: {
			...params,
		},
	})

export const getNCM = (id: string): Promise<CodigoEDescricao> =>
	request.get(`/impostos/ncm/${id}`)

export const findNCM = (params?: any, config?: AxiosRequestConfig): Promise<Page<CodigoEDescricao>> =>
	request.get(`/impostos/ncm`, {
		params: {
			...params,
		},
		...config,
	})

export const getPIS = (id: string): Promise<CodigoEDescricao> =>
	request.get(`/impostos/pis/${id}`)

export const findPIS = (params?: any): Promise<Page<CodigoEDescricao>> =>
	request.get(`/impostos/pis`, {
		params: {
			...params,
		},
	})

export const getIPI = (id: string): Promise<CodigoEDescricao> =>
	request.get(`/impostos/ipi/${id}`)

export const findIPI = (params?: any): Promise<Page<CodigoEDescricao>> =>
	request.get(`/impostos/ipi`, {
		params: {
			...params,
		},
	})

export const getOrigem = (id: string): Promise<CodigoEDescricao> =>
	request.get(`/impostos/origens/${id}`)

export const findOrigem = (params?: any): Promise<Page<CodigoEDescricao>> =>
	request.get(`/impostos/origens`, {
		params: {
			...params,
		},
	})

export const findRegraDeImposto = (
	params?: any,
	config?: AxiosRequestConfig,
): Promise<Page<RegraDeImposto>> =>
	request.get(`/read-only/regras-de-imposto`, {
		params,
		...config,
	})

export const deletaRegraDeImposto = (id: string): Promise<void> =>
	request.delete(`/regras-imposto/${id}`)

export const getRegraDeImposto = (id: string): Promise<RegraDeImposto> =>
	request.get(`/read-only/regras-de-imposto/${id}`)

export const createRegraDeImposto = (
	regra: CapaRegraImpostoInput,
): Promise<CapaRegraImposto> => request.post(`/regras-imposto`, regra)

export const atualizaRegraImposto = (
	regra: CapaRegraImpostoInput,
): Promise<CapaRegraImposto> =>
	request.put(`/regras-imposto/${regra.id}`, regra)

export const deletaItemDaRegraDeImposto = (
	idItem: string,
): Promise<void> => request.delete(`/regras-imposto/itens/${idItem}`)

export const getItens = (
	regraId: string,
	params?: any,
	config?: AxiosRequestConfig,
): Promise<Page<RegraDeEstado>> => request.get(`/read-only/regras-de-imposto/${regraId}/itens`, {
	params,
	...config,
})

export const adicionarRegraDeAplicacao = (
	idRegra: string,
	item: RegraDeAplicacao,
): Promise<RegraDeAplicacao> => request.post(`/regras-imposto/${idRegra}/regras-aplicacao`, item)

export const findRegraDeAplicacao = (
	params?: any,
	config?: AxiosRequestConfig,
): Promise<Page<RegraDeAplicacao>> =>
	request.get(`/regras-aplicacao`, {
		params,
		...config,
	})

export const findRegrasDeAplicacaoByRegraImposto = (
	idRegraImposto: string,
	params?: any,
	config?: AxiosRequestConfig,
): Promise<Page<RegraDeAplicacao>> =>
	request.get(`/regras-aplicacao/regras-imposto/${idRegraImposto}`, {
		params,
		...config,
	})

export const getRegraDeAplicacao = (id: string): Promise<RegraDeAplicacao> =>
	request.get(`/regras-aplicacao/${id}`)

export const atualizaRegraAplicacao = (
	regra: RegraDeAplicacao,
): Promise<RegraDeAplicacao> =>
	request.put(`/regras-aplicacao/${regra.id}`, regra)

export const deletarRegraDeAplicacao = (
	id: string,
): Promise<void> => request.delete(`/regras-aplicacao/${id}`)

export const copiarRegraDeAplicacao = (
	idRegraDestino: string,
	idRegraOrigem: string,
): Promise<RegraDeAplicacao> => request.put(`/regras-aplicacao/${idRegraDestino}/copiar/${idRegraOrigem}`)

export const findRegraPorEstadoOrigem = (
	idRegraAplicacao: string,
	ufOrigem: string,
	params?: any,
	config?: AxiosRequestConfig,
): Promise<Page<RegraPorEstado>> =>
	request.get(`/regras-estado/regra-aplicacao/${idRegraAplicacao}/estado-origem/${ufOrigem}`, {
		params,
		...config,
	})

export const salvarRegrasPorEstado = (
	idRegraImposto: string,
	idRegraAplicacao: string,
	regrasPorEstado: RegraPorEstado[],
): Promise<RegraPorEstado[]> =>
	request.put(`/regras-estado/regra-Imposto/${idRegraImposto}/regra-aplicacao/${idRegraAplicacao}`,
		regrasPorEstado.map((regra: RegraPorEstado) => {
			return {
				id: regra.id,
				impostos: Object.values(regra.impostos),
				percentualFCP: regra.percentualFCP,
				percentualMVA: regra.percentualMVA,
				tiposDeOperacoesFiscais: regra.tiposDeOperacoesFiscais,
				ufDestino: regra.ufDestino,
				ufOrigem: regra.ufOrigem,
				regraAtiva: regra.regraAtiva,
				reduzIcms: regra.reduzIcms,
				incluirFrete: regra.incluirFrete,
				codigosDeBeneficio: [
					typeof regra.codigosDeBeneficio == 'object' ? null : regra.codigosDeBeneficio,
				],
				codigoCreditoPresumido: regra.codigoCreditoPresumido,
				aliquotaCreditoPresumido: regra.aliquotaCreditoPresumido,
				mensagem: regra.mensagem,
			}
		}),
	)

export const copiarRegraParaEstadoDestino = (
	idRegraOrigem: string,
	idRegraDestino: string,
): Promise<RegraPorEstado> =>
	request.put(`/regras-estado/${idRegraOrigem}/copiar/${idRegraDestino}`)

export const getEstadosOrigemPorRegraAplicacao = (id: string): Promise<string[]> =>
	request.get(`/regras-aplicacao/${id}/estados-origem`)