import { ConfiguracaoDeNotificacaoPorEmail, ConfiguracaoDeNotificacaoPorUsuario, Deposito, Loja, LojaDoPdv, Page, ParametrosControlPay } from '@/models'
import { Inject } from 'inversify-props'
import type { LojaServiceAdapter } from '@/usecases'
import { AxiosRequestConfig } from 'axios'
import { SerieFiscal } from '@/models/SerieFiscal'

export class FindLojaUseCase {
	@Inject('LojaServiceAdapter')
	private lojaService!: LojaServiceAdapter

	async findLojaById(id: string): Promise<Loja> {
		return this.lojaService.get(id)
	}

	async findDepositoDaLojaById(id: string, params?: AxiosRequestConfig): Promise<Page<Deposito>> {
		return this.lojaService.getDepositoDaLoja(id, params)
	}

	find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Loja>> =>
		await this.lojaService.find(params, config)

	buscarParametrosControlPay = async (lojaId: string): Promise<ParametrosControlPay> =>
		await this.lojaService.buscarParametrosControlPay(lojaId)

	findLojaDaTabela = async (tabelaId: string): Promise<Page<LojaDoPdv>> =>
		await this.lojaService.findLojaDaTabela(tabelaId)

	buscarSeriesDeDanfes = async (lojaId: string): Promise<SerieFiscal[]> =>
		await this.lojaService.buscarSeriesDeDanfes(lojaId)

	findLojasVinculadasComCliente = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Loja>> =>
		await this.lojaService.findLojasVinculadasComCliente(params, config)

	async findLojasVinculadasByLojaId(params?: any, config?: AxiosRequestConfig): Promise<Page<Loja>> {
		return this.lojaService.findLojasVinculadasByLojaId(params, config)
	}

	findLojasVinculadasAsLojasDoUsuario = async (): Promise<Loja[]> =>
		await this.lojaService.findLojasVinculadasAsLojasDoUsuario()

	async findConfigDeNotificacaoPorUsuario(id: string): Promise<ConfiguracaoDeNotificacaoPorUsuario[]> {
		return this.lojaService.findConfigDeNotificacaoPorUsuario(id)
	}

	async findConfigDeNotificacaoPorEmail(id: string): Promise<ConfiguracaoDeNotificacaoPorEmail[]> {
		return this.lojaService.findConfigDeNotificacaoPorEmail(id)
	}

	buscarTodasAsLojasPorFiltro = async (params?: any, config?: AxiosRequestConfig): Promise<Loja[]> =>
		await this.lojaService.buscarTodasAsLojasPorFiltro(params, config)

	findLojasByGrupoEconomicoId = async(grupoEconomicoId: string, config?: AxiosRequestConfig): Promise<Page<Loja>> => 
		await this.lojaService.findLojasByGrupoEconomicoId(grupoEconomicoId, config)
}
