import { TipoDeClienteServiceAdapter } from '@/usecases'
import { create, update, find, get, remove } from '@/api/tiposdecliente'
import { FormTipoDeCliente } from '@/models'

export class TipoDeClienteService implements TipoDeClienteServiceAdapter {
	create = async (tipo: FormTipoDeCliente) => create(tipo)
	update = async (tipo: FormTipoDeCliente) => update(tipo)
	get = async (id: string) => get(id)
	find = async (params?: any) => find(params)
	remove = async (id: string) => remove(id)
}
