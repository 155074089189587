import request from '@/common/request'
import { TipoDeCliente, Page, FormTipoDeCliente } from '@/models'

const path = '/tipos-de-cliente'
export const create = async (data: FormTipoDeCliente): Promise<TipoDeCliente> =>
	request.post(`${path}`, data)

export const update = async (data: FormTipoDeCliente): Promise<TipoDeCliente> =>
	request.put(`${path}/${data.id}`, data)

export const find = (params?: any): Promise<Page<TipoDeCliente>> =>
	request.get(`${path}`, { params })

export const get = async (id: string): Promise<TipoDeCliente> =>
	request.get(`${path}/${id}`)

export const remove = async (id: string): Promise<void> =>
	request.delete(`${path}/${id}`)