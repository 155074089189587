import request from "@/common/request";
import { Notificacao, Page, Pageable } from "@/models";
import { AxiosRequestConfig } from "axios";
import { injectable } from "inversify-props";
import { NotificacaoServiceAdapter } from "@/usecases";

export interface FiltroDeNotificacao {
	idDestinatario: string
}

@injectable()
export class NotificacaoService implements NotificacaoServiceAdapter{
	static URL_BASE = '/notificacoes'
	async listar(params?: Pageable & FiltroDeNotificacao, config?: AxiosRequestConfig) {
		return await request.get<never, Page<Notificacao>>(NotificacaoService.URL_BASE, {
			params,
			...config,
		})
	}

	async updateAll (notificacoes: Notificacao[]): Promise<Page<Notificacao>> {
		return request.put(NotificacaoService.URL_BASE, notificacoes)
	}
	
	async update(notificacao: Notificacao): Promise<Notificacao> {
		return request.put(`${NotificacaoService.URL_BASE}/${notificacao.id}`, notificacao)
	}

	async findNotificacoes (params: any, config?: AxiosRequestConfig): Promise<Page<Notificacao>> {
		return request.get(`${NotificacaoService.URL_BASE}/home`, { params, ...config})
	}
}

export default new NotificacaoService()