import { Inject } from 'inversify-props'
import type { AtributoServiceAdapter } from './adapter/AtributoServiceAdapter'
import { Atributo } from '@/models'

export class SaveAtributoUseCase {
	@Inject('AtributoServiceAdapter')
	private atributoService!: AtributoServiceAdapter

	create = async (atributo: Atributo) => await this.atributoService.create(atributo)

	update = async (atributo: Atributo) => await this.atributoService.update(atributo)
}
