import request from '@/common/request'
import {
	ItemDaTabelaDePreco,
	Page,
	TabelaDePreco,
	FormCapaTabelaDePreco,
	ItemGranelTabelaDePreco,
	LojaTabelaPreco,
} from '@/models'
import { AxiosRequestConfig } from 'axios'

const path = '/tabelas-precos'

export const get = async (id: string): Promise<TabelaDePreco> =>
	request.get(`${path}/${id}`)

export const find = async (params: any, config?: AxiosRequestConfig): Promise<Page<TabelaDePreco>> =>
	request.get(`${path}`, {
		params,
		...config,
	})

export const buscaItensPorTabelaDePreco = async (id: string, params?: any, config?: AxiosRequestConfig):
	Promise<Page<ItemDaTabelaDePreco>> =>
	request.get(`${path}/${id}/itens`, {
		params,
		...config,
	})

export const buscarQuantidadeCompradaDoItemHaDoisDias = async (itemTabelaPrecoId: string, cpfOuCnpjDoCliente: string): Promise<number> => 
	request.get(`${path}/quantidade-comprada`, {
		params: {
			itemTabelaPrecoId: itemTabelaPrecoId,
			cpfOuCnpjDoCliente: cpfOuCnpjDoCliente,
		},
	})

export const update = async (data: FormCapaTabelaDePreco): Promise<TabelaDePreco> =>
{
	return request.put(`${path}/${data.id}`, data)
}

export const create = async (data: FormCapaTabelaDePreco): Promise<TabelaDePreco> => {
	return request.post(`${path}`, data)
}

export const criaItem = async (
	idTabela: string,
	item: ItemDaTabelaDePreco,
): Promise<ItemDaTabelaDePreco> =>
	request.post(`${path}/${idTabela}/itens`, item)


export const updateTabelaPreco = async (
	lojasTabelaPreco: LojaTabelaPreco[],
	tabelaId: string,
): Promise<TabelaDePreco> =>
	request.post(`${path}/${tabelaId}/vincular-tabala`, lojasTabelaPreco)


export const importaItem = async (
	idTabela: string,
	arquivoContemCabecalho: boolean,
	urlDoArquivoCsv: string,
): Promise<void> =>
	request.post(`${path}/${idTabela}/itens-em-bloco?arquivoContemCabecalho=${arquivoContemCabecalho}&urlDoArquivoCsv=${urlDoArquivoCsv}`)
  

export const updateItem = async (
	idTabela: string,
	item: ItemDaTabelaDePreco,
): Promise<ItemDaTabelaDePreco> =>
	request.put(`${path}/${idTabela}/itens/${item.id}`, item)

export const removeItem = async (
	idTabela: string,
	idItem: string,
): Promise<void> =>
	request.delete(`${path}/${idTabela}/itens/${idItem}`)


export const desvincularLojaDaTabela = async (
	idTabela: string,
	lojaId: string,
): Promise<void> =>
	request.delete(`${path}/${idTabela}/remove-loja/${lojaId}`)



export const getItemByIdDoProduto = async (
	idTabela: string,
	id: string,
): Promise<ItemDaTabelaDePreco> =>
	request.get(`${path}/${idTabela}/itens`, {
		params: {
			id: id,
		},
	})

export const removeItens = async (
	idTabela: string,
	itens: string[],
): Promise<string[]> =>
	request.patch(`${path}/${idTabela}/itens/`, itens)

export const removeAllItens = async (
	idTabela: string,
): Promise<void> =>
	request.delete(`${path}/${idTabela}/all-itens`)

export const exportAllItens = async (
	idTabela: string,
): Promise<any> =>
	request.get(`${path}/${idTabela}/all-itens/${idTabela}.csv`, {responseType: 'arraybuffer'})

export const remove = async (tabelaId: string): Promise<void> =>
	request.delete(`${path}/${tabelaId}`)

export const listarItensGraneisDaTabelaDePreco = async (
	idTabela: string,
	params?: any,
	config?: AxiosRequestConfig,
): Promise<ItemGranelTabelaDePreco[]> =>
	request.get(`${path}/${idTabela}/itens-graneis`, {
		params,
		...config,
	})
