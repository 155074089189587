import request from "@/common/request"
import { DetalhesDoPagamento } from "@/models"

const basePath = '/detalhesDoPagamento'

export const criarPagamento = (pagamento: DetalhesDoPagamento): Promise<DetalhesDoPagamento> => 
	request.post(`${basePath}`, {...pagamento})

export const remove = async (detalhesDoPagamentoId: string): Promise<void> => 
	request.delete(`${basePath}/${detalhesDoPagamentoId}`)

export const updateDetalhesDoPagamento = (detalhesDoPagamentoId: string, detalhesDePagamento: DetalhesDoPagamento): Promise<void> => 
	request.put(`${basePath}/${detalhesDoPagamentoId}`, {...detalhesDePagamento})