import { criarCartaDeCorrecao, findCartasDeCorrecao } from "@/api/cartaDeCorrecao";
import { CartaDeCorrecao } from "@/models/fiscal/CartaDeCorrecao";
import { CartaDeCorrecaoServiceAdapter } from "@/usecases";
import { AxiosRequestConfig } from "axios";
import { injectable } from "inversify";

@injectable()
export class CartaDeCorrecaoService implements CartaDeCorrecaoServiceAdapter {
	criarCartaDeCorrecao = async (carta: CartaDeCorrecao) => await criarCartaDeCorrecao(carta)
	findCartasDeCorrecao = async (params?: any, config?: AxiosRequestConfig) => await findCartasDeCorrecao(params, config)
}