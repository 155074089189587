import request from '@/common/request'
import { FindNumerosInutilizaveisParams, SerieENumeroNfParam } from '@/usecases'
import { AxiosRequestConfig } from 'axios'
import { Page, SerieENumeroNF, SerieInutilizada } from '@/models'

const BASE_URL = '/fiscal'
export const findNumeros = async (params?: FindNumerosInutilizaveisParams, config?: AxiosRequestConfig): Promise<Page<SerieENumeroNF>> =>
	request.get(`${BASE_URL}/inutilizacao`, {
		params,
		...config,
	})

export const inutiliza = async (seriesFiscais: SerieENumeroNfParam[]): Promise<SerieInutilizada[]> =>
	request.post(`${BASE_URL}/inutilizacao`, seriesFiscais)
