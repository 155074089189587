import { Inject } from "inversify-props";
import type { CartaDeCorrecaoServiceAdapter } from "./adapter";
import { CartaDeCorrecao } from "@/models/fiscal/CartaDeCorrecao";
import { AxiosRequestConfig } from "axios";
import { Page } from "@/models";

export class CartaDeCorrecaoUseCase {
	
	@Inject('CartaDeCorrecaoServiceAdapter')
	private cartaDeCorrecaoServiceAdapter!: CartaDeCorrecaoServiceAdapter

	async criarCartaDeCorrecao(carta: CartaDeCorrecao): Promise<void> {
		await this.cartaDeCorrecaoServiceAdapter.criarCartaDeCorrecao(carta)
	}

	async findCartasDeCorrecao(params?: any, config?: AxiosRequestConfig): Promise<Page<CartaDeCorrecao>> {
		return this.cartaDeCorrecaoServiceAdapter.findCartasDeCorrecao(params, config);
	}
}