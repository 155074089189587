import { createMotivo, findMotivo, updateMotivo } from "@/api/motivosDeCancelamentoFiscal"
import { MotivosCancelamentoFiscal } from "@/models"
import { MotivoDeCancelamentoFiscalServiceAdapter } from "@/usecases"
import { injectable } from "inversify-props"

@injectable()
export class MotivoDeCancelamentoFiscalService implements MotivoDeCancelamentoFiscalServiceAdapter {
	find = async (params?: any) => await findMotivo(params)
	create = async (motivo: MotivosCancelamentoFiscal) => await createMotivo(motivo)
	update = async (motivo: MotivosCancelamentoFiscal) => await updateMotivo(motivo)
}