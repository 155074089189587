import { Inject } from 'inversify-props'
import type { BaixaDePagamentoServiceAdapter } from './adapter/BaixaDePagamentoServiceAdapter'
import { BaixaDePagamentoForm, BaixaDePagamentoMultiploForm } from '@/models/financeiro/BaixaDePagamento'

export class CreateBaixaDePagamentoUseCase {
	@Inject('BaixaDePagamentoServiceAdapter')
	private baixaDePagamentoServiceAdapter!: BaixaDePagamentoServiceAdapter

	create = async(baixa: BaixaDePagamentoForm): Promise<any> =>
		await this.baixaDePagamentoServiceAdapter.create(baixa)

	createMultiplo = async(baixa: BaixaDePagamentoMultiploForm): Promise<any> =>
		await this.baixaDePagamentoServiceAdapter.createMultiplo(baixa)


}