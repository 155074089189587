import criarPromiseAdiavel, { PromiseAdiavel } from "@/helpers/criarPromiseAdiavel"

export interface Plugin {
	id: string
	nome: string
	url: string

	debug: boolean
	montado?: boolean
	pronto?: boolean

	configs: PluginConfig
	dialog: PluginDialog

	ativo: boolean
	env: string
	configsPorLoja: ConfigPorLoja[]

	ping: () => void
	pong: () => void

	listeners: PluginListeners
	messageHandler: (e: MessageEvent) => void

	aguardarInicializacao: PromiseAdiavel<any>
}

export interface FormPlugin {
	id?: string
	nome: string
	url: string
	debug: boolean
	configs: PluginConfig
	ativo: boolean
	env: string
	configsPorLoja: ConfigPorLoja[]
}

export type PluginListeners = {
	[key in EventName]?: (params?: any) => any
}

export interface PluginDialog {
	mostrar: boolean
	fullscreen: boolean
	width?: string
	height?: string
}

export interface PluginConfig {
	[key: string]: string
}

export interface ConfigPorLoja {
	id?: string
	pluginId: string
	lojaId: string
	chave: string
	valor: string
}

export type EventName =
	| 'default'
	| 'ping'
	| 'pong'
	| 'ready'
	| 'dialog'
	| 'selecionarTipoDePagamento'
	| 'adicionarPagamento'
	| 'testeFuncaoAsync'
	| 'error'
	| 'success'
	| 'warning'
	| 'print-pdf-make'
	| 'dialog-close'
	| 'finalizarVenda'
	| 'adicionar-botao-customizado'
	| 'alternar-rota'
	| 'buscar-itens-graneis-da-tabela'
	| 'enviar-itens-da-tabela'
	| 'aplicarClienteNaVenda'
	| 'abrirPagamentos'
	| 'get-token'
	| 'set-token'
	| string

export interface MensagemDoPlugin {
	type: EventName
	params?: any
}

export interface PluginHandler {
	type: EventName
	params: any
	pluginId: string
}

export class Plugin implements Plugin {
	constructor(plugin: Plugin) {
		this.id = plugin.id
		this.nome = plugin.nome
		this.url = plugin.url
		this.debug = !!plugin.debug

		this.montado = false
		this.pronto = false

		this.dialog = {
			mostrar: false,
			fullscreen: false,
			width: '100%',
			height: '80vh',
		}

		this.configs = plugin.configs || {}

		this.listeners = {}

		this.aguardarInicializacao = criarPromiseAdiavel()
		this.messageHandler = (event: MessageEvent) => {
			const { type, params, pluginId } = event.data
			if (pluginId !== this.id) return
			this.onMessage(type, params, event.ports[0])
		}

		this.ativo = plugin.ativo
		this.env = plugin.env
		this.configsPorLoja = plugin.configsPorLoja
	}

	get iframeId() {
		return `almode-plugin-frame-${this.id}`
	}

	get iframe() {
		const iframe = document.getElementById(this.iframeId) as HTMLIFrameElement
		return iframe
	}

	get src() {
		const queryStringParams = new URLSearchParams({
			pluginId: this.id,
			origin: window.location.href,
			...this.configs,
		})

		if (this.debug) queryStringParams.set('debug', 'true')

		const src = `${this.url}?${queryStringParams.toString()}`
		return src
	}

	log(...logs: any[]) {
		if (this.debug) console.log(...logs)
	}

	mount() {
		this.montado = true
		this.log('plugin montado:' + this.nome)

		this.setupDefaultListeners()
		window.addEventListener('message', this.messageHandler)
	}

	unmount() {
		this.montado = false
		this.pronto = false
		this.log('plugin desmontado:' + this.nome)
		window.removeEventListener('message', this.messageHandler)
	}

	postMessage(type: EventName, params?: any, port?: MessagePort) {
		const data = { pluginId: this.id, type, params }
		const ports = port ? [port] : undefined

		this.log(`Mensagem enviado para plugin ${this.nome}`, data)

		this.iframe.contentWindow?.postMessage(data, this.url, ports)
	}

	async onMessage(type: EventName, params?: any, port?: MessagePort) {
		this.log(`Mensagem recebida do plugin ${this.nome}`, type, params)
		const listener = this.getListener(type)
		const result = await listener(params)
		if (port) port.postMessage({ result })
	}

	async asyncMessage(type: EventName, params?: any) {
		return new Promise((resolve, reject) => {
			const channel = new MessageChannel()

			channel.port1.onmessage = ({ data }) => {
				this.log(`Resposta recebida do plugin ${this.nome}`, data)
				channel.port1.close()
				if (data.error) {
					reject(data.error)
				} else {
					resolve(data.result)
				}
			}

			this.postMessage(type, params, channel.port2)
		})
	}

	on(type: EventName, listener: PluginListeners[typeof type]) {
		if (typeof listener !== 'function') {
			throw new Error(`Plugin listener expected to be a function, received '${listener}'`)
		}
		this.listeners[type] = listener
	}

	emit(type: EventName, params?: any) {
		this.aguardarInicializacao.then(() => {
			this.postMessage(type, params)
		})
	}

	removeListener(type: EventName) {
		delete this.listeners[type]
	}

	getListener(type: EventName) {
		const listener = this.listeners[type]
		if (listener) {
			return listener
		} else {
			console.log('thislisteners',this.listeners)
			this.log(`Evento '${type}' sem listener definido.`)
			return () => false
		}
	}

	setupDefaultListeners() {
		this.on('ready', (ready: boolean) => {
			this.aguardarInicializacao.resolve(ready)
			this.pronto=ready
		})
		this.on('dialog', (dialog: {
			show: boolean
			width?: string
			height?: string
		}) => {
			this.dialog.mostrar = dialog.show
			this.dialog.width = dialog.width || '100%'
			this.dialog.height = dialog.height || '80vh'
		})
		this.on("get-configs", () => {
			const configs = this.organizarConfigs()
			this.emit('set-configs', configs)
		})
	}

	organizarConfigs() {
		const configs = this.configs
		const configsPorLoja = this.configsPorLoja.reduce((acc,configLoja) => {
			if (configLoja.pluginId!==this.id) return acc
			const {lojaId,chave, valor } = configLoja
			if (!acc[lojaId]) acc[lojaId] = {}
			acc[lojaId][chave] = valor
			return acc
		},{})
		return {...configs,configsPorLoja}
	}

	abrirDialog() {
		if (this.pronto) this.dialog.mostrar = true
	}
}
