import { TipoDeRecurso } from "@/models/Recurso";
import { tituloDaToolbarPeloNomeDaRota } from "@/shareds/router-shareds";
import { RouteConfig } from "vue-router";
const rotasDeCadastroDeMovimentacoes: RouteConfig[] = [
	{
		path: 'movimentacoes',
		name: 'Movimentações',
		components: {
			default: () => 
				import ('@/views/application/movimentacoes/TelaDeMotivoDeMovimentacoes.vue'),
			toolbar: () => 
				import ('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota},
		meta: {
			recurso: 'motivos-da-movimentacao' as TipoDeRecurso,
		},
	},
]
export default rotasDeCadastroDeMovimentacoes