import 'reflect-metadata'
import { Inject } from 'inversify-props'
import type { LojaServiceAdapter } from './adapter'

export class DeleteLojaUseCase {
	@Inject('LojaServiceAdapter')
	private service!: LojaServiceAdapter

	delete = async (id: string): Promise<void> => await this.service.remove(id)

	deletarConfigDeNotificacaoPorUsuario = async (configId: string): Promise<void> => await this.service.deletarConfigDeNotificacaoPorUsuario(configId)

	deletarConfigDeNotificacaoPorEmail = async (configId: string): Promise<void> => await this.service.deletarConfigDeNotificacaoPorEmail(configId)
}
