import { Inject } from 'inversify-props'
import type { ManifestoServiceAdapter } from './adapter'
import { FormManifesto, Manifesto } from '@/models'

export class CreateManifestoUseCase {
	@Inject('ManifestoServiceAdapter')
	private manifestoService!: ManifestoServiceAdapter

	create = async (manifesto: FormManifesto): Promise<Manifesto> =>
		await this.manifestoService.create(manifesto)
}
