import { remove as removeDiacritics } from 'diacritics'

export function isEmptyOrNull(word: string) {
	return !word || word.trim().length === 0
}

export function isInvalidAddress(address: string) {
	// eslint-disable-next-line no-useless-escape
	const validEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return !validEmailRegex.test(address)
}

export function isInvalidPassword(password: string) {
	const passwordRegex = /^[a-zA-Z0-9_.-]*$/
	return !passwordRegex.test(password)
}

export const isAplicacao = (() => {
	//modo de apresentacao
	if (process.env.NODE_ENV === 'staging') {
		return true
	}

	const parts = window.location.host.split('.')
	const domainLength = 3
	return parts.length !== domainLength - 1 && parts[0] !== 'www' && parts[1] !== 'cloudfront'
})()

export const navigationSubdomain = (() => {
	return window.location.host.split('.')[0]
})()

export const navigationDomain = (() => {
	return window.location.host.split('.')
})()


export const backendDomain = () => process.env.VUE_APP_BACKEND_API || '/api'

export const isVestcasa = navigationSubdomain.startsWith('vestcasa')

export const isIostec = navigationSubdomain.startsWith('iostec')

export const isSantaines = navigationSubdomain.startsWith('santaines')

export const isTexcotton = navigationSubdomain.startsWith('texcotton')

export const isMarcatto = navigationSubdomain.startsWith('marcatto')

export const isFormitz = navigationSubdomain.startsWith('formitz')

export const comparacaoDiatrica = (texto: string, substring: string) =>
	removeDiacritics(texto).toUpperCase().includes(removeDiacritics(substring).toUpperCase())

export const cores = [
	'red',
	'orange',
	'yellow',
	'primary',
	'blue',
]

export const ambiente = (() => {
	if (window.location.hostname.endsWith('.com') || window.location.hostname.endsWith('.br')) {
		return 'Produção'
	} else if (window.location.hostname.endsWith('.dev')) {
		return 'Staging'
	} else if (window.location.hostname.endsWith('.it')) {
		return ''
	}
	return 'Máquina do DEV'
})()

export const nextTick = () => new Promise(resolve => setTimeout(() => resolve(undefined)))

export function validarCpfOuCnpj(cpfOuCnpj) {
	if (!cpfOuCnpj) return true

	cpfOuCnpj = cpfOuCnpj.replace(/[^\d]+/g,''); // Remove caracteres não numéricos

	// Verifica se o CPF tem 11 dígitos
	if (cpfOuCnpj.length === 11) {
		if (/^(\d)\1+$/.test(cpfOuCnpj)) {
			return false;
		}

		// Verifica o primeiro dígito verificador
		let sum = 0;
		for (let i = 0; i < 9; i++) {
			sum += parseInt(cpfOuCnpj.charAt(i)) * (10 - i);
		}
		let rest = sum % 11;
		let digit = rest < 2 ? 0 : 11 - rest;
		if (digit !== parseInt(cpfOuCnpj.charAt(9))) {
			return false;
		}

		// Verifica o segundo dígito verificador
		sum = 0;
		for (let i = 0; i < 10; i++) {
			sum += parseInt(cpfOuCnpj.charAt(i)) * (11 - i);
		}
		rest = sum % 11;
		digit = rest < 2 ? 0 : 11 - rest;
		if (digit !== parseInt(cpfOuCnpj.charAt(10))) {
			return false;
		}

		return true;
	}

	if (cpfOuCnpj.length === 14) {
		if (/^(\d)\1+$/.test(cpfOuCnpj)) {
			return false
		}

		let tamanho = cpfOuCnpj.length - 2
		let numeros = cpfOuCnpj.substring(0, tamanho)
		const digitos = cpfOuCnpj.substring(tamanho)
		let soma = 0
		let pos = tamanho - 7

		for(let i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if(pos < 2) {
				pos = 9
			}
		}

		let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

		if(resultado != digitos.charAt(0)) {
			return false;
		}

		tamanho = tamanho + 1;
		numeros = cpfOuCnpj.substring(0, tamanho);
		soma = 0;
		pos = tamanho - 7;

		for(let i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if(pos < 2) {
				pos = 9;
			}
		}

		resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

		if(resultado != digitos.charAt(1)) {
			return false;
		}

		return true;
	}

	return false
}


