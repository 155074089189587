import { Page, Cashback, ItemCashback, CashbackListagem } from "@/models"
import { AxiosRequestConfig } from "axios"
import { Inject } from "inversify-props"
import type { CashbackServiceAdapter } from "./adapter/CashbackServiceAdapter"
import { CampanhaCashback } from "@/models/CampanhaCashback"
import { AcaoDeCashback } from "@/models/AcaoDeCashback"

export class FindCashbackUseCase {
	@Inject('CashbackServiceAdapter')
	private cashBackServiceAdapter!: CashbackServiceAdapter

	findCashBackPorCliente = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Cashback>> =>
		await this.cashBackServiceAdapter.findCashBackPorCliente(params, config)

	findItensCashback = async (params?: any, config?: AxiosRequestConfig): Promise<Page<ItemCashback>> =>
		await this.cashBackServiceAdapter.findItensCashback(params, config) 

	findCampanhas = async (params?: any, config?: AxiosRequestConfig): Promise<Page<CampanhaCashback>> =>
		await this.cashBackServiceAdapter.findCampanhas(params, config)

	findListagemDeCashback = async (params?: any, config?: AxiosRequestConfig): Promise<Page<CashbackListagem>> =>
		await this.cashBackServiceAdapter.findListagemDeCashback(params, config)

	findById = async (id: string): Promise<Cashback> => 
		await this.cashBackServiceAdapter.get(id)

	findAcoesDeCashback = async (params?: any, config?: AxiosRequestConfig): Promise<Page<AcaoDeCashback>> =>
		await this.cashBackServiceAdapter.findAcoesDeCashback(params, config)
}