import { FiltroDeImportacao } from "@/models";
import { ImportacaoServiceAdapter } from "@/usecases/importacao/adapter";
import { AxiosRequestConfig } from "axios";
import { injectable } from "inversify-props";
import { find, findItens } from '@/api/importacoes'

@injectable()
export class ImportacaoService implements ImportacaoServiceAdapter {
	find = async (params?: FiltroDeImportacao, config?: AxiosRequestConfig) => await find(params, config)

	findItens = async (params?: any, config?: AxiosRequestConfig) => await findItens(params, config)
}
