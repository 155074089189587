import "reflect-metadata";
import { injectable } from 'inversify-props'
import { signUpAccount as cadastroConta } from '@/api/contas';
import { putConfiguracaoDaConta, findConfiguracaoDaConta, validarConta} from '@/api/configuracaodaconta';
import { Cadastro, ContaResponse } from '@/models';
import { ContaServiceAdapter } from '@/usecases';
import { ConfiguracaoDaContaForm, ConfiguracaoDaConta } from '@/models/ConfiguracaoDaConta';

@injectable()
export class AccountService implements ContaServiceAdapter {
	signUpAccount = async (data: Cadastro): Promise<ContaResponse> =>
		await cadastroConta(data)

	putConfiguracaoDaConta = async (configuracaoDaContaForm: ConfiguracaoDaContaForm): Promise<ConfiguracaoDaConta> => await putConfiguracaoDaConta(configuracaoDaContaForm)
	findConfiguracaoDaConta = async () => await findConfiguracaoDaConta()
	validarConta = async (email?: string, subdominio?: string) => await validarConta(email, subdominio)
}