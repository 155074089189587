






import {Component, Vue, Watch } from "vue-property-decorator";
import AppStore from "./store/vuex/aplicacao/AppStore";

@Component
export default class App extends Vue {
	get modoNoturno() {
		return AppStore.modoNoturno
	}
	@Watch('modoNoturno', { immediate: true })
	onChangeModoNoturno(modoNoturno: boolean) {
		this.$vuetify.theme.dark = modoNoturno
		window.document.body.style.backgroundColor = modoNoturno ? '#121212' : 'white'
	}
}


