import { TipoDeRecurso } from '@/models/Recurso';
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotasDeRegradeImpostos: RouteConfig [] =[
	{
		path: 'impostos/novo',
		name: 'Criação de Regra de Imposto',
		components: {
			default: () =>
				import('@/views/application/configuracoes/fiscal/TelaDeEdicaoDeRegraDeImposto.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			default: { novo: true },
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'regras-imposto' as TipoDeRecurso,
		},
	},
	{
		path: 'impostos/:id',
		name: 'Edição de Regra de Imposto',
		components: {
			default: () =>
				import('@/views/application/configuracoes/fiscal/TelaDeEdicaoDeRegraDeImposto.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			default: true,
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'regras-imposto' as TipoDeRecurso,
		},
	},
	{
		path: 'impostos/:idRegraImposto/regra-aplicacao/:idRegraAplicacao/estado-origem/:ufOrigem',
		name: 'Edição de Regra por Estado',
		components: {
			default: () =>
				import('@/views/application/configuracoes/fiscal/TelaDeRegraPorEstado.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			default: true,
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'regras-imposto' as TipoDeRecurso,
		},
	},
	{
		path: 'impostos/:id/regra-aplicacao/:idRegraAplicacao',
		name: 'Regra de Aplicação',
		components: {
			default: () =>
				import('@/views/application/configuracoes/fiscal/TelaDeEdicaoDeRegraDeAplicacao.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			default: true,
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'regras-imposto' as TipoDeRecurso,
		},
	},
]

export default rotasDeRegradeImpostos