import { TipoDeRecurso } from '@/models/Recurso'
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds'
import { RouteConfig } from 'vue-router'

const rotasDeClientes: RouteConfig[] = [
	{
		path: 'plugins',
		name: 'Plugins',
		components: {
			default: () => import('@/views/application/plugins/TelaDePlugins.vue'),
			toolbar: () => import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'plugins' as TipoDeRecurso,
		},
	},
]

export default rotasDeClientes
