import { TipoDeRecurso } from '@/models/Recurso';
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotasDeComandas: RouteConfig[] = [
	{
		path: 'comandas',
		name: 'Pedidos Romaneio',
		components: {
			default: () =>
				import('@/views/application/comandas/TelaDeComandas.vue'),
			toolbar: () =>
				import('@/views/application/comandas/ToolbarDeComandas.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'pedidos-romaneio' as TipoDeRecurso,
			operacao: 'criar',
		},
	},
]
export default rotasDeComandas