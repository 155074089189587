import { injectable } from 'inversify-props'
import { DetalhesDoPagamentoServiceAdapter } from "@/usecases/venda/adapter/DetalhesDoPagamentoServiceAdapter";
import { DetalhesDoPagamento } from '@/models';
import { criarPagamento, remove, updateDetalhesDoPagamento } from '@/api/detalhesDoPagamento';

@injectable()
export class DetalhesDoPagamentoService implements DetalhesDoPagamentoServiceAdapter {
	criarPagamento = async (pagamento: DetalhesDoPagamento) => await criarPagamento(pagamento)
	updateDetalhesDoPagamento = async (detalhesDoPagamentoId: string, detalhesDePagamento: DetalhesDoPagamento) => await updateDetalhesDoPagamento(detalhesDoPagamentoId, detalhesDePagamento)
	remove = async (detalhesDoPagamentoId: string): Promise<void> => await remove(detalhesDoPagamentoId)
}


