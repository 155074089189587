import { Inject } from 'inversify-props'
import { Page, Estoque, FiltroDaTelaDeEstoques, EstoqueProjection, EstoqueEmGrade, ListagemDeMovimentacaoDeEstoque, FiltroDeMovimentacaoDeEstoque, ListagemDeMovimentacaoSintetica, DetalhamentoDaReservaDoProduto } from '@/models'
import type { EstoqueServiceAdapter, FiltroDeEstoques } from '.'
import { AxiosRequestConfig } from 'axios'

export class FindEstoqueUseCase {

	@Inject('EstoqueServiceAdapter')
	private estoqueService!: EstoqueServiceAdapter

	findEstoques = async (filtros?: FiltroDaTelaDeEstoques, config?: AxiosRequestConfig): Promise<Page<Estoque>> =>
		await this.estoqueService.buscarEstoques(filtros, config)

	findOtimizado = async (params?: any, config?: AxiosRequestConfig): Promise<Page<EstoqueProjection>> =>
		await this.estoqueService.findOtimizado(params, config)

	getEstoque = async (idDeposito: string, idEstoque: string): Promise<Estoque> =>
		await this.estoqueService.get(idDeposito, idEstoque)

	getEstoqueDoItemDaVendaNaLoja = async (idProduto: string, quantidadeDeItens: string, idLoja: string, autenticadoParaLiberarProdutoSemEstoque: boolean): Promise<Estoque> =>
		await this.estoqueService.getEstoqueDoItemDaVendaNaLoja(idProduto, quantidadeDeItens, idLoja, autenticadoParaLiberarProdutoSemEstoque)

	findEstoqueEmGradePorProduto = async (filtros?: FiltroDeEstoques, config?: AxiosRequestConfig): Promise<EstoqueEmGrade> =>
		await this.estoqueService.findEstoqueEmGradePorProduto(filtros, config)

	findMovimentacaoAnalitica = async (filtro: FiltroDeMovimentacaoDeEstoque, config?: AxiosRequestConfig): Promise<Page<ListagemDeMovimentacaoDeEstoque>> =>
		await this.estoqueService.findMovimentacaoAnalitica(filtro, config)

	findMovimentacaoSintetica = async (filtro: FiltroDeMovimentacaoDeEstoque, config?: AxiosRequestConfig): Promise<Page<ListagemDeMovimentacaoSintetica>> =>
		await this.estoqueService.findMovimentacaoSintetica(filtro, config)

	findDetalhamento = async (params: any, config?: AxiosRequestConfig): Promise<Page<DetalhamentoDaReservaDoProduto>> =>
		await this.estoqueService.findDetalhamento(params, config)

	findMovimentacaoAnaliticaCompleta = async (filtro: FiltroDeMovimentacaoDeEstoque, config?: AxiosRequestConfig): Promise<any> =>
		await this.estoqueService.findMovimentacaoAnaliticaCompleta(filtro, config)

	findAllByDeposito = async (depositoId: string, dataDoInventario: string): Promise<EstoqueProjection[]> =>
		await this.estoqueService.findAllByDeposito(depositoId, dataDoInventario)
}