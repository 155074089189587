import request from '@/common/request'
import { AxiosRequestConfig } from 'axios'
import { ItemDoRankingDeProdutos, Page } from '@/models'

const API_PATH = '/data/produtos'

export const rankingDeProdutos = async (params?: any, config?: AxiosRequestConfig): Promise<Page<ItemDoRankingDeProdutos>> =>
	await request.get(`${API_PATH}`, {
		params: {
			...params,
			...params.page,
		},
		...config,
	})
