import request from "@/common/request";
import { Comanda, ComandaForm, Page } from "@/models";
import { ComandaServiceAdapter } from "@/usecases/comanda/adapter";
import { injectable } from "inversify-props";

@injectable()
export class ComandaService implements ComandaServiceAdapter {
	getComandas(busca: string, idLoja: string): Promise<Page<ComandaForm>> {
		return request.get(`/comandas/${idLoja}/${busca}`);
	}

	update(comanda: Comanda): Promise<Comanda> {
		return request.patch(`/comandas`, comanda)
	}

	findComandasNaoFinalizadasEAbertas(idLoja: string): Promise<ComandaForm[]> {
		return request.get(`/comandas/${idLoja}`)
	}
}