import request from '@/common/request'
import axios from 'axios'
import print from 'print-js'

export async function uploadFilesToS3(files: File[], folderName: string) {
	const arquivoDetalhado = await Promise.all(files.map(async file => {
		const presignedUrl = await request.post<PreSignedBody, string>('/pre-signed-url', {
			fileName: file.name,
			folderName,
			contentType: file.type,
			fileSize: file.size,
			httpMethod: 'PUT',
		})
		const informacoesDosArquivos = await axios.put(
			presignedUrl, file, {
				headers: {
					'Content-Type': file.type,
				},
			},
		)
		return informacoesDosArquivos
	}))
	return arquivoDetalhado
}

export async function uploadFilesPublicToS3(files: File[], folderName: string, usuarioId: string) {
	const arquivoDetalhado = await Promise.all(files.map(async file => {
		const presignedUrl = await request.post<PreSignedBody, string>(`/public/pre-signed-url/${usuarioId}`, {
			fileName: file.name,
			folderName,
			contentType: file.type,
			fileSize: file.size,
			httpMethod: 'PUT',
		})
		const informacoesDosArquivos = await axios.put(
			presignedUrl, file, {
				headers: {
					'Content-Type': file.type,
				},
			},
		)
		return informacoesDosArquivos
	}))
	return arquivoDetalhado
}

export async function downloadFilesFromS3(caminhoDaPasta: string, nomeDoArquivo: string) {
	const extensaoDoArquivo = decodeURIComponent(
		nomeDoArquivo.substring(nomeDoArquivo.lastIndexOf('.') + 1),
	)
	const presignedUrl = await request.post<{fileName: string; contentType: string }, string>('/pre-signed-url', {
		fileName: nomeDoArquivo,
		folderName: caminhoDaPasta,
		contentType: extensaoDoArquivo,
		httpMethod: 'GET',
	})

	if (extensaoDoArquivo === 'pdf') {
		Promise.race([
			new Promise<void>((resolve, reject) => {
				print({
					documentTitle: nomeDoArquivo,
					printable: presignedUrl,
					onError: (error) => reject(error),
					onLoadingEnd: () => resolve(),
				})
			}),
			new Promise((resolve, reject) =>
				setTimeout(() => reject('Erro ao fazer download do arquivo.'), 15000),
			),
		])
		return
	}

	const link = document.createElement('a')
	link.href = await toDataImageURL(presignedUrl)
	link.download = nomeDoArquivo
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}

async function toDataImageURL(url: string) {
	return fetch(url).then((response) => {
		return response.blob()
	}).then(blob => {
		return URL.createObjectURL(blob)
	})
}

export async function obterUrlsAssinadas(xmls: string[]): Promise<SignedUrl[]> {
	const presignedUrls = await request.post<PreSignedBatchBody, SignedUrl[]>('/pre-signed-url/batch', {
		fileNames: xmls.filter(xml => !!xml),
		httpMethod: 'GET',
	})

	return presignedUrls
}

interface PreSignedBody {
	fileName: string
	contentType: string
}

interface PreSignedBatchBody {
	fileNames: string[]
	httpMethod: string
}

export interface SignedUrl {
	srcUrl: string
	sigUrl: string
}