import { Inject } from 'inversify-props'
import type { CategoriaServiceAdapter } from './adapter/CategoriaServiceAdapter'
import { Categoria } from '@/models'

export class SaveCategoriaUseCase {
	@Inject('CategoriaServiceAdapter')
	private categoriaService!: CategoriaServiceAdapter

	create = async (categoria: Categoria) => await this.categoriaService.create(categoria)

	update = async (categoria: Categoria) => await this.categoriaService.update(categoria)
}
