import { injectable } from 'inversify-props'
import { EstacaoServiceAdapter } from '@/usecases/produto/estacao/adapter'
import { Estacao } from '@/models'
import { findEstacao, createEstacao, updateEstacao } from '@/api/produtos'
import { AxiosRequestConfig } from 'axios'

@injectable()
export class EstacaoService implements EstacaoServiceAdapter {
	find = async (params?: any, config?: AxiosRequestConfig) => await findEstacao(params, config)
	create = async (estacao: Estacao) => await createEstacao(estacao)
	update = async (estacao: Estacao) => await updateEstacao(estacao)
}
