import JSzip from 'jszip'
import { DownloadModule, PREFIXO_ITEM } from "@/store/vuex/fiscal/DownloadStore";
import { get, set } from 'idb-keyval';
import { obterUrlsAssinadas, SignedUrl } from '@/shareds/s3/files';
import axios from 'axios';
import { Download, ItemDownload, StatusDownload } from '@/models';

const BATCH_SIZE = 20

export class DownloadZipUseCase {
	
	/**
	 * Pausa o download e envia sinal de cancelamento(axios) para os itens que estivem baixando no momento
	 * @param idDownload Id do download a ser pausado
	 * @returns void
	 */
	pausarDownloads = async (idDownload: string) => {
		DownloadModule.atualizarDownload({
			idDownload,
			status: 'Pausado',
		})

		const tokens = DownloadModule.axiosTokens.filter(d => d.id === idDownload)
		if (!tokens.length) return

		tokens.forEach(item => {
			if (item.token) {
				console.log('Cancelando token ', item.id)
				item.token.cancel()
			}
		})
	}

	/**
	 * Junta todos os arquivos do download em um zip utilizando a biblioteca JSZip e 
	 * envia para a area de download do navegador.
	 * @param idDownload Id do download a ser empacotado seus arquivos em ZIP
	 * @returns void
	 */
	gerarZip = async (idDownload: string) => {

		const download = DownloadModule.downloadsNF.find(({ id }) => id === idDownload)
		if (!download) return

		const zip = new JSzip();

		const promises: Promise<any>[] = []

		download.itens.forEach(item => {
			promises.push(get(`${PREFIXO_ITEM}-${item.id}`).then(data => {
				const urlParts = item.url.split('/')
				zip.file(`${urlParts[urlParts.length-1]}.xml`, data);
			}))
		})
		
		await Promise.all(promises)
		
		zip.generateAsync({type:"blob"})
			.then((content) => {
				this.saveAs(content, `${download.identificador.split(' ').join('-')}.zip`);
			});
	}
	
	private saveAs = async (content: Blob, fileName) => {
		const link = document.createElement('a')
		link.href = URL.createObjectURL(content)
		link.download = fileName
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	/**
	 * Inicia a transfêrencia dos arquivos pertencentes ao download para o indexedDB e atualiza seus status
	 * para pronto informando a flag __erro__ caso ocorra algum erro ex: Arquivo não encontrado .
	 * Os arquivos são baixados em lotes de 20 em 20 definido na constante __BATCH_SIZE__ presente no começo do arquivo
	 * @param id Id do download a iniciar as transferências
	 * @returns void
	 */
	baixarXmls = async (id: string) => {
		const download = DownloadModule.downloadsNF.find(d => d.id === id)
		if (!download) return

		await DownloadModule.atualizarStatusDownload({ idDownload: id, status: 'Em Andamento'})

		const itemsPendentes = this.obterItemsPendentes(download)

		if (this.validarDownloadConcluido(itemsPendentes, id)) return

		try {
			for (let i = 0; i < itemsPendentes.length / BATCH_SIZE; i++) {

				if (!this.verificarStatusDownload(id, "Em Andamento")) return

				const xmls = itemsPendentes.slice(i*BATCH_SIZE, (i*BATCH_SIZE)+BATCH_SIZE)

				// eslint-disable-next-line no-await-in-loop
				const urlAssinados = await obterUrlsAssinadas(xmls)

				const promises = this.montarPromisesDownloadArquivo(urlAssinados, download)

				// eslint-disable-next-line no-await-in-loop
				await Promise.all(promises)
			}
			
			await DownloadModule.concluirDownload(id)
		} catch(err) {
			DownloadModule.atualizarStatusDownload({idDownload: id, status: 'Pausado'})
		}
	}

	/**
	 * Obtém os items pendentes do download que serão baixados
	 * @param download Download que será processado
	 * @returns Itens pendentes já tratados para transformar a url em formato de objeto do S3
	 */
	private obterItemsPendentes(download: Download) {
		const itemsPendentes = download.itens.filter(item => !item.pronto)
		
		return itemsPendentes.map(({ url }) => {
			const splitedUrl = url.split('/')
			return splitedUrl.slice(splitedUrl.length-3, splitedUrl.length).join('/')
		})
	}

	/**
	 * 
	 * @param itensPendentes Array com os items pendentes do download
	 * @param id Id do download a ser processado
	 * @returns __true__ se a lista de itens pendentes for vazia caso contrário __false__
	 */
	private validarDownloadConcluido(itensPendentes: string[], id: string) {
		if (!itensPendentes.length) {
			DownloadModule.concluirDownload(id)
			return true
		}

		return false
	}

	/**
	 * Monta uma lista com todas as promises que farão a transferência dos arquivos de um download
	 * @param urlAssinados Array de SignedUrl conténdo a url origim e a url assinada que serão baixadas
	 * @param download Download que será processado
	 * @returns Array com as Promises de download dos arquivos
	 */
	private montarPromisesDownloadArquivo(urlAssinados: SignedUrl[], download: Download): Promise<void>[] {
		return urlAssinados.map(signedUrl => new Promise<void>((resolve) => {
			const item = download.itens.find(item => item.url.endsWith(signedUrl.srcUrl))
			if (!item) {
				resolve()
				return
			}

			this.baixarArquivo(signedUrl, item, download, resolve)
		}))
	}

	/**
	 * Incia a transferência do arquivo de um item do download utilizando axios
	 * @param signedUrl Objeto contendo a Url Assinada e a Url de origem do item
	 * @param item Item do download que será baixado
	 * @param download Download que o item pertence
	 * @param resolve método resolve da Promise original que será processada ao terminal o download ou na ocorrencia de um erro
	 */
	private baixarArquivo(signedUrl: SignedUrl, item: ItemDownload, download: Download, resolve) {
		const cancelToken = this.adicionarTokenItem(download)

		axios({ url: signedUrl.sigUrl, responseType: 'blob', cancelToken }).then(resp => {
			if (!this.downloadExiste(download.id)) {
				resolve()
				return
			}

			this.salvarDadosDownload(item.id, download.id, resolve, resp.data)
		}).catch(this.resolverItemErro(download.id, item.id, resolve))
	}

	/**
	 * Monta um calcelToken do axios e o adiciona a store
	 * @param download Download para o qual esse token deve ser salvo
	 * @returns string com o token gerado
	 */
	private adicionarTokenItem(download: Download) {
		const cancelSource = axios.CancelToken.source()
		DownloadModule.atualizarTokens([ ...DownloadModule.axiosTokens, { id: download.id, token: cancelSource}])

		return cancelSource.token
	}

	/**
	 * Salva o conteudo do arquivo no indexedDB utilizando como chave o prefixo definido em PREFIXO_ITEM juntamente com o id do item separado por traço(-)
	 * @param idItem Id do item baixado
	 * @param idDownload Id do download do item
	 * @param resolve metodo resolve da promise de origem
	 * @param data __Blob__ de dados baixados do S3 referentes ao arquivo
	 */
	private salvarDadosDownload(idItem, idDownload, resolve, data) {
		set(`${PREFIXO_ITEM}-${idItem}`, data)
			.then(this.resolverItemSucesso(idDownload, idItem, resolve))
			.catch(this.resolverItemErro(idDownload, idItem, resolve))
	}

	/**
	 * Monta um método para ser usado no .then() das promises que processam o arquivo definindo que o item não teve erros na transferencia
	 * @param idDownload Id do download
	 * @param idItem Id do item
	 * @param resolve método __resolve__ da promise de origem
	 * @returns Um método para resolver a Promise alterando o statis do item para pronto com sucesso
	 */
	private resolverItemSucesso(idDownload: string, idItem: string, resolve) {
		return this.resolverItem(idDownload, idItem, false, resolve)
	}

	/**
	 * Monta um método para ser usado no .then() das promises que processam o arquivo definindo que o item teve erros na transferencia
	 * @param idDownload Id do download
	 * @param idItem Id do item
	 * @param resolve método __resolve__ da promise de origem
	 * @returns Um método para resolver a Promise alterando o statis do item para pronto com erro
	 */
	private resolverItemErro(idDownload: string, idItem: string, resolve) {
		return this.resolverItem(idDownload, idItem, true, resolve)
	}

	/**
	 * Helper para a criação de métodos para revolver a promise de download do arquivo
	 * @param idDownload Id do download
	 * @param idItem Id do item
	 * @param erro __boolean__ indicando se houve erro na transferência do arquivo
	 * @param resolve método __resolve__ da promise de origem
	 * @returns Um método para resolver a Promise alterando o statis do item para pronto com o valor de erro informado
	 */
	private resolverItem(idDownload: string, idItem: string, erro: boolean, resolve) {
		return (err) => {
			if (axios.isCancel(err)) {
				resolve()
				return
			}

			return DownloadModule.atualizarStatusItem({
				idDownload,
				idItem,
				pronto: true,
				erro,
			}).then(() => resolve())
		}
	}

	/**
	 * Verificar se o downlad existe na __store__. Método utilizado em alguns contextos onde se faz necessário 
	 * verificar se o download ainda está presente para que não sejam salvos dados de um download que já tenha sido removido
	 * @param id Id do download a ser consultado
	 * @returns __true__ se o download existe caso contrário __false__
	 */
	private downloadExiste(id: string) {
		const download = DownloadModule.downloadsNF.find(d => d.id === id)
		return !!download
	}

	/**
	 * Valida se o download existe e possui o status informado
	 * @param id Id do download a ser consultado
	 * @param status Status que se deseja consultar no download
	 * @returns __true__ se o download existe e possui o status informado caso contrário __false__
	 */
	private verificarStatusDownload(id: string, status: StatusDownload) {
		const download = DownloadModule.downloadsNF.find(d => d.id === id)
		if (!download) return false
		if (download.status != status) {
			return false
		}

		return true
	}
}