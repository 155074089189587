import pdfMake from 'pdfmake/build/pdfmake'
import type { Content, ContentText, TDocumentDefinitions } from 'pdfmake/interfaces'
import vfs from 'pdfmake/build/vfs_fonts.js'
import print from 'print-js'
import { formatCEP } from '@brazilian-utils/brazilian-utils'
import { DetalhesDoPagamento, ItemDaVenda, Pagamento, Venda } from '@/models'
import { formatarCnpjOuCpf, formatarMoeda, aplicarMascaraParaCpfOuCnpjOculto, aplicarMascaraParaEmail, aplicarMascaraParaTelefone } from '../formatadores'
import { dateTimeToPtBrFormat } from '../date/date-utils'
import { ReimpressaoModule } from '@/store/vuex/reimpressao/ReimpressaoStore'
import { obterTotalDeTaxas, obterValorDeFrete } from '../venda-shareds'
import { Decimal } from 'decimal.js'
import { FindLojaUseCase, ValePresenteUseCase } from '@/usecases'
import { ValePresente } from '@/models/views/ValePresente'

window.pdfMake.vfs = vfs.pdfMake.vfs

const SETE = 7
const CENTER = 'center'
const findLojaUseCase = new FindLojaUseCase()


async function gerarDocumentoParaImpressao(venda: Venda, pagamentos: DetalhesDoPagamento[]): Promise<string> {
	console.log(pagamentos)
	const lojaAtualizada =  await findLojaUseCase.findLojaById(venda.pontoDeVenda && venda.pontoDeVenda.loja ? venda.pontoDeVenda?.loja.id : '')
	const valesPresentes = await new ValePresenteUseCase().findValesPresentesByVendaId(venda.id)
	const configLoja = lojaAtualizada.configuracaoDaLoja
	const itensValidosDaVenda = venda.itens.filter((item) => item.motivoCancelamento === null)
	const docDefinition: TDocumentDefinitions = {
		pageSize: {
			width: 230,
			height: 500,
		},
		pageOrientation: 'portrait',
		pageMargins: [ 3, 5, 5, 5 ],
		content: [
			{ text: `${venda.pontoDeVenda?.loja.nomeFantasia || ''}\n`, fontSize: 8, bold: true, alignment: CENTER },
			{ text: `CNPJ: ${formatarCnpjOuCpf(venda.pontoDeVenda?.loja.cnpj || '')}     IE: ${venda.pontoDeVenda?.loja.inscricaoEstadual || ''}\n`, style: 'labels' },
			{ text: `${venda.pontoDeVenda?.loja.endereco.rua || ''}, ${venda.pontoDeVenda?.loja.endereco.numero || ''}, CEP ${formatCEP(venda.pontoDeVenda?.loja.endereco.cep || '')}\n`, style: 'labels' },
			{ text: `Bairro: ${venda.pontoDeVenda?.loja.endereco.bairro || ''}   Cidade/UF: ${venda.pontoDeVenda?.loja.endereco.cidade || ''} - ${venda.pontoDeVenda?.loja.endereco.uf || ''}\n`, style: 'labels' },
			{ text: `Fone: ${venda.pontoDeVenda?.loja.telefones ? venda.pontoDeVenda?.loja.telefones[0] : 'Não consta.'}\n`, style: 'labels' },
			{ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 225, y2: 0, lineWidth: 0.2 }], fontSize: SETE },
			configLoja?.tipoDeCodigo == 'Sku' 
				?
				buildTabelaDosItensDaVenda(itensValidosDaVenda || [], venda, [
					{ text: 'sku', style: 'tableColumns' },
					{ text: 'descricaoDoProduto', style: 'tableColumns' },
					{ text: 'preco', style: 'tableColumns' },
					{ text: 'quantidade', style: 'tableColumns' },
					{ text: 'valorTotalPorItem', style: 'tableColumns' },
				])
				: 
				buildTabelaDosItensDaVenda(itensValidosDaVenda || [], venda, [
					{ text: 'ean', style: 'tableColumns' },
					{ text: 'descricaoDoProduto', style: 'tableColumns' },
					{ text: 'preco', style: 'tableColumns' },
					{ text: 'quantidade', style: 'tableColumns' },
					{ text: 'valorTotalPorItem', style: 'tableColumns' },
				]),
			buildTabelaDasFormasDePagamento(venda.pagamentos || [], [
				{ text: 'formaDePagamento', style: 'tableColumns' },
				{ text: 'valorPago', style: 'tableColumns' },
			]),
			{
				alignment: 'justify',
				columns:[
					{text: venda.troco > 0 ? 'Troco' : '', fontSize: 7, margin:[4,0] },
					{text: venda.troco > 0 ? `R$ ${formatarMoeda(venda.troco) || ''}`: '', alignment: 'right', fontSize: 7 },
				],
			},
			
			{ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 225, y2: 0, lineWidth: 0.2 }], fontSize: SETE },
			{ text: `Emitido em ${dateTimeToPtBrFormat(venda.dataHora || '')}\n`, style: 'labels' },
			{ text: `Ponto de venda: ${venda.pontoDeVenda?.nome}\n`, style: 'labels' },
			{ text: `Venda: ${venda.identificador}\n`, style: 'labels' },
			{ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 225, y2: 0, lineWidth: 0.2 }], fontSize: SETE },
			{ text: `Consumidor: ${venda.cliente?.razaoSocialOuNome || ''}`, style: 'labels' },
			{ text: `CPF/CNPJ: ${aplicarMascaraParaCpfOuCnpjOculto(venda.cpfDoCliente || venda.cliente?.cnpjOuCpf || '')}`, style: 'labels' },
			{ text: `E-mail: ${aplicarMascaraParaEmail(venda.cliente?.email || 'Não informado.')}`, style: 'labels' },
			{ text: `Fone: ${aplicarMascaraParaTelefone(venda.cliente?.telefones[0] || 'Não consta.')}`, style: 'labels' },
			{ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 225, y2: 0, lineWidth: 0.2 }], fontSize: SETE },
			buildQrCode(valesPresentes),
			(valesPresentes && valesPresentes.length > 0 ? { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 225, y2: 0, lineWidth: 0.2 }], margin: [0, 5, 0, 0], fontSize: SETE } : []),
		],
		styles: {
			labels: {
				fontSize: SETE,
				alignment: CENTER,
			},
			tableColumns: {
				bold: true,
				fontSize: SETE,
			},
		},
	}

	const titulo = `Venda: ${[
		venda.identificador,
		aplicarMascaraParaCpfOuCnpjOculto(venda.cpfDoCliente || venda.cliente?.cnpjOuCpf || ''),
		venda.cliente?.razaoSocialOuNome,
	]
		.filter(e => e)
		.join(', ')}`

	ReimpressaoModule.addReimpressao({ titulo , docDefinition })
	return new Promise(
		resolve => pdfMake.createPdf(docDefinition).getBase64((result) => resolve(result)),
	)
}

export async function imprimirCupomSemValorFiscal(venda: Venda, pagamentos: DetalhesDoPagamento[]): Promise<void> {
	const cupomSemValorFiscal: string = await gerarDocumentoParaImpressao(venda, pagamentos)
	return new Promise((resolve, reject) => {
		print({
			printable: cupomSemValorFiscal,
			type: 'pdf',
			base64: true,
			onError: reject,
			onLoadingEnd: resolve,
		})
	})
}

function buildTabelaDasFormasDePagamento(pagamentos: Pagamento[], colunas: ContentText[]) {
	return {
		table: {
			headerRows: 1,
			widths: [ 120, 90 ],
			body: buildCorpoDaTabelaDasFormasDePagamento(pagamentos, colunas),
		},
		layout: {
			hLineWidth: () => 0,
			vLineWidth: () => 0,
		},
	}
}

function buildQrCode(valesPresentes: ValePresente[]): Content[] {
	const body: Content[] = []

	valesPresentes.forEach(vale => {
		body.push({
			table: {
				widths: ['auto', '*'],
				body: [
					[
						{
							qr: vale.id,
							fit: 80,
							margin: [0, 5, 3, 0],
						},
						{
							text: `
								Código: ${formatCodigo(vale.id)}
								Valor do vale-presente: R$${formatarMoeda(vale.valor)}
							`,
							style: 'labels',
							margin: [0, 0, 0, 0],
							alignment: 'left',
						},
					],
				],
			},
			layout: 'noBorders',
		});
	});
	
	return body;
}

function formatCodigo(id) {
	const part1 = id.substring(0, 8);
	const part2 = id.substring(id.length - 4);
	return `${part1}...${part2}`;
}

function buildTabelaDosItensDaVenda(itensDaVenda: ItemDaVenda[], venda: Venda, colunas: ContentText[]) {
	return {
		table: {
			headerRows: 1,
			widths: [ 43, 67, 23, 22, 32 ],
			body: buildCorpoDaTabelaDosItensDaVenda(itensDaVenda, venda ,colunas),
		},
		layout: {
			hLineWidth: () => 0,
			vLineWidth: () => 0,
		},
	}
}

/*function buildPagamentosDaVenda(pagamentos: DetalhesDoPagamento[]) {
	return {
		table: {
			headerRows: 1,
			widths: [ 230 ],
			body: buildPagamentos(pagamentos),
		},
		layout: {
			hLineWidth: () => 0,
			vLineWidth: () => 0,
		},
	}
}

function buildPagamentos(pagamentos: DetalhesDoPagamento[]) {
	const body: ContentText[][] = []

	pagamentos.forEach(pagamento => {
		const dataRow: ContentText[] = []
		dataRow.push({
			text: ` \n - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - \n \n ${pagamento.comprovanteAdquirente}`,
			bold: false,
			fontSize: 8,
			alignment: 'center',
		})

		body.push(dataRow)
	})
	
	if (pagamentos.length === 0) { 
		const dataRow: ContentText[] = []
		body.push(dataRow)
	}

	return body
}*/

function buildCorpoDaTabelaDasFormasDePagamento(pagamentos: Pagamento[], colunas: ContentText[]) {
	const body: ContentText[][] = []
	pagamentos.forEach(pagamento => {
		const dataRow: ContentText[] = []
		colunas.forEach(coluna => {
			let conteudoDaLinha = pagamento[coluna.text as string]
			if (coluna.text === 'formaDePagamento') {
				const formaDePagamento = pagamento.tipoDePagamento.formaDePagamento
				conteudoDaLinha = formaDePagamento !== pagamento.tipoDePagamento.nome
					? `${formaDePagamento} - ${pagamento.tipoDePagamento.nome}`
					: formaDePagamento
			}
			if (coluna.text === 'valorPago') {
				conteudoDaLinha = `R$ ${formatarMoeda(pagamento.valor)}`
			}
			dataRow.push({
				text: conteudoDaLinha,
				bold: false,
				fontSize: SETE,
				alignment: coluna.text === 'valorPago' ? 'right' : 'left',
			})
		})
		body.push(dataRow)
	})
	return body
}

function buildCorpoDaTabelaDosItensDaVenda(itensDaVenda: ItemDaVenda[], venda: Venda, colunas: ContentText[]) {
	const body: ContentText[][] = []
	const colunasDaTabelaDeItens: ContentText[] = [
		{ text: 'Cód.', style: 'tableColumns' },
		{ text: 'Descrição', style: 'tableColumns' },
		{ text: 'V. Unit.', style: 'tableColumns', alignment: 'right' },
		{ text: 'Qtd.', style: 'tableColumns', alignment: 'right' },
		{ text: 'V. Total', style: 'tableColumns', alignment: 'right' },
	]
	body.push(colunasDaTabelaDeItens)
	itensDaVenda.forEach(itemDaVenda => {
		const dataRow: ContentText[] = []
		colunas.forEach(coluna => {
			let conteudoDaLinha = coluna.text === 'preco'
				? `${formatarMoeda(Number(itemDaVenda[coluna.text]))}`
				: itemDaVenda[coluna.text as string]
			if (coluna.text === 'sku') {
				conteudoDaLinha = itemDaVenda.produto.sku
			}
			if (coluna.text === 'ean') {
				conteudoDaLinha = itemDaVenda.produto.eans[0]
			}
			if (coluna.text === 'descricaoDoProduto') {
				conteudoDaLinha = itemDaVenda.produto.nome
			}
			if (coluna.text === 'valorTotalPorItem') {
				conteudoDaLinha = `${valorTotalPorItem(itemDaVenda)}`
			}
			dataRow.push({
				text: conteudoDaLinha,
				bold: false,
				fontSize: SETE,
				alignment: coluna.text === 'valorTotalPorItem' ||
					coluna.text === 'preco' ||
					coluna.text === 'quantidade' ? 'right' : 'left',
			})
		})
		body.push(dataRow)
	})

	body.push(obterDescontoTotalDaVenda(venda))
	if (venda.informacoesDeTransporte.valorFrete && venda.informacoesDeTransporte.valorFrete > 0) {
		body.push(obterFreteDaVenda(venda))
	}
	body.push(obterValorTotalDosItensDaVenda(venda))
	return body
}

function obterDescontoTotalDaVenda(venda: Venda): ContentText[] {
	const itensValidosDaVenda = venda.itens.filter((item) => item.motivoCancelamento === null)
	const descontoTotal = obterDescontoItensValidosPedido(itensValidosDaVenda)
	return [
		{ text: 'Desconto', bold: false, fontSize: 8 },
		{ text: '', bold: true, fontSize: 7 },
		{ text: '', bold: true, fontSize: 7 },
		{ text: ``, bold: true, fontSize: 8 },
		{ text: `${descontoTotal > 0 ? '-' : ''}${formatarMoeda(descontoTotal)}`, bold: false, fontSize: 8, alignment: 'right' },
	]
}

function obterFreteDaVenda(venda: Venda): ContentText[] {
	const freteDaVenda = venda.informacoesDeTransporte.valorFrete ? venda.informacoesDeTransporte.valorFrete : 0
	return [
		{ text: 'Frete', bold: false, fontSize: 8 },
		{ text: '', bold: true, fontSize: 7 },
		{ text: '', bold: true, fontSize: 7 },
		{ text: ``, bold: true, fontSize: 8 },
		{ text: `${formatarMoeda(freteDaVenda)}`, bold: false, fontSize: 8, alignment: 'right' },
	]
}

function obterValorTotalDosItensDaVenda(venda: Venda): ContentText[] {
	const itensValidosDaVenda = venda.itens.filter((item) => item.motivoCancelamento === null)
	const descontoTotal = obterDescontoItensValidosPedido(itensValidosDaVenda)
	const valorUnitarioTotal = itensValidosDaVenda.reduce(
		(total, { quantidade, preco }) => total.add(new Decimal(quantidade).mul(preco)), new Decimal(0))
		.toNumber()
	const valorTotalComDesconto = (valorUnitarioTotal - descontoTotal)
	let quantidadeTotalDeItens: string | number = itensValidosDaVenda.reduce(
		(total, { quantidade }) => total + quantidade, 0,
	)
	const valorTotalDaTaxa = obterTotalDeTaxas(venda)

	quantidadeTotalDeItens = Number.isInteger(quantidadeTotalDeItens)
		? quantidadeTotalDeItens
		: quantidadeTotalDeItens.toFixed(2)
	return [
		{ text: 'TOTAL (R$)', bold: true, fontSize: 8 },
		{ text: '', bold: true, fontSize: 7 },
		{ text: '', bold: true, fontSize: 7 },
		{ text: `${quantidadeTotalDeItens}`, bold: true, fontSize: 8, alignment: 'right' },
		{ text: `${formatarMoeda(valorTotalComDesconto + valorTotalDaTaxa + obterValorDeFrete(venda))}`, bold: true, fontSize: 8, alignment: 'right' },
	]
}

function valorTotalPorItem(itemDaVenda: ItemDaVenda) {
	return formatarMoeda(new Decimal(itemDaVenda.preco).mul(itemDaVenda.quantidade).toNumber())
}

function obterDescontoItensValidosPedido(itensValidos: ItemDaVenda[]){
	const descontoTotal = itensValidos.reduce((total, itemValido) => total + (itemValido.valorDescontoRateadoDoItem || 0), 0)

	return descontoTotal;
}
