import { TipoDeRecurso } from '@/models/Recurso'
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds'
import { RouteConfig } from 'vue-router'

const rotasDeTabelaDePrecos: RouteConfig [] = [
	{
		path: 'precos',
		name: 'Tabelas de Preços',
		components: {
			default: () =>
				import('@/views/application/tabela-de-precos/TelaDeTabelaDePrecos.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'tabela-de-precos' as TipoDeRecurso,
		},
	},
	{
		path: 'precos/:id',
		name: 'Edição de Tabela de Preços',
		components: {
			default: () =>
				import('@/views/application/tabela-de-precos/EdicaoDeTabelaDePrecos.vue'),
			toolbar: () =>
				import('@/views/application/tabela-de-precos/ToolbarEdicaoTabelaDePreco.vue'),
		},
		props: {
			default: true,
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'tabela-de-precos' as TipoDeRecurso,
		},
	},
]

export default rotasDeTabelaDePrecos