import request from "@/common/request"
import { Adquirente, Page } from "@/models"

export const find = async (params?: any): Promise<Page<Adquirente>> =>
	request.get(`/adquirente`, {
		params: {
			...params,
		},
	})

export const create = async (adquirente: Adquirente): Promise<Adquirente> =>
	request.post(`/adquirente`, adquirente)

export const update = async (adquirente: Adquirente): Promise<Adquirente> =>
	request.put(`/adquirente/${adquirente.id}`, adquirente)