import { Inject } from 'inversify-props';
import { CodigoEDescricao } from '@/models/shareds/CodigoEDescricao';
import { Page } from '@/models';
import type { ServiceICMSAdapter } from '../adapter/ServiceICMSAdapter';

export class FindICMSUseCase {
	@Inject("ServiceICMSAdapter")
	private icmsService!: ServiceICMSAdapter

	findById = async (id: string): Promise<CodigoEDescricao> =>
		await this.icmsService.get(id)

	find = async (params?: any): Promise<Page<CodigoEDescricao>> =>
		await this.icmsService.find(params);

}