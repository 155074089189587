import { Inject } from 'inversify-props'
import type { TipoDeClienteServiceAdapter } from '../adapter/'
import { Page, TipoDeCliente } from '@/models'

export class FindTipoDeClienteUseCase {
	@Inject('TipoDeClienteServiceAdapter')
	private tipoDeClienteServiceAdapter!: TipoDeClienteServiceAdapter

	find = async (params?: any): Promise<Page<TipoDeCliente>> =>
		await this.tipoDeClienteServiceAdapter.find(params)

	get = async (id: string): Promise<TipoDeCliente> =>
		await this.tipoDeClienteServiceAdapter.get(id)

}