import { Inject } from 'inversify-props'
import type { TabelaDePrecoServiceAdapter } from './adapter'

export class DeleteTabelaUseCase {
	@Inject('TabelaDePrecoServiceAdapter')
	private tabelaDePrecoService!: TabelaDePrecoServiceAdapter

	delete = async (id: string): Promise<void> =>
		await this.tabelaDePrecoService.removeTabela(id)
}
