import { Inject } from 'inversify-props'
import type { GrupoEconomicoServiceAdapter } from './adapter'

export class DeleteGrupoEconomicoUseCase {
	@Inject('GrupoEconomicoServiceAdapter')
	private grupoEconomicoServiceAdapter!: GrupoEconomicoServiceAdapter

	delete = async (grupoEconomicoId: string): Promise<void> =>
		await this.grupoEconomicoServiceAdapter.delete(grupoEconomicoId)
}
