import { AxiosRequestConfig } from 'axios';
import { Inject } from 'inversify-props'
import type { RelatorioServiceAdapter } from "./adapter";
import { ViewPerfil } from '@/models/views/ViewRelatorio';
import { AgGridState } from '@/models/views/AgGrid';

export class SaveRelatorioUseCase {
	@Inject('RelatorioServiceAdapter')
	private relatorioService!: RelatorioServiceAdapter

	salvarViewPerfis = async (viewPerfis: ViewPerfil[], config?: AxiosRequestConfig): Promise<void> =>
		await this.relatorioService.salvarViewPerfis(viewPerfis, config)

	salvarPreset = async (novoPreset: AgGridState): Promise<void> =>
		await this.relatorioService.salvarPreset(novoPreset)

	compartilharPreset = async (presetId: string, usuarioId: string, nome: string): Promise<void> =>
		await this.relatorioService.compartilharPreset(presetId, usuarioId, nome)
}