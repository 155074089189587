import { StatusDownload } from '@/models';
import type { Download } from '@/models';
import store from '@/store/vuex'
import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import { del, get, set } from 'idb-keyval'
import { CancelTokenSource } from 'axios';

const DOWNLOAD_NF_STORE = "DOWNLOAD_NF_STORE"

export const PREFIXO_ITEM = "DOWNLOAD"

@Module({
	name: 'DownloadStore',
	namespaced: true,
	dynamic: true,
	store,
})
class DownloadStore extends VuexModule {

	downloads: Download[] = []
	notificar = false
	axiosTokensBaixando: DownloadTokens[] = []

	@Mutation
	addDownloads(downloadsNf: Download[]) {
		const downloadsAtual = this.downloads || []
		const idsAtual = downloadsAtual.map(d => d.id)
		const downloadsAdicionar = downloadsNf.filter(d => !idsAtual.some(id => d.id == id))
		if (!downloadsAdicionar.length) return

		this.downloads = [ ...downloadsAtual, ...downloadsAdicionar ]
	}

	@Mutation
	removeDownload(id: string) {
		
		const pos = this.downloads.findIndex(d => d.id != id)
		const downloadsAtual = this.downloads

		const removido = downloadsAtual.splice(pos, 1)

		removido.forEach(({ itens }) => itens.forEach(({ id }) => del(`${PREFIXO_ITEM}-${id}`)))

		this.downloads = downloadsAtual
		
		get(DOWNLOAD_NF_STORE).then(idbVal => {
			if (!idbVal) return
		
			const downloadsSalvar = idbVal.filter(d => d.id !== id)
			set(DOWNLOAD_NF_STORE, downloadsSalvar)
		})
	}

	@MutationAction({ mutate: [ 'downloads' ]})
	async removerTodosDownloads() {
		return { downloads: [] }
	}

	@Mutation
	atualizarDownloadItem(info: AttParams) {
		if (!info.idItem) return
		const downloadsAtual = this.downloads || []
		const pos = downloadsAtual.findIndex(({id}) => id == info.idDownload)
		if (pos < 0) return

		const download = downloadsAtual[pos]
		const downloadAtualizado = modificarStatus(download, info.idItem, info.pronto, info.erro)
		
		downloadsAtual.splice(pos, 1, downloadAtualizado)

		this.downloads = downloadsAtual
	}

	@Mutation
	async atualizarDownload({ idDownload, status }: {
		idDownload: string
		status: StatusDownload
	}) {
		const downloadsAtual = this.downloads || []
		const download = downloadsAtual.find(d => d.id == idDownload)
		if (!download) return

		this.downloads = [
			...downloadsAtual.filter(({ id }) => id != idDownload),
			{
				...download,
				status,
			},
		]
	}

	@Action({ commit: 'addDownloads'})
	async addDownloadsNF(downloadNf: Download[]) {
		this.setNotificar(true)
		return downloadNf
	}

	@Action({ commit: 'addDownloads'})
	async addDownloadsLoadIDB(downloadNf: Download[]) {
		return downloadNf
	}

	@Action({ commit: 'addDownloads'})
	async addDownloadNF(downloadNf: Download) {
		this.setNotificar(true)
		return [ downloadNf ]
	}

	@Action({ commit: 'removeDownload'})
	async removeDownloadNF(id: string) {
		return id
	}

	@Action({ commit: 'atualizarDownloadItem' })
	async atualizarStatusItem(info: AttParams) {
		return info
	}

	@Action({ commit: 'atualizarDownload' })
	async atualizarStatusDownload({ idDownload, status }: {
		idDownload: string
		status: StatusDownload
	}) {
		this.setNotificar(true)
		return { idDownload, status }
	}

	@Action
	async salvarDownloadsIDB() {
		const downloadsSalvar = this.downloadsNF
			.filter(d => d.status !== 'Concluido')
			
		const idbVal = await get(DOWNLOAD_NF_STORE)
		if (idbVal) {
			await set(DOWNLOAD_NF_STORE, [ ...idbVal, ...downloadsSalvar ])
			return
		}

		await set(DOWNLOAD_NF_STORE, downloadsSalvar)
	}

	@Action
	async concluirDownload(idDownload: string) {
		const download = this.downloadsNF.find(d => d.id === idDownload)
		if (!download) return

		if (download.status === 'Concluido') return

		this.atualizarStatusDownload({ idDownload, status: 'Concluido'})

		let idbVal = await get(DOWNLOAD_NF_STORE)
		if (!idbVal) idbVal = []
		
		await set(DOWNLOAD_NF_STORE, [ ...idbVal, { ...download, status: 'Concluido' }])
	}

	@Action
	async loadDownloadsIDBPendentes() {
		const idbVal = await get(DOWNLOAD_NF_STORE)
		if (!idbVal) return
		
		const downloadsCarregar = idbVal.filter(d => d.status !== 'Concluido')
			.map(d => ({
				...d,
				status: d.status === 'Em Andamento' ? 'Pendente' : d.status,
			}))

		this.addDownloadsLoadIDB(downloadsCarregar)
		
		await set(DOWNLOAD_NF_STORE, idbVal.filter(d => !downloadsCarregar.some(d2 => d2.id === d.id)))
	}

	@Action
	async loadDownloadsIDBConcluidos() {
		const idbVal = await get(DOWNLOAD_NF_STORE)
		if (!idbVal) return
		
		const downloadsCarregar = idbVal.filter(d => d.status === 'Concluido')
		this.addDownloadsLoadIDB(downloadsCarregar)
	}

	@MutationAction({ mutate: [ 'notificar' ]})
	async setNotificar(notificar: boolean) {
		return { notificar }
	}

	@MutationAction({ mutate: [ 'axiosTokensBaixando' ]})
	async atualizarTokens(axiosTokensBaixando: DownloadTokens[]) {
		return { axiosTokensBaixando }
	}

	get downloadsNF(): Download[] {
		return this.downloads
	}

	get notificarDownload(): boolean {
		return this.notificar
	}

	get axiosTokens() {
		return this.axiosTokensBaixando
	}
}

export async function possuiDownloadsPendentes(): Promise<boolean> {
	const idbVal = await get(DOWNLOAD_NF_STORE)
	return idbVal.filter(d => d.status !== 'Concluido').length > 0
}

function modificarStatus(download: Download, idItem: string, pronto: boolean, erro: boolean): Download {
	const itens = [ ...download.itens ]

	const pos = itens.findIndex(({ id }) => id === idItem)
	if (pos >= 0) {
		itens.splice(pos, 1, {
			...itens[pos],
			pronto,
			erro,
		})
	}

	return {
		...download,
		itens,
	}
}

interface AttParams { 
	idDownload: string
	idItem: string | null
	erro: boolean
	pronto: boolean
	token?: CancelTokenSource
}

interface DownloadTokens {
	id: string
	token: CancelTokenSource
}

export const DownloadModule = getModule(DownloadStore)

export default getModule(DownloadStore)