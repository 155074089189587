import { Importacao, ItemDeImportacao, Page } from '@/models';
import { AxiosRequestConfig } from 'axios';
import { Inject } from 'inversify-props';
import type { ImportacaoServiceAdapter } from "./adapter";



export class FindImportacaoUseCase {
	@Inject('ImportacaoServiceAdapter')
	private importacaoServiceAdapter!: ImportacaoServiceAdapter

	find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Importacao>> => 
		await this.importacaoServiceAdapter.find(params, config)

	findItens = async (params?: any, config?: AxiosRequestConfig): Promise<Page<ItemDeImportacao>> =>
		await this.importacaoServiceAdapter.findItens(params, config)
		
}