import request from "@/common/request"
import { Page } from "@/models/Page"
import { ItemDaPreVenda, ItemDaPreVendaImportacao } from "@/models/pre-venda/PreVenda"
import { AxiosRequestConfig } from "axios"

const basePath = '/item-pre-venda'

export const importarItens = (preVendaId: string, itensImportacao: ItemDaPreVendaImportacao[]): Promise<void> => 
	request.post(`${basePath}/${preVendaId}/importar`, itensImportacao)

export const findItensDaPreVenda = (params?: any, config?: AxiosRequestConfig): Promise<Page<ItemDaPreVenda>> => 
	request.get(`${basePath}`, {
		params,
		...config,
	})

export const remover = (idItemPreVenda: string): Promise<void> => 
	request.post(`${basePath}/${idItemPreVenda}`)

export const salvarOuAtualizarItemPreVenda = (itemPreVendaForm: ItemDaPreVenda, preVendaId: string): Promise<void> => 
	request.post(`${basePath}/${preVendaId}/salvar-ou-atualizar`, itemPreVendaForm)