import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { Inject } from 'inversify-props'
import type { VendaServiceAdapter } from './adapter'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { criarVendaDoPdv } from '@/shareds/venda-shareds'
import { ItemDaVenda, NotaDaVenda } from "@/models"
import { CancelarVendaUseCase } from './CancelarVendaUseCase'

export class ReabrirVendaUseCase {
	@Inject('VendaServiceAdapter')
	private vendaService!: VendaServiceAdapter
	private cancelarVendaUseCase!: CancelarVendaUseCase

	constructor() {
		this.cancelarVendaUseCase = new CancelarVendaUseCase()
	}

	async reabrir(identificador: string, devolverTudo: boolean) {
		try {
			const page = await this.vendaService.find({
				identificador,
			})
			const vendaOriginal = page.content[0]

			if (!vendaOriginal) throw new Error('Venda não encontrada')
			if (!vendaOriginal.pontoDeVenda) throw new Error('Venda não tem ponto de venda')
			if (vendaOriginal.temDevolucao) throw new Error('Essa venda já foi devolvida')
			if (vendaOriginal.tipoDeTransacao === 'Devolução') throw new Error('Infelizmente, essa venda é uma devolução e não pode ser reaberta.')
			if (this.existeNotaEmitida(vendaOriginal.notas) && !vendaOriginal.isConsignado && !vendaOriginal.isDemonstracao) throw new Error('Essa venda não pode ser recuperada. Possui Nota Fiscal emitida')
			if (vendaOriginal.situacao === 'Cancelada' && (vendaOriginal.isConsignado || vendaOriginal.isDemonstracao)) throw new Error('Essa venda já está cancelada')
			if ((vendaOriginal.isConsignado || vendaOriginal.isDemonstracao) && !this.existeNotaAprovada(vendaOriginal.notas)) throw new Error('Essa venda não pode ser recuperada. Não possui Nota Fiscal emitida')

			const novoIdentificador = await this.vendaService.gerarIdentificadorDeVenda(vendaOriginal.pontoDeVenda.id)

			if (vendaOriginal.situacao !== 'Cancelada' && !(vendaOriginal.isConsignado || vendaOriginal.isDemonstracao)) {
				try {
					await this.cancelarVendaUseCase
						.cancelar(vendaOriginal.id)
				} catch (error: any) {
					VendaModule.iniciarNovaVenda({ identificador: novoIdentificador, novaVendaReaberta: true })
					throw error
				}
			}

			const novaVenda = criarVendaDoPdv(vendaOriginal.pontoDeVenda)
			if (vendaOriginal.notas.length === 0 && (vendaOriginal.isDemonstracao || vendaOriginal.isConsignado)) novaVenda.id = vendaOriginal.id

			if (vendaOriginal.vendedor && vendaOriginal.vendedor.length > 0) {
				novaVenda.vendedor = [vendaOriginal.vendedor[0]]
			}

			novaVenda.cpfDoCliente = vendaOriginal.cpfDoCliente
			novaVenda.cliente = vendaOriginal.cliente
			novaVenda.tipoDeCliente = vendaOriginal.tipoDeCliente
			novaVenda.itens = vendaOriginal.itens.map<ItemDaVenda>(item => ({
				id: '',
				desconto: item.desconto,
				quantidade: item.quantidade,
				preco: item.preco,
				precoDeCusto: item.precoDeCusto,
				produto: item.produto,
				idConjunto: item.idConjunto,
				descontoVarejo: item.descontoVarejo,
				percentualCashback: item.percentualCashback,
				diasParaEfetivarCashback: item.diasParaEfetivarCashback,
				isBrinde: false,
				idItemNotaOrigem: null,
				chaveNotaOrigem: null,
				diasParaExpirarCashback: item.diasParaExpirarCashback,
				possuiDescontoPorTabela: item.possuiDescontoPorTabela,
				valorDescontoRateadoDoItem: 0,
				itemDeDevolucao: item.itemDeDevolucao,
				isTotalmenteTrocado: item.isTotalmenteTrocado,
				motivoCancelamento: item.motivoCancelamento,
				isCompra: false,
				idItemOrigem: '',
				isDaVendaReaberta: true,
				vendedor: item.vendedor,
				autenticadoParaLiberarProdutoSemEstoque: item.autenticadoParaLiberarProdutoSemEstoque,
				vendaOrigemDoItem: item.vendaOrigemDoItem,
				valorFreteRateado: 0,
				isItemReferenteATroca: item.isItemReferenteATroca,
			}))
			novaVenda.desconto = vendaOriginal.desconto
			novaVenda.vendaOrigem = vendaOriginal.isConsignado || vendaOriginal.isDemonstracao ? vendaOriginal.id : vendaOriginal.vendaOrigem
			novaVenda.tipoDeTransacao = vendaOriginal.tipoDeTransacao
			novaVenda.temDevolucao = vendaOriginal.temDevolucao
			novaVenda.duplicadoDe = {
				id: vendaOriginal.id,
				identificador: vendaOriginal.identificador,
			}
			novaVenda.isConsignado = vendaOriginal.isConsignado
			novaVenda.isDemonstracao = vendaOriginal.isDemonstracao
			novaVenda.notas = vendaOriginal.notas

			if (vendaOriginal.notas.filter(nota => nota.cstat === '100').length === 0) {
				novaVenda.modalidadeDeVenda = vendaOriginal.modalidadeDeVenda
			}

			VendaModule.iniciarNovaVenda({ identificador: novoIdentificador, novaVenda, novaVendaReaberta: true, devolverTudo: devolverTudo })

		} catch (e) {
			AlertModule.setError(e)
			throw e
		}

	}

	existeNotaEmitida(notas: NotaDaVenda[]) {
		const notasEmitidas = notas.filter(notaFiscal => notaFiscal.cstat === '100')
		return notasEmitidas.length > 0
	}

	existeNotaAprovada(notas: NotaDaVenda[]) {
		const notasEmitidas = notas.filter(notaFiscal => notaFiscal.cstat === '100' || notaFiscal.cstat === '301' || notaFiscal.cstat === '302')
		return notas.length == 0 ? true : notasEmitidas.length > 0
	}
}