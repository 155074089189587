import { Inject } from 'inversify-props'
import { Tag, Page } from '@/models'
import type { TagServiceAdapter } from './adapter'
import { AxiosRequestConfig } from 'axios'

export class FindTagUseCase {
	@Inject('TagServiceAdapter')
	private tagAdapter!: TagServiceAdapter

	findAll = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Tag>> =>
		this.tagAdapter.find(params, config)

	findTagColecao = async (config?: AxiosRequestConfig): Promise<string[]> =>
		this.tagAdapter.findTagColecao(config)

}
