import { isVestcasa } from '@/shareds/utils'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pt from 'vuetify/src/locale/pt'
import type { ThemeOptions } from 'vuetify/types/services/theme'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
	directives: {
		Ripple,
	},
});

const TEMA_PADRAO: ThemeOptions = {
	themes: {
		light: {
			primary: '#60269E',
			secondary: '#0EB5CB',
		},
		dark: {
			primary: '#60269e',
			secondary: '#424242',
		},
	},
}

const TEMA_VESTCASA: ThemeOptions = {
	themes: {
		dark: {
			primary: '#08C266',
			secondary: '#3d3d29',
		},
		light: {
			primary: '#08C266',
		},
	},
}

export const primaryColor = () => {
	return !isVestcasa ? '#60269E' : '#08C266'
}

export default new Vuetify({
	lang: {
		locales: { pt },
		current: 'pt',
	},
	theme: !isVestcasa ? TEMA_PADRAO : TEMA_VESTCASA,
});
