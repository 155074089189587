import { Inject } from 'inversify-props'
import type { ConsultaNFServiceAdapter } from './adapter/ConsultaNFServiceAdapter'
import { DadosConsultaNF } from '@/models/DadosConsultaNF'

export class ConsultarNFUseCase {

	@Inject('ConsultaNFService')
	private consultaNFService!: ConsultaNFServiceAdapter

	async execute(chaveConsulta: string): Promise<DadosConsultaNF> {
		return this.consultaNFService.consultarNota(chaveConsulta)
	}

}