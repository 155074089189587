import { Page, Profissao } from '@/models'
import request from '@/common/request'
import { AxiosRequestConfig } from 'axios'


const PATH = '/profissoes'

export const findProfissao = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Profissao>> => 
	await request.get(`${PATH}`, {
		params,
		...config,
	})