import request from "@/common/request"
import { Page } from "@/models"
import { PreVenda, PreVendaListagem } from "@/models/pre-venda/PreVenda"
import { AxiosRequestConfig } from "axios"

const basePath = '/pre-venda'

export const findPreVendaByFiltro = (params?: any, config?: AxiosRequestConfig): Promise<PreVenda> =>
	request.get(`${basePath}`, {
		params,
		...config,
	})

export const findPreVendas = (params?: any, config?: AxiosRequestConfig): Promise<Page<PreVenda>> =>
	request.get(`${basePath}/lista`, {
		params,
		...config,
	})

export const findPreVendaListagem = (params?: any, config?: AxiosRequestConfig): Promise<PreVendaListagem[]> =>
	request.get(`${basePath}/listagem`, {
		params,
		...config,
	})

export const findPreVendaByIdentificador = (identificadorDaPreVenda: string): Promise<PreVenda> =>
	request.get(`${basePath}/${identificadorDaPreVenda}`)

export const remover = (idPreVenda: string): Promise<void> =>
	request.post(`${basePath}/remover/${idPreVenda}`)

export const salvar = (preVenda: PreVenda): Promise<PreVenda> =>
	request.post(`${basePath}/salvar`, preVenda)

export const updateSituacao = (preVenda: PreVenda): Promise<void> =>
	request.post(`${basePath}/update-situacao`, preVenda)