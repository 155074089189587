import request from '@/common/request'
import { AxiosRequestConfig } from 'axios'
import { DadosDeCustoDeItensVendidosPorCategoria, DadosDeEstoque, DadosDeVendaProcessada, DadosDeVendasPorLoja, DadosDeVendasPorMarca, DadosDeVendasPorVendedor, DadosDeVendasPorVendedorSemCrediario, DadosDemonstracaoLoja, DadosPagamentoPorLoja, DadosVendasExternasEInternas } from '@/models'

const API_PATH = '/data/vendas'

export const dadosDeVendas = async (params?: any, config?: AxiosRequestConfig): Promise<DadosDeVendaProcessada[]> =>
	await request.get(`${API_PATH}`, {
		params: {
			...params,
			...params.page,
		},
		...config,
	})

export const dadosDeVendasPorVendedor = async (params?: any, config?: AxiosRequestConfig): Promise<DadosDeVendasPorVendedor[]> =>
	await request.get(`${API_PATH}/vendedor`, {
		params: {
			...params,
			...params.page,
		},
		...config,
	})

export const dadosDeVendasPorMarca = async (params?: any, config?: AxiosRequestConfig): Promise<DadosDeVendasPorMarca[]> =>
	await request.get(`${API_PATH}/marca`, {
		params: {
			...params,
			...params.page,
		},
		...config,
	})

export const dadosDeVendasPorVendedorOrcamento = async (params?: any, config?: AxiosRequestConfig): Promise<DadosDeVendasPorVendedor[]> =>
	await request.get(`${API_PATH}/vendedor-orcamento`, {
		params: {
			...params,
			...params.page,
		},
		...config,
	})

export const findDadosDeVendaPorLoja = async (params?: any, config?: AxiosRequestConfig): Promise<DadosDeVendasPorLoja[]> =>
	await request.get(`${API_PATH}/loja`, {
		params: {
			...params,
			...params.page,
		},
		...config,
	})

	
export const findDadosDeVendasOrcamentoPorLoja = async (params?: any, config?: AxiosRequestConfig): Promise<DadosDeVendasPorLoja[]> =>
	await request.get(`${API_PATH}/loja-orcamento`, {
		params: {
			...params,
			...params.page,
		},
		...config,
	})

export const findDadosDeDemonstracaoPorLoja = async (params?: any, config?: AxiosRequestConfig): Promise<DadosDemonstracaoLoja[]> =>
	await request.get(`${API_PATH}/loja-demonstracao`, {
		params: {
			...params,
			...params.page,
		},
		...config,
	})

export const findDadosDePagamentoPorLoja = async (params?: any, config?: AxiosRequestConfig): Promise<DadosPagamentoPorLoja[]> =>
	await request.get(`${API_PATH}/loja-pagamentos`, {
		params: {
			...params,
			...params.page,
		},
		...config,
	})

export const findDadosPagamentosPorLojaOrcamento = async (params?: any, config?: AxiosRequestConfig): Promise<DadosPagamentoPorLoja[]> =>
	await request.get(`${API_PATH}/loja-pagamentos-orcamento`, {
		params: {
			...params,
			...params.page,
		},
		...config,
	})

	

export const findDadosVendasExternasEInternas = async (params?: any, config?: AxiosRequestConfig): Promise<DadosVendasExternasEInternas[]> =>
	await request.get(`${API_PATH}/vendas-internas-externas`, {
		params: {
			...params,
			...params.page,
		},
		...config,
	})

	
export const findDadosVendasOrcamentoExternasDemonstracao = async (params?: any, config?: AxiosRequestConfig): Promise<DadosVendasExternasEInternas[]> =>
	await request.get(`${API_PATH}/vendas-internas-externas-orcamento`, {
		params: {
			...params,
			...params.page,
		},
		...config,
	})

export const findDadosDeEstoque = async (params?: any, config?: AxiosRequestConfig): Promise<DadosDeEstoque[]> =>
	await request.get(`${API_PATH}/estoque-colecao`, {
		params: {
			...params,
			...params.page,
		},
		...config,
	})

export const findDadosCustoDeItensVendidosPorCategoria = async (params?: any, config?: AxiosRequestConfig): Promise<DadosDeCustoDeItensVendidosPorCategoria[]> =>
	await request.get(`${API_PATH}/custo-de-itens-vendidos-por-categoria`, {
		params: {
			...params,
			...params.page,
		},
		...config,
	})

export const dadosDeVendasPorVendedorOrcamentoSemCrediario = async (params?: any, config?: AxiosRequestConfig): Promise<DadosDeVendasPorVendedorSemCrediario[]> =>
	await request.get(`${API_PATH}/vendedor-orcamento-sem-crediario`, {
		params: {
			...params,
			...params.page,
		},
		...config,
	})	

	