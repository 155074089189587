import request from "@/common/request"
import { DetalhamentoDaReservaDoProduto, Estoque, EstoqueEmGrade, EstoqueProjection, FiltroDeMovimentacaoDeEstoque, ListagemDeMovimentacaoDeEstoque, ListagemDeMovimentacaoSintetica, Page } from "@/models"
import { AxiosRequestConfig } from "axios"

const basePath = '/estoques'

export const findEstoques = (params?: any, config?: AxiosRequestConfig): Promise<Page<Estoque>> =>
	request.get(`${basePath}`, {
		params: {
			...params,
		},
		...config,
	})

export const findOtimizado = (params?: any, config?: AxiosRequestConfig): Promise<Page<EstoqueProjection>> =>
	request.get(`${basePath}/lista`, {
		params,
		...config,
	})


export const salvarRomaneio = async (

): Promise<any> => 
	request.post("/romaneio")

export const findEstoqueEmGradePorProduto = async (params?: any, config?: AxiosRequestConfig): Promise<EstoqueEmGrade> =>
	request.get(`${basePath}/grade`, {
		params,
		...config,
	})

export const findMovimentacaoAnalitica = async (filtro: FiltroDeMovimentacaoDeEstoque, config?: AxiosRequestConfig): Promise<Page<ListagemDeMovimentacaoDeEstoque>> =>
	request.get(`${basePath}/listagemDeMovimentacaoAnalitica`, {
		params: {...filtro, ...config},
	})

export const findMovimentacaoSintetica = async (filtro: FiltroDeMovimentacaoDeEstoque, config?: AxiosRequestConfig): Promise<Page<ListagemDeMovimentacaoSintetica>> =>
	request.get(`${basePath}/listagemDeMovimentacaoSintetica`, {
		params: {...filtro, ...config},
	})

export const findDetalhamento = async (params: any, config?: AxiosRequestConfig): Promise<Page<DetalhamentoDaReservaDoProduto>> =>
	request.get(`${basePath}/detalhamento-da-reserva-do-produto`, {params: {...params, ...config} })

export const findMovimentacaoAnaliticaCompleta = async (filtro: FiltroDeMovimentacaoDeEstoque, config?: AxiosRequestConfig): Promise<any> =>
	request.get(`${basePath}/listagemDeMovimentacaoAnalitica/completo`, {
		params: {...filtro, ...config},
	})