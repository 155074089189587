import { ImportacaoDeNota } from '@/models/ImportacaoDeNota'
import { Inject } from 'inversify-props'
import type { ImportacaoDeNotaServiceAdapter } from './adapter'

export class FinalizarImportacaoDeNotaDoFornecedorUseCase {
	@Inject('ImportacaoDeNotaServiceAdapter')
	private importacaoDeNotaServiceAdapter!: ImportacaoDeNotaServiceAdapter

	async execute(urlDoArquivoJson: string, naoDeveMovimentarEstoque: boolean): Promise<ImportacaoDeNota> {
		return await this.importacaoDeNotaServiceAdapter.finalizarImportacao(urlDoArquivoJson, naoDeveMovimentarEstoque)
	}
}