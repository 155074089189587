import { Inject } from 'inversify-props'
import type { VendaServiceAdapter } from './adapter'
import { ItemDaVenda, Page, ResumoDaVenda, Venda, VendaComReferencias } from '@/models'
import { AxiosRequestConfig } from 'axios'

export class FindVendaUseCase {
	@Inject('VendaServiceAdapter')
	private vendaService!: VendaServiceAdapter

	find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Venda>> =>
		await this.vendaService.find(params, config)

	list = async (params?: any, config?: AxiosRequestConfig): Promise<Page<ResumoDaVenda>> =>
		await this.vendaService.listVendas(params, config)

	listVendasSemTurno = async (params?: any, config?: AxiosRequestConfig): Promise<Page<ResumoDaVenda>> =>
		await this.vendaService.listVendasSemTurno(params, config)

	findNumeroDePedidos = async (params?: any, config?: AxiosRequestConfig): Promise<string> =>
		await this.vendaService.findNumeroDePedidos(params, config)

	get = async  (id: string): Promise<Venda> =>
		await this.vendaService.get(id)

	getVendaComReferencias = async  (id: string): Promise<VendaComReferencias> =>
		await this.vendaService.getVendaComReferencias(id)

	listNota = async (params?: any, config?: AxiosRequestConfig): Promise<Page<ResumoDaVenda>> =>
		await this.vendaService.listNota(params, config)

	buscarOrigem = async (produtoId: string, quantidadeLida: number, lojaId: string, cpfOuCnpjCliente: string, itens: ItemDaVenda[]): Promise<ItemDaVenda> =>
		await this.vendaService.buscarOrigem(produtoId, quantidadeLida, lojaId, cpfOuCnpjCliente, itens)
}
