import { PedidoDeVenda } from "@/models/venda/PedidoDeVenda"
import { container } from "inversify-props"
import { PedidoServiceAdapter } from "./adapter/PedidoServiceAdapter"
import {
	imprimirNotaFiscal as imprimirNotaFiscalPadrao,
} from '@/shareds/venda-shareds'
import { Venda } from "@/models"

export default async ({
	venda,
	imprimirNotaFiscal = imprimirNotaFiscalPadrao,
	service = container.get<PedidoServiceAdapter>('PedidoServiceAdapter'),
}: {
	venda: Venda
	imprimirNotaFiscal?: (idVenda: string, imprimirNfe: boolean, isContingencia) => Promise<any>
	service?: PedidoServiceAdapter
}) => {
	const pedidoAEnviar: PedidoDeVenda = JSON.parse(JSON.stringify(venda.pedido))
	pedidoAEnviar.situacao = 'Faturado'
	const pedidoAtualizado = imprimirNotaFiscal(venda.id, true, false)
		.then(async () => {
			const pedidoAtualizado = await service.atualizarSituacao(pedidoAEnviar)
			return pedidoAtualizado
		}).catch(error => { throw error })
	return pedidoAtualizado
}