import request from '@/common/request'
import { ConfiguracaoDeCupom, CupomDeDesconto, Page } from '@/models'
import { AxiosRequestConfig } from 'axios'

const basePath = '/cupons'

export const create = (data: ConfiguracaoDeCupom): Promise<ConfiguracaoDeCupom> =>
	request.post(`${basePath}`, data)

export const get = (id: string): Promise<ConfiguracaoDeCupom> =>
	request.get(`${basePath}/${id}`)

export const findByCodigo = (codigo: string, params?: any, config?: AxiosRequestConfig): Promise<CupomDeDesconto> =>
	request.get(`${basePath}/codigo/${codigo}`, {
		params: {
			...params,
		},
		...config,
	})

export const find = (params?: any, config?: AxiosRequestConfig): Promise<Page<ConfiguracaoDeCupom>> =>
	request.get(`${basePath}`, {
		params: {
			...params,
		},
		...config,
	})

export const update = (data: ConfiguracaoDeCupom): Promise<ConfiguracaoDeCupom> =>
	request.put(`${basePath}/${data.id}`, data)

export const deleteById = (id: string): Promise<void> =>
	request.delete(`${basePath}/${id}`)

export const criarCupons = (data: ConfiguracaoDeCupom): Promise<void> =>
	request.post(`${basePath}/gerar-cupons`, data)
