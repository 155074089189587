import { TipoDeOperacao, TipoDeRecurso } from '@/models/Recurso';
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotasDeEntradas: RouteConfig[] = [
	{
		path: 'fornecedores',
		name: 'Fornecedores',
		components: {
			default: () =>
				import('@/views/application/entradas/TelaDeFornecedores.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'fornecedores' as TipoDeRecurso,
		},
	},
	{
		path: 'importacao-de-notas',
		name: 'Importacao de Notas',
		components: {
			default: () =>
				import('@/views/application/entradas/TelaDeImportacaoDeNota.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'documentos-fiscais' as TipoDeRecurso,
			operacao: 'criar' as TipoDeOperacao,
		},
	},
	{
		path: 'importacoes',
		name: 'Processos',
		components: {
			default: () =>
				import('@/views/application/entradas/TelaDeImportacoes.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'importacoes' as TipoDeRecurso,
		},
	},
	{
		path: 'grid-relatorios',
		name: 'Grid de Relatórios',
		components: {
			default: () =>
				import('@/views/application/entradas/TelaDeRelatorios.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'relatorios-em-grid' as TipoDeRecurso,
		},
	},
	{
		path: 'notas-em-transito',
		name: 'Notas em trânsito',
		components: {
			default: () =>
				import('@/views/application/notas/TelaDeNotasEmTransito.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'notas-em-transito' as TipoDeRecurso,
		},
	},
	{
		path: 'emissao-notas',
		name: 'Emissão de Notas',
		components: {
			default: () =>
				import('@/views/application/venda/TelaDeEmissaoDeNotas.vue'),
			toolbar: () =>
				import('@/views/application/venda/ToolbarDeEmissaoDeNota.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'emissao-notas' as TipoDeRecurso,
		},
	},
	{
		path: 'listagem-de-notas',
		name: 'Listagem De Notas',
		components: {
			default: () =>
				import('@/views/application/venda/TelaDeListagemDeNotas.vue'),
			toolbar: () =>
				import('@/views/application/venda/ToolbarDeVendas.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'listagem-de-notas' as TipoDeRecurso,
		},
	},
]

export default rotasDeEntradas