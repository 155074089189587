import { injectable } from 'inversify-props'
import { MarcaServiceAdapter } from '@/usecases/produto/marca/adapter'
import { Marca } from '@/models'
import { findMarca, createMarca, updateMarca, findMarcaByLojaId, findMarcaPorUsuario } from '@/api/produtos'
import { AxiosRequestConfig } from 'axios'

@injectable()
export class MarcaService implements MarcaServiceAdapter {
	find = async (params?: any, config?: AxiosRequestConfig) => await findMarca(params, config)
	create = async (marca: Marca) => await createMarca(marca)
	update = async (marca: Marca) => await updateMarca(marca)
	findMarcaByLojaId = async (params?: any, config?: AxiosRequestConfig) => await findMarcaByLojaId(params, config)
	findMarcaPorUsuario = async (params?: any, config?: AxiosRequestConfig) => await findMarcaPorUsuario(params, config)
}
