import { create, update, find } from "@/api/bandeiraDeCartao"
import { BandeirasDeCartao } from "@/models"
import { BandeirasDeCartaoServiceAdapter } from "@/usecases"

import { injectable } from "inversify-props"

@injectable()
export class BandeirasDeCartaoService implements BandeirasDeCartaoServiceAdapter {
	find = async (params?: any) => await find(params)
	create = async (bandeira: BandeirasDeCartao) => await create(bandeira)
	update = async (bandeira: BandeirasDeCartao) => await update(bandeira)
}