import { RouteConfig } from 'vue-router';

const rotasDeLojas: RouteConfig[] = [
	{
		path: 'usuarios',
		name: 'Usuários',
		components: {
			default: () =>
				import('@/views/application/usuarios/TelaDeUsuarios.vue'),
			toolbar: () =>
				import('@/components/usuario/ToolbarUsuarios.vue'),
		},
		children: [
			{
				path: 'novo',
				name: 'Novo Usuário',
				component: () =>
					import('@/views/application/usuarios/EdicaoDeUsuario.vue'),
				props: { novo: true },
			},
			{
				path: ':id',
				name: 'Usuário',
				component: () =>
					import('@/views/application/usuarios/EdicaoDeUsuario.vue'),
				props: true,
			},
		],
	},
]
export default rotasDeLojas