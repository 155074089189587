import { create, update, find, remove, findAll, findSequenciaisValidos, findModalidadePadrao, findModalidadesDaOrigem } from "@/api/modalidadeDeVenda"
import { ModalidadeDeVenda, TipoDeOrigemDeVenda } from "@/models"
import { ModalidadeDeVendaServiceAdapter } from "@/usecases/venda/adapter"

import { injectable } from "inversify-props"

@injectable()
export class ModalidadeDeVendaService implements ModalidadeDeVendaServiceAdapter {
	findAll = async (params?: any) => await findAll(params)
	find = async (idLoja?: string) => await find(idLoja)
	findSequenciaisValidos = async (idLoja?: string) => await findSequenciaisValidos(idLoja)
	create = async (lojaId: string, modalidade: ModalidadeDeVenda) => await create(lojaId, modalidade)
	update = async (modalidade: ModalidadeDeVenda) => await update(modalidade)
	remove = async (modalidadeId: string) => await remove(modalidadeId)
	findModalidadePadrao = async (idLoja: string, params: TipoDeOrigemDeVenda) => await findModalidadePadrao(idLoja, params)
	findModalidadesDaOrigem = async (idLoja: string, params: TipoDeOrigemDeVenda) => await findModalidadesDaOrigem(idLoja, params)
}