import{
	get,
	find,
	create,
	update,
	remove,
}from '@/api/meta'
import { Meta} from '@/models/Meta';
import { MetaServiceAdapter } from '@/usecases';
import { AxiosRequestConfig } from 'axios';
import { injectable } from 'inversify-props';


@injectable()
export class MetaService implements MetaServiceAdapter {
	get = async (id: string) => await get(id)
	find = async (params: any, config?: AxiosRequestConfig) => await find(params, config)
	create = async (meta: Meta) => await create(meta)
	update = async (meta: Meta) => await update(meta)
	remove = async (id: string): Promise<void> => await remove(id)
}