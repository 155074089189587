import { injectable } from 'inversify-props'
import { PagamentoServiceAdapter } from '@/usecases/venda'
import { FormaDePagamento, FormPagamento, Pagamento } from '@/models'
import { efetuarPagamentoTef, consultarPagamentoTef, cancelarPagamentoTef, 
	buscarDetalhesDoPagamento, consultarUltimoPagamentoTefPelaVenda, buscarTipoDePagamentoPorForma, 
	salvarPagamento, podeAlterarQtdDeParcelas, findPagamentosDetalhadosByVendaId } from '@/api/pagamento'

@injectable()
export class PagamentoService implements PagamentoServiceAdapter {
	podeAlterarQtdDeParcelas = async (pagamentoId: string) => await podeAlterarQtdDeParcelas(pagamentoId)
	efetuarPagamentoTef = async (idPdv: string, pagamento: FormPagamento, identificadorDaVenda: string) => await efetuarPagamentoTef(idPdv, pagamento, identificadorDaVenda)
	consultarPagamentoTef = async (pdvId: string, detalhesDoPagamentoId: string) => await consultarPagamentoTef(pdvId, detalhesDoPagamentoId)
	cancelarPagamentoTef = async (pdvId: string, detalhesDoPagamentoId: string) => await cancelarPagamentoTef(pdvId, detalhesDoPagamentoId)
	buscarDetalhesDoPagamento = async (detalhesDoPagamentoId: string) => await buscarDetalhesDoPagamento(detalhesDoPagamentoId)
	consultarUltimoPagamentoTefPelaVenda = async (identificadorDaVenda: string) => await consultarUltimoPagamentoTefPelaVenda(identificadorDaVenda)
	buscarTipoDePagamentoPorForma = async (formaDePagamento: FormaDePagamento, lojaId: string) => await buscarTipoDePagamentoPorForma(formaDePagamento, lojaId)
	salvarPagamento = async (pagamento: Pagamento) => await salvarPagamento(pagamento)
	findPagamentosDetalhadosByVendaId = async (vendaId: string) => await findPagamentosDetalhadosByVendaId(vendaId)
}
