import { Inject } from 'inversify-props'
import { Deposito, Page } from '@/models'
import type { DepositoServiceAdapter } from '.'

export class FindDepositoUseCase {
	@Inject('DepositoServiceAdapter')
	private depositoService!: DepositoServiceAdapter

	get = async (id: string): Promise<Deposito> =>
		await this.depositoService.get(id)

	find = async (params?: any): Promise<Page<Deposito>> =>
		await this.depositoService.find(params)

}
