import request from "@/common/request"
import { FiltroDeCapaDaNota } from "@/models"
import { AxiosRequestConfig } from "axios"

const basePath = '/email'

export const enviarValesPorEmail = (idVenda: string, email: string): Promise<void> =>
	request.post(`${basePath}/${idVenda}/vale-presente/enviar-email?emailDestino=${email}`)

export const enviarXmlsPorEmail = (filtro: FiltroDeCapaDaNota, emailDestino: string, config?: AxiosRequestConfig): Promise<void> => {
	return request.post(`${basePath}/enviar-xmls-por-email?emailDestino=${emailDestino}`,
		filtro,		
		config,
	)
}