import "reflect-metadata"
import { injectable } from 'inversify-props'
import { LojaServiceAdapter } from '@/usecases'
import {
	get,
	find,
	create,
	update,
	patch,
	remove,
	changeLoja,
	buscarParametrosControlPayDaLoja,
	gravarParametrosControlPay,
	buscarSeriesDeDanfes,
	findLojasVinculadasComCliente,
	vincularClientesComLoja,
	desvincularLojaECliente,
	desvincularLoja,
	vincularLojas,
	findLojasVinculadasAsLojasDoUsuario,
	findConfigDeNotificacaoPorUsuario,
	salvarConfigDeNotificacaoPorUsuario,
	deletarConfigDeNotificacaoPorUsuario,
	findConfigDeNotificacaoPorEmail,
	salvarConfigDeNotificacaoPorEmail,
	deletarConfigDeNotificacaoPorEmail,
	buscarTodasAsLojasPorFiltro,
} from '@/api/lojas'
import { Deposito, FormLoja, Page, PdvComOperador, PdvsComValores, TurnoDetalhado, ParametrosControlPay, LojaDoPdv, Cliente, Loja, ConfiguracaoDeNotificacaoPorUsuario, ConfiguracaoDeNotificacaoPorEmail } from '@/models'
import request from "@/common/request"
import { AxiosRequestConfig } from "axios"

@injectable()
export class LojaService implements LojaServiceAdapter {
	get = async (id: string, params?: AxiosRequestConfig) => await get(id, params)
	find = async (params?: any, config?: AxiosRequestConfig) => await find(params, config)
	create = async (loja: FormLoja) => await create(loja)
	update = async (loja: FormLoja) => await update(loja)
	patch = async (loja: FormLoja) => await patch(loja)
	remove = async (id: string): Promise<void> => await remove(id)
	changeLoja = async (loja: Partial<FormLoja>, rota: string) => await changeLoja(loja, rota)
	vincularClientesComLoja = async (lojaId: string, clientes: Cliente[]) => await vincularClientesComLoja(lojaId, clientes)
	desvincularLojaECliente = async (lojaId: string, clienteId: string) => await desvincularLojaECliente(lojaId, clienteId)
	vincularLojas = async (loja: Partial<FormLoja>) => await vincularLojas(loja)
	desvincularLoja = async (lojaId: string, lojaDesvincular: string) => await desvincularLoja(lojaId, lojaDesvincular)

	getDepositoDaLoja(id: string, params?: AxiosRequestConfig): Promise<Page<Deposito>> {
		return request.get(`/lojas/${id}/depositos`, params)
	}
	getPdvsDaLoja(id: string, params?: AxiosRequestConfig): Promise<Page<PdvComOperador>> {
		return request.get(`/lojas/${id}/pdvs`, params)
	}

	getPdvsDaLojaComValores(id: string, somenteAbertos: boolean, params?: AxiosRequestConfig): Promise<Page<PdvsComValores>> {
		return request.get(`/turno-venda/valores-turno/${id}/${somenteAbertos}`, params)
	}
	getTurnoDetalhado(idTurno: string, params?: AxiosRequestConfig): Promise<Page<TurnoDetalhado>> {
		return request.get(`/turno-venda/turno-detalhado/${idTurno}`, params)
	}

	findLojaDaTabela(tabelaId: string, params?: any): Promise<Page<LojaDoPdv>> {
		return request.get(`/lojas/${tabelaId}/tabela`, { params })
	}

	buscarParametrosControlPay = async (lojaId: string) => await buscarParametrosControlPayDaLoja(lojaId)

	gravarParametrosControlPay = async (lojaId: string, parametrosControlPay: ParametrosControlPay) => await gravarParametrosControlPay(lojaId, parametrosControlPay)

	buscarSeriesDeDanfes = async (lojaId: string) => await buscarSeriesDeDanfes(lojaId)

	findLojasVinculadasComCliente = async (params?: any, config?: AxiosRequestConfig) => await findLojasVinculadasComCliente(params, config)

	findLojasVinculadasByLojaId(params: string, config?: AxiosRequestConfig): Promise<Page<Loja>> {
		return request.get(`/lojas/lojas-vinculadas`, { params, ...config })
	}

	findLojasVinculadasAsLojasDoUsuario = async () => await findLojasVinculadasAsLojasDoUsuario()

	findConfigDeNotificacaoPorUsuario = async (lojaId: string) => await findConfigDeNotificacaoPorUsuario(lojaId)

	salvarConfigDeNotificacaoPorUsuario = async (lojaId: string, configuracaoDeNotificacao: ConfiguracaoDeNotificacaoPorUsuario) => await salvarConfigDeNotificacaoPorUsuario(lojaId, configuracaoDeNotificacao)

	deletarConfigDeNotificacaoPorUsuario = async (configId: string) => await deletarConfigDeNotificacaoPorUsuario(configId)

	findConfigDeNotificacaoPorEmail = async (lojaId: string) => await findConfigDeNotificacaoPorEmail(lojaId)

	salvarConfigDeNotificacaoPorEmail = async (lojaId: string, configuracaoDeNotificacao: ConfiguracaoDeNotificacaoPorEmail) => await salvarConfigDeNotificacaoPorEmail(lojaId, configuracaoDeNotificacao)

	deletarConfigDeNotificacaoPorEmail = async (configId: string) => await deletarConfigDeNotificacaoPorEmail(configId)

	buscarTodasAsLojasPorFiltro = async (params?: any, config?: AxiosRequestConfig) => await buscarTodasAsLojasPorFiltro(params, config)

	findLojasByGrupoEconomicoId(grupoEconomicoId: string, config?: AxiosRequestConfig): Promise<Page<Loja>> {
		return request.get(`/lojas/grupo-economico/${grupoEconomicoId}`, {
			...config,
		})
	}
}