import { Inject } from 'inversify-props'
import { Titulo, TituloComParcelamento } from '@/models'
import type { TituloServiceAdapter } from '../adapter/TituloServiceAdapter';

export class SaveContasAReceberUseCase {
	@Inject('TituloServiceAdapter')
	private tituloServiceAdapter!: TituloServiceAdapter

	async execute(params: TituloComParcelamento): Promise<Titulo> {
		return !params.titulo.id
			? this.tituloServiceAdapter.create(params)
			: this.tituloServiceAdapter.update(params.titulo)
	}
}
