import { Versao } from '@/models'
import axios from 'axios'

export const get = async (): Promise<Versao> => {
	const { data } = await axios.get('/verifica-versao.json', {
		timeout: 5000,
		headers: {
			'cache-control': 'no-cache',
		},
		params: {
			timestamp: Date.now(),
		},
	})
	return data
}
