import { TipoDeRecurso } from '@/models/Recurso';
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotas: RouteConfig[] = [
	{
		path: 'painel-de-caixas',
		name: 'Painel de Caixa',
		components: {
			default: () =>
				import('@/views/application/fechamentosdecaixa/TelaDeSangria.vue'),
			toolbar: () =>
				import('@/views/application/fechamentosdecaixa/ToolbarDeSangria.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'sangria' as TipoDeRecurso,
		},
	},
]
export default rotas