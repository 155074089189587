import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotasDeConfiguracoesfiscais: RouteConfig[] = [
	{
		path: 'impostos',
		name: 'Configurações Fiscais',
		components: {
			default: () =>
				import('@/views/application/configuracoes/fiscal/TelaDeFiscal.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
	},
]
export default rotasDeConfiguracoesfiscais