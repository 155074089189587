import { findItensDaPreVenda, importarItens, remover, salvarOuAtualizarItemPreVenda } from "@/api/itemPreVenda";
import { ItemDaPreVenda, ItemDaPreVendaImportacao } from "@/models/pre-venda/PreVenda";
import { ItemDaPreVendaServiceAdapter } from "@/usecases";
import { AxiosRequestConfig } from "axios";
import { injectable } from "inversify";

@injectable()
export class ItemDaPreVendaService implements ItemDaPreVendaServiceAdapter {
	importarItens = async (preVendaId: string, itens: ItemDaPreVendaImportacao[]) => await importarItens(preVendaId, itens)
	findItensDaPreVenda = async (params: any, config: AxiosRequestConfig) => await findItensDaPreVenda(params, config)
	remover = async (idItemPreVenda: string) => await remover(idItemPreVenda) 
	salvarOuAtualizarItemPreVenda = async (item: ItemDaPreVenda, preVendaId: string) => await salvarOuAtualizarItemPreVenda(item, preVendaId)
}