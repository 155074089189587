import { Page, ModalidadeDeVenda, TipoDeOrigemDeVenda } from "@/models"
import { AxiosRequestConfig } from "axios"
import { Inject } from "inversify-props"
import type { ModalidadeDeVendaServiceAdapter } from "./adapter"

export class FindModalidadeDeVendaUseCase {
	@Inject('ModalidadeDeVendaServiceAdapter')
	private ModalidadeDeVendaAdapter!: ModalidadeDeVendaServiceAdapter

	findAll = async (params?: any, config?: AxiosRequestConfig): Promise<Page<ModalidadeDeVenda>> =>
		this.ModalidadeDeVendaAdapter.findAll(params, config)

	find = async (idLoja?: string, config?: AxiosRequestConfig): Promise<Array<ModalidadeDeVenda>> =>
		this.ModalidadeDeVendaAdapter.find(idLoja, config)

	findSequenciaisValidos = async (idLoja?: string): Promise<Array<number>> =>
		this.ModalidadeDeVendaAdapter.findSequenciaisValidos(idLoja)

	findModalidadePadrao = async (idLoja: string, params: TipoDeOrigemDeVenda): Promise<ModalidadeDeVenda> =>
		this.ModalidadeDeVendaAdapter.findModalidadePadrao(idLoja, params)

	findModalidadesDaOrigem = async (idLoja: string, params: TipoDeOrigemDeVenda): Promise<Array<ModalidadeDeVenda>> =>
		this.ModalidadeDeVendaAdapter.findModalidadesDaOrigem(idLoja, params)
}