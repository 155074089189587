import { ItemDaTabelaDePreco } from '@/models';
import {Inject} from "inversify-props";
import type { TabelaDePrecoServiceAdapter } from './adapter/TabelaDePrecoServiceAdapter';

export class AddItemUseCase {
	@Inject('TabelaDePrecoServiceAdapter')
	private tabelaService!: TabelaDePrecoServiceAdapter

	async execute(idTabela: string, item: ItemDaTabelaDePreco): Promise<ItemDaTabelaDePreco> {
		return this.tabelaService.adicionaItem(idTabela, item)
	}
}