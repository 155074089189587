import {Inject} from "inversify-props";
import type { TabelaDePrecoServiceAdapter } from './adapter/TabelaDePrecoServiceAdapter';

export class ImportaItensUseCase {
	@Inject('TabelaDePrecoServiceAdapter')
	private tabelaService!: TabelaDePrecoServiceAdapter

	async execute(idTabela: string, arquivoContemCabecalho: boolean, urlDoArquivo: string): Promise<void> {
		return this.tabelaService.importaItens(idTabela, arquivoContemCabecalho, urlDoArquivo)
	}
}