import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import store from '@/store/vuex'
import { Notificacao } from "@/models/usuario/Notificacao"
import NotificacaoService from '@/services/usuario/NotificacaoService'
import UserLoginStore from "../authentication/UserLoginStore"

@Module({ name: 'NotificacaoStore', store, dynamic: true, namespaced: true })
class NotificacaoStore extends VuexModule {
	notificacoes: Notificacao[] = []

	@Mutation
	setNotificacoes(notificacoes: Notificacao[]) {
		this.notificacoes = notificacoes
	}

	@Action
	async atualizarNotificacoes() {
		const idUsuario = UserLoginStore.usuario?.id
		if (!idUsuario) return
		const notificacoes = await NotificacaoService.listar({
			idDestinatario: idUsuario,
			size: 20,
			sort: 'createdAt,desc',
		})
		this.setNotificacoes(notificacoes.content)
	}
}

export default getModule(NotificacaoStore)
