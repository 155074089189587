import { Inject } from 'inversify-props'
import type { RegraPorEstadoServiceAdapter } from '@/usecases'
import { RegraPorEstado } from '@/models'

export class SaveRegraPorEstadoUseCase {
	@Inject('RegraPorEstadoServiceAdapter')
	private regraService!: RegraPorEstadoServiceAdapter

	save = async (idRegraImpoto: string, idRegraAplicacao: string, regrasPorEstado: RegraPorEstado[]): Promise<RegraPorEstado[]> =>
		await this.regraService.salvarTodos(idRegraImpoto, idRegraAplicacao, regrasPorEstado)

	copiar = async (idRegraOrigem: string, idRegraDestino: string): Promise<RegraPorEstado> =>
		await this.regraService.copiarRegra(idRegraOrigem,idRegraDestino)
}
