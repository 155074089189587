import { injectable } from 'inversify-props'
import { ConfiguracaoDeCupom } from '@/models'
import {
	create,
	get,
	findByCodigo,
	find,
	update,
	deleteById,
	criarCupons,
} from '@/api/cupons'
import { ConfiguracaoDeCupomServiceAdapter } from '@/usecases/cupom'
import { AxiosRequestConfig } from 'axios'

@injectable()
export class ConfiguracaoDeCupomService implements ConfiguracaoDeCupomServiceAdapter {
	create = async (configuracaoDeCupom: ConfiguracaoDeCupom) => await create(configuracaoDeCupom)
	get = async (id: string) => await get(id)
	find = async (params?: any, config?: AxiosRequestConfig) => await find(params, config)
	update = async (cupom: ConfiguracaoDeCupom) => await update(cupom)
	delete = async (id: string) => await deleteById(id)
	findByCodigo = async (codigo: string, params?: any, config?: AxiosRequestConfig) => await findByCodigo(codigo, params, config)
	criarCupons = async (cupom: ConfiguracaoDeCupom) => await criarCupons(cupom)
}
