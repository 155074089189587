import "reflect-metadata"
import { injectable } from 'inversify-props';
import { ServiceICMSAdapter } from '@/usecases/impostos/adapter/ServiceICMSAdapter';
import { getICMS, findICMS } from '@/api/impostos';


@injectable()
export class ServiceICMS implements ServiceICMSAdapter {
	get = async (id: string) => await getICMS(id)
	find = async(params: any) => await findICMS(params)
}