import { Usuario, Perfil, Page } from '@/models'
import { Inject } from 'inversify-props'
import type { UsuarioServiceAdapter } from '@/usecases'
import createSearchTags from '@/shareds/createSearchTags'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FindPerfilUseCase } from '@/usecases'
import { AxiosRequestConfig } from 'axios'

const ERRO_NA_REQUISICAO = 'Não foi possível buscar os usuários'

export class FindUsuarioUseCase {
	@Inject('UsuarioServiceAdapter')
	private usuarioService!: UsuarioServiceAdapter
	private findPerfilUseCase = new FindPerfilUseCase()

	findById = async (id: string) => await this.usuarioService.get(id)

	async findAllByLoja(
		loja: string,
		page: number,
		size: number,
	): Promise<Usuario[]> {
		try {
			const resposta = await this.usuarioService.find({ loja, page, size })
			const users = resposta.content
			const pagePerfis = await this.findPerfilUseCase.findAll({})
			return users.map(usuario => ({
				...usuario,
				tags: createSearchTags(
					usuario.nome,
					this.buscaNomePerfil(pagePerfis.content, usuario.perfil.id),
				),
			}))
		} catch (e) {
			AlertModule.setError(e)
		}
		return Promise.reject(ERRO_NA_REQUISICAO);
	}

	private buscaNomePerfil(perfis: Perfil[], id: string) {
		const perfil = perfis.find(perfil => perfil.id === id)
		return perfil?.nome || ''
	}

	usuarioLogado = async (token?: string) => await this.usuarioService.getUsuarioLogado(token)

	findAll = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Usuario>> => {
		const pageUsers = await this.usuarioService.find(params, config)
		const pagePerfis = await this.findPerfilUseCase.findAll({}, config)
		pageUsers.content.map(usuario => {
			
			usuario.tags = createSearchTags(
				usuario.nome,
				this.buscaNomePerfil(pagePerfis.content, usuario.perfil.id),
			)

			return usuario
		})

		return pageUsers
	}

	findAllVendedores = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Usuario>> => {
		const pageUsers = await this.usuarioService.findAllVendedores(params, config)
		const pagePerfis = await this.findPerfilUseCase.findAll({}, config)
		pageUsers.content.map(usuario => {
			
			usuario.tags = createSearchTags(
				usuario.nome,
				this.buscaNomePerfil(pagePerfis.content, usuario.perfil.id),
			)

			return usuario
		})

		return pageUsers
	}
	
	findByPerfil = async (perfil: string) => {		

		const resposta = await this.usuarioService.find({ 
			perfis: perfil,
		})
		const users = resposta.content

		return users;
			
	}

	
	findByVendedor = async (vendedor: boolean, usuarioAtivo: boolean) => {		

		const resposta = await this.usuarioService.find({ 
			somenteVendedor: vendedor,
			somenteUsuariosAtivos: usuarioAtivo,
		})
		const users = resposta.content

		return users;
			
	}

	listarVendedorAtivo = async (vendedor: boolean, usuarioAtivo: boolean) => {
		const resposta = await this.usuarioService.findNew({
			somenteVendedor: vendedor,
			somenteUsuariosAtivos: usuarioAtivo,
		})
		const users = resposta.content
		return users
	}

	existeSomenteUmaLojaVinculadaComUsuario = async (usuarioId: string) => await this.usuarioService.existeSomenteUmaLojaVinculadaComUsuario(usuarioId)
}
