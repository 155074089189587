import { Inject } from 'inversify-props'
import type { TurnoDeVendaServiceAdapter } from './adapter'
import { TurnoDeVenda, Page, FechamentoDeCaixa, MovimentacaoDeCaixa } from '@/models'

export class FindTurnoDeVendaUseCase {
	@Inject('TurnoDeVendaServiceAdapter')
	private turnoDeVendaServiceAdapter!: TurnoDeVendaServiceAdapter

	find = async (params?: any): Promise<Page<TurnoDeVenda>> =>
		await this.turnoDeVendaServiceAdapter.find(params)
	
	get = async (id: string): Promise<TurnoDeVenda> =>
		await this.turnoDeVendaServiceAdapter.get(id)

	getFechamentoEsperado = async (idDoTurnoDeVenda: string): Promise<FechamentoDeCaixa> =>
		await this.turnoDeVendaServiceAdapter.getFechamentoEsperado(idDoTurnoDeVenda)

	getMovimentacoesCaixaByTurnoId = async (idDoTurnoDeVenda: string): Promise<MovimentacaoDeCaixa[]> =>
		await this.turnoDeVendaServiceAdapter.getMovimentacoesCaixaByTurnoId(idDoTurnoDeVenda)

}