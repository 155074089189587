import store from '@/store/vuex'
import { TipoDeRegraDePermissao } from './../../../models/RegraDeNegocio'
import {  Perfil, PerfilOld, Permissao, TokenJwt, Usuario } from '@/models'
import type { Credenciais } from '@/models'
import {
	Module,
	Mutation,
	VuexModule,
	getModule,
	Action,
} from 'vuex-module-decorators'
import {
	JWT_TOKEN,
	PERFIL_LOGADO,
	USUARIO_LOGADO,
} from '@/common/constants'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { navigationSubdomain } from '@/shareds/utils'
import { FindPerfilUseCase, FindUsuarioUseCase, LoginUseCase } from '@/usecases'
import PluginModule from '@/store/vuex/aplicacao/PluginStore'
import AppStore from '@/store/vuex/aplicacao/AppStore'
import { TipoDeOperacao, TipoDeRecurso } from '@/models/Recurso'
import { montarOperacaoDeRecurso, perfilTemAutorizacao, perfilTemRegraDeNegocio } from '@/shareds/permissoes-shareds'

const perfilAnterior: null | Perfil | PerfilOld = localStorage[PERFIL_LOGADO]
	? JSON.parse(localStorage[PERFIL_LOGADO])
	: null

const perfilMigrado: null | Perfil = perfilAnterior
	? {
		...perfilAnterior,
		permissoes: (perfilAnterior.permissoes as (string | Permissao)[])
			.filter(permissao => !!permissao)
			.map((permissao: string | Permissao) => typeof permissao === 'string'
				? permissao
				: permissao.nome,
			),
		percentualMaximoDesconto: 0,
		tempoParaLogoff: perfilAnterior.tempoParaLogoff,
		percentualMaximoDescontoPorItem: 0,
		tempoLimiteDeInatividade: perfilAnterior.tempoLimiteDeInatividade,
	}
	: perfilAnterior

	
const MOSTRAR_CONTAGEM_REGRESSIVA = 'MOSTRAR_CONTAGEM_REGRESSIVA'
const TEMPO_LIMITE_DE_INATIVIDADE = 'TEMPO_LIMITE_DE_INATIVIDADE'
const AUTENTICANDO = 'AUTENTICANDO'
const AUTENTICANDO_PARA_LIBERAR_VENDA_SEM_ESTOQUE = 'AUTENTICANDO_PARA_LIBERAR_VENDA_SEM_ESTOQUE'

@Module({
	name: 'UserLoginStore',
	namespaced: true,
	dynamic: true,
	store,
})
class UserLoginStore extends VuexModule {
	loginStatus = {
		path: '/login',
		name: 'Entrar',
	}

	usuario: null | Usuario = localStorage[USUARIO_LOGADO]
		? JSON.parse(localStorage[USUARIO_LOGADO])
		: null

	perfil: null | Perfil = perfilMigrado

	token: null | string = localStorage[JWT_TOKEN]
		? localStorage[JWT_TOKEN]
		: null

	tempoLimiteDeInatividade: string = localStorage[TEMPO_LIMITE_DE_INATIVIDADE]
		? JSON.stringify(localStorage[TEMPO_LIMITE_DE_INATIVIDADE])
		: '00:00:00'

	autenticando: boolean = localStorage[AUTENTICANDO]
		? JSON.parse(localStorage[AUTENTICANDO])
		: false

	mostrarContagemRegressiva: boolean = localStorage[MOSTRAR_CONTAGEM_REGRESSIVA]
		? JSON.parse(localStorage[MOSTRAR_CONTAGEM_REGRESSIVA])
		: false

	autenticandoParaLiberarVendaSemEstoque: boolean = localStorage[AUTENTICANDO_PARA_LIBERAR_VENDA_SEM_ESTOQUE]
		? JSON.parse(localStorage[AUTENTICANDO_PARA_LIBERAR_VENDA_SEM_ESTOQUE])
		: false

		
	@Mutation
	public setAutenticandoParaLiberarVendaSemEstoque(autenticando: boolean | false) {
		localStorage[AUTENTICANDO_PARA_LIBERAR_VENDA_SEM_ESTOQUE] = autenticando
		this.autenticandoParaLiberarVendaSemEstoque = autenticando
	}

	@Mutation
	public removeAutenticandoParaLiberarVendaSemEstoque(): void {
		this.autenticandoParaLiberarVendaSemEstoque = false
		localStorage.removeItem(AUTENTICANDO_PARA_LIBERAR_VENDA_SEM_ESTOQUE)
	}

	
	@Mutation
	public setAutenticando(autenticando: boolean | false) {
		localStorage[AUTENTICANDO] = autenticando
		this.autenticando = autenticando
	}

	@Mutation
	public removeAutenticando(): void {
		this.autenticando = false
		localStorage.removeItem(AUTENTICANDO)
	}

	@Mutation
	public setTempoLimiteDeInatividade(tempo: string): void {
		localStorage[TEMPO_LIMITE_DE_INATIVIDADE] = tempo
		this.tempoLimiteDeInatividade = tempo
	}

	@Mutation
	public removeTempoLimiteDeInativdade(): void {
		this.tempoLimiteDeInatividade = ''
		localStorage.removeItem(TEMPO_LIMITE_DE_INATIVIDADE)
	}

	@Mutation
	public setMostrarContagemRegressiva(mostrar: boolean | false): void {
		localStorage[MOSTRAR_CONTAGEM_REGRESSIVA] = mostrar
		this.mostrarContagemRegressiva = mostrar 
	}

	@Mutation
	public removeMostrarContagemRegressiva(): void {
		this.mostrarContagemRegressiva = false
		localStorage.removeItem(MOSTRAR_CONTAGEM_REGRESSIVA)
	}

	@Mutation
	setPerfil(perfil: Perfil | null) {
		perfil
			? localStorage.setItem(PERFIL_LOGADO, JSON.stringify(perfil))
			: localStorage.removeItem(PERFIL_LOGADO)
		this.perfil = perfil
	}

	@Mutation
	setUsuario(usuario: Usuario | null) {
		usuario
			? localStorage.setItem(USUARIO_LOGADO, JSON.stringify(usuario))
			: localStorage.removeItem(USUARIO_LOGADO)
		this.usuario = usuario
	}

	@Mutation
	public removeUsuario(): void {
		this.usuario = null
		localStorage.removeItem(USUARIO_LOGADO)
	}

	@Mutation
	public removeToken(): void {
		this.token = null
		localStorage.removeItem(JWT_TOKEN)
	}

	@Mutation
	public setToken(token: string): void {
		this.token = token
		localStorage.setItem(JWT_TOKEN, token)
	}

	@Mutation
	public clearToken(): void {
		this.token = null
	}

	get isLoggedIn(): boolean {
		return !!this.token
	}

	@Mutation
	setLoginStatus(): void {
		const isLoggedIn: boolean = this.isLoggedIn
		if (isLoggedIn) {
			this.loginStatus = { path: '/logout', name: 'Logout' }
		} else {
			this.loginStatus = { path: '/login', name: 'Login' }
		}
	}

	@Action
	public async logout() {
		if (VendaModule.turnoDeVenda) {
			return VendaModule.mostrarFechamento()
		}
		AppStore.pararSincronizacao()
		localStorage.clear()
		location.reload()
	}

	@Action({ rawError: true })
	async login(credenciais: Credenciais) {
		const token: TokenJwt = await new LoginUseCase().signIn(credenciais)
		const subdominio = token.subdominio.split('.')[0]
		if (!token.token) {
			throw new Error('Não foi possível autenticar o token')
		}
		if (subdominio !== navigationSubdomain && !navigationSubdomain.startsWith("localhost")) {
			throw new Error('URL diferente do usuário')
		}
		
		this.setMostrarContagemRegressiva(false)
		
		this.setTempoLimiteDeInatividade("00:00:00")

		this.setToken(token.token)

		await AppStore.inicializaSincronizacao()

		PluginModule.inicializarPlugins()
	}

	@Action({ rawError: true })
	async loginComToken(token: string) {
		this.setToken(token)

		await AppStore.inicializaSincronizacao()

		PluginModule.inicializarPlugins()
	}

	@Action({ rawError: true })
	async atualizarUsuario() {
		const findUsuarioUseCase = new FindUsuarioUseCase()
		const findPerfilUseCase = new FindPerfilUseCase()
		const usuario = await findUsuarioUseCase.usuarioLogado()
		this.setUsuario(usuario)
		const perfil = await findPerfilUseCase.findById(usuario.perfil.id)
		this.setPerfil(perfil)
	}

	get temAutorizacao() {
		return perfilTemAutorizacao(this.perfil)
	}

	get temAutorizacaoPorOperacaoERecurso() {
		return (operacao: TipoDeOperacao, recurso: TipoDeRecurso) =>
			this.temAutorizacao(montarOperacaoDeRecurso(operacao, recurso))
	}

	get permiteRegraDeNegocio() {
		return (regra: TipoDeRegraDePermissao) => perfilTemRegraDeNegocio(this.perfil, regra)
	}

	get tempoParaLogoff(): string {
		if(!this.perfil) return '00:00:00'
		return this.perfil.tempoParaLogoff || '00:00:00'
	}

	get tempoLimite(): string {
		return this.perfil?.tempoLimiteDeInatividade || '00:00:00'
	}

	get mostrarContagem(): boolean {
		return this.mostrarContagemRegressiva || false
	}
}

export default getModule(UserLoginStore)
