import { Inject } from 'inversify-props'
import type { LojaServiceAdapter } from './adapter/LojaServiceAdapter'
import { Cliente, ConfiguracaoDeNotificacaoPorEmail, ConfiguracaoDeNotificacaoPorUsuario, FormLoja, Loja, ParametrosControlPay } from '@/models'

const ERRO_NA_REQUISICAO = 'Erro ao salvar a loja'

export class SaveLojaUseCase {
	@Inject('LojaServiceAdapter')
	private lojaService!: LojaServiceAdapter

	async save(loja: FormLoja): Promise<Loja> {
		if (loja.id) {
			const lojaResponse = await this.lojaService.update(loja)
			if (lojaResponse) {
				return lojaResponse
			}
			throw new Error(lojaResponse)
		} else {
			const lojaResponse = await this.lojaService.create(loja)
			if (lojaResponse) {
				return lojaResponse
			}
			throw new Error(lojaResponse)
		}
	}

	async changeLoja(loja: Partial<FormLoja>, rota: string): Promise<Loja> {
		try {
			const lojaResponse = await this.lojaService.changeLoja(loja, rota)
			if (lojaResponse) return lojaResponse
			throw new Error(lojaResponse)
		} catch (error: any) {
			throw new Error(`${ERRO_NA_REQUISICAO} ${error.message}`)
		}
	}

	async gravarParametrosControlPay(lojaId: string, gravarParametrosControlPay: ParametrosControlPay): Promise<ParametrosControlPay> {
		return this.lojaService.gravarParametrosControlPay(lojaId, gravarParametrosControlPay)
	}

	async vincularClientesComLoja(lojaId: string, clientes: Cliente[]): Promise<void> {
		return this.lojaService.vincularClientesComLoja(lojaId, clientes)
	}


	async desvincularLojaECliente(lojaId: string, clienteId: string): Promise<void> {
		return this.lojaService.desvincularLojaECliente(lojaId, clienteId)
	}

	async vincularLojas(loja: Partial<FormLoja>): Promise<void> {
		return this.lojaService.vincularLojas(loja)
	}

	async desvincularLoja(lojaId: string, lojaDesvincular: string): Promise<void> {
		return this.lojaService.desvincularLoja(lojaId, lojaDesvincular)
	}

	async salvarConfigDeNotificacaoPorUsuario(lojaId: string, configuracaoDeNotificacao: ConfiguracaoDeNotificacaoPorUsuario): Promise<void> {
		return this.lojaService.salvarConfigDeNotificacaoPorUsuario(lojaId, configuracaoDeNotificacao)
	}

	async salvarConfigDeNotificacaoPorEmail(lojaId: string, configuracaoDeNotificacao: ConfiguracaoDeNotificacaoPorEmail): Promise<void> {
		return this.lojaService.salvarConfigDeNotificacaoPorEmail(lojaId, configuracaoDeNotificacao)
	}
}
