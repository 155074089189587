import { TipoDeRecurso } from '@/models/Recurso';
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotasDePerfis: RouteConfig [] = [
	{
		path: 'perfis/novo',
		name: 'Criação de Perfil',
		components: {
			default: () =>
				import('@/views/application/perfis/EdicaoDePerfil.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			default: { novo: true },
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'perfis' as TipoDeRecurso,
		},
	},
	{
		path: 'perfis/:id',
		name: 'Edição de Perfil',
		components: {
			default: () =>
				import('@/views/application/perfis/EdicaoDePerfil.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			default: true,
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'perfis' as TipoDeRecurso,
		},
	},
	{
		path: 'perfis/:id/novo',
		name: 'Copia de Perfil',
		components: {
			default: () =>
				import('@/views/application/perfis/CopiaDePerfis.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			default: true,
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'perfis' as TipoDeRecurso,
		},
	},
	{
		path: 'perfis',
		name: 'Perfis',
		components: {
			default: () =>
				import('@/views/application/perfis/TelaDePerfis.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'perfis' as TipoDeRecurso,
		},
	},
]

export default rotasDePerfis