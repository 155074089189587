import { Faq } from "@/models";
import { Inject } from "inversify-props";
import type { FaqServiceAdapter } from "./adapter";


const ERRO_NA_REQUISICAO = "Erro ao salvar Faq"

export class SaveFaqUseCase {
	@Inject("FaqServiceAdapter")
	private faqService!: FaqServiceAdapter

	async update(faq: Faq): Promise<Faq> {
		try{
			if (faq.id) {
				const faqResponse = await this.faqService.update(faq)
				if(faqResponse) {
					return faqResponse
				}
				throw new Error(faqResponse)
			} else {
				const faqResponse = await this.faqService.create(faq)
				if (faqResponse) {
					return faqResponse
				}
				throw new Error(faqResponse)
			}
		} catch(e: any) {
			
			throw new Error(`${ERRO_NA_REQUISICAO}: ${e.response.data[0]}`)
		}
	}
}