import 'reflect-metadata'
import { injectable } from 'inversify-props'
import { RegraDeImpostoServiceAdapter } from '@/usecases'
import {
	findRegraDeImposto,
	getRegraDeImposto,
	createRegraDeImposto,
	atualizaRegraImposto,
	deletaRegraDeImposto,
	deletaItemDaRegraDeImposto,
	getItens,
	adicionarRegraDeAplicacao,
} from '@/api/impostos'
import { CapaRegraImpostoInput, Page, RegraDeAplicacao, RegraDeEstado } from '@/models'
import { AxiosRequestConfig } from 'axios'

@injectable()
export class RegraDeImpostoService implements RegraDeImpostoServiceAdapter {
	find = async (params?: any, config?: AxiosRequestConfig) =>
		await findRegraDeImposto(params, config)

	get = async (id: string) => await getRegraDeImposto(id)

	create = async (regra: CapaRegraImpostoInput) => await createRegraDeImposto(regra)

	update = async (regra: CapaRegraImpostoInput) => await atualizaRegraImposto(regra)

	delete = async (id: string) => await deletaRegraDeImposto(id)

	deleteItem = async (idItem: string) =>
		await deletaItemDaRegraDeImposto(idItem)

	getItens(regraId: string, params?: any, config?: AxiosRequestConfig | undefined): Promise<Page<RegraDeEstado>> {
		return getItens(regraId, params, config)
	}

	addItem = async (idRegra: string, item: RegraDeAplicacao) =>
		await adicionarRegraDeAplicacao(idRegra, item)
}
