import { TipoDeRecurso } from '@/models/Recurso';
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotasDeVendas: RouteConfig[] = [
	{
		path: 'caixa',
		name: 'Caixa',
		components: {
			default: () =>
				import('@/views/application/venda/TelaDeCaixa.vue'),
			toolbar: () =>
				import('@/views/application/venda/ToolbarDeVenda.vue'),
		},
		meta: {
			recurso: 'vendas' as TipoDeRecurso,
			operacao: 'criar',
		},
	},
	{
		path: 'vendas/novo',
		redirect: { name: 'Caixa' },
		name: 'Venda',
	},
	{
		path: 'vendas',
		name: 'Lista de Vendas',
		components: {
			default: () =>
				import('@/views/application/venda/TelaDeVendas.vue'),
			toolbar: () =>
				import('@/views/application/venda/ToolbarDeVendas.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'vendas' as TipoDeRecurso,
		},
	},
	{
		path: 'vendas/bandeiras',
		name: 'Bandeira De Cartao',
		components: {
			default: () =>
				import('@/views/application/venda/TelaDeCadastroDeBandeiras.vue'),
			toolbar: () =>
				import('@/views/application/venda/ToolbarDeVendas.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'vendas' as TipoDeRecurso,
		},
	},
	{
		path: 'vendas/adquirente',
		name: 'Adquirente',
		components: {
			default: () =>
				import('@/views/application/venda/TelaDeCadastroDeAdquirente.vue'),
			toolbar: () =>
				import('@/views/application/venda/ToolbarDeVendas.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'vendas' as TipoDeRecurso,
		},
	},
	{

		path: 'vendas/modalidades-de-venda',
		name: 'Modalidades De Venda',
		components: {
			default: () =>
				import('@/views/application/venda/TelaDeModalidadesDeVenda.vue'),
			toolbar: () =>
				import('@/views/application/venda/ToolbarDeVendas.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'vendas' as TipoDeRecurso,
		},
	},
]
export default rotasDeVendas
