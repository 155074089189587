import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators'
import store from '@/store/vuex'

const REIMPRESSOES = 'REIMPRESSOES'

@Module({
	name: 'ReimpressaoStore',
	namespaced: true,
	dynamic: true,
	store,
})
export default class ReimpressaoStore extends VuexModule {
	reimpressoes = carregarReimpressoesLocal()

	@Action
	addReimpressao({ titulo, docDefinition, dataHora = Date.now() }){
		this.setReimpressao({ titulo, dataHora, docDefinition })
	}

	@Mutation
	setReimpressao(reimpressao) {
		this.reimpressoes.unshift(reimpressao)
		if (this.reimpressoes.length >= 60) {
			this.reimpressoes.pop()
		}
		gravarReimpressoesLocal(this.reimpressoes)
	}

	@Mutation
	clearReimpressoes() {
		this.reimpressoes = []
		localStorage.setItem(REIMPRESSOES, JSON.stringify(this.reimpressoes))
	}
}

function carregarReimpressoesLocal(): Array<any> {
	const jsonImpressoes = localStorage.getItem(REIMPRESSOES)
	if (jsonImpressoes) {
		return JSON.parse(jsonImpressoes) as Array<any>
	} else {
		return []
	}
}

function gravarReimpressoesLocal(reimpressoes) {
	localStorage.setItem(REIMPRESSOES, JSON.stringify(reimpressoes))
}

export const ReimpressaoModule = getModule(ReimpressaoStore)
