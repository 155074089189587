import { Module, VuexModule, getModule, Mutation, Action } from "vuex-module-decorators";
import store from '@/store/vuex'

import { FindVendaUseCase } from "@/usecases";

@Module({ name: 'PedidoStore', store, dynamic: true, namespaced: true })
class PedidoStore extends VuexModule {
	pedidos: string | null = null
	findVendaUseCase =  new FindVendaUseCase
	@Mutation 
	setPedidos(pedidos: string) {
		this.pedidos = pedidos
	}

	@Action
	async atualizarPedidos() {
		const pedidos = await this.findVendaUseCase.findNumeroDePedidos({
			situacoesDoPedido: 'Pendente',
			vendasComPedido: true,
		})
		this.setPedidos(pedidos)
	}
}

export default getModule(PedidoStore)