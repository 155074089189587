import { remove as removeDiacritics } from 'diacritics'

/**
 * Cria tags de busca
 * Separa uma lista de texto por espaço e normaliza (remove acentuação...)
 * 
 * @param texts textos a serem separados e normalizados
 */
export default function createSearchTags(...texts: (number | string)[]): string[] {
	const tags = texts
		.flatMap((text) => removeDiacritics(String(text)).toUpperCase().split(' '))
		.filter((text) => !!text)
	return [...new Set(tags)]
}
