import { TipoDeRecurso } from '@/models/Recurso';
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotasDeDashboards: RouteConfig[] = [
	{
		path: 'dashboard',
		name: 'Dashboard',
		components: {
			default: () =>
				import('@/views/application/dashboards/TelaInicialDashBoard.vue'),
			toolbar: () =>
				import('@/views/application/dashboards/ToolbarDoDashboardInicial.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'dashboards' as TipoDeRecurso,
		},
	},
	{
		path: 'dashboards',
		redirect: 'Dashboard',
		name: 'Dashboards',
	},
]

export default rotasDeDashboards