import { TipoDeRecurso } from "@/models/Recurso"
import { tituloDaToolbarPeloNomeDaRota } from "@/shareds/router-shareds"
import { RouteConfig } from "vue-router"

const rotasDePreVenda: RouteConfig [] = [
	{
		path: 'pre-vendas',
		name: 'Lista de pré-vendas',
		components: {
			default: () =>
				import('@/views/application/venda/TelaDePreVendas.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'pre-vendas' as TipoDeRecurso,
		},
	},
]
export default rotasDePreVenda