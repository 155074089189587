import 'reflect-metadata'
import { injectable } from 'inversify-props'
import { AtributoServiceAdapter } from '@/usecases'
import { getAtributo, findAtributo, createAtributo, updateAtributo } from '@/api/produtos'
import { Atributo } from '@/models'
import { AxiosRequestConfig } from 'axios'

@injectable()
export class AtributoService implements AtributoServiceAdapter {
	get = async (id: string) => await getAtributo(id)
	find = async (params?: any, config?: AxiosRequestConfig) => await findAtributo(params, config)
	create = async (atributo: Atributo) => await createAtributo(atributo)
	update = async (atributo: Atributo) => await updateAtributo(atributo)
}
