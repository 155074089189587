import { ItemDaComanda, ItemDaTabelaDePreco, Loja } from "@/models"
import { displayNomeCompletoDoProduto } from "@/shareds/produto-shareds"
import moment from "moment"
import { FindItemDaTabelaDePrecoUseCase } from "./FindItemDaTabelaDePrecoUseCase"

type UseCaseParams = {
	loja: Loja
	item: ItemDaComanda
	findItemTabelaDePrecoUseCase?: FindItemDaTabelaDePrecoUseCase
}

export default async ({
	loja,
	item,
	findItemTabelaDePrecoUseCase = new FindItemDaTabelaDePrecoUseCase(),
}: UseCaseParams): Promise<ItemDaComanda> => {

	// ? Procura tabela sem cliente
	const tabelaPadrao = loja.configuracaoDaLoja.tabelasDePreco
		.find(tabela => !tabela.tipoDeCliente && !tabela.tabelaDePreco.isTabelaDePromocao)?.tabelaDePreco
	const tabelasDePromocao =  loja.configuracaoDaLoja.tabelasDePreco
		.filter(({ tabelaDePreco }) => !tabelaDePreco.tipoDeCliente && tabelaDePreco.isTabelaDePromocao)
		.filter(({ tabelaDePreco }) => {
			return moment().isSameOrAfter(moment(`${tabelaDePreco.inicioVigencia} 00:00:00`, 'YYYY-MM-DD HH:mm:ss'))
						&& moment().isSameOrBefore(moment(`${tabelaDePreco.fimVigencia} 23:59:59`, 'YYYY-MM-DD HH:mm:ss'))
		})
		.map(({ tabelaDePreco }) => tabelaDePreco)
	if (!item.produto.id) throw new Error(`Produto ${displayNomeCompletoDoProduto(item.produto)} não cadastrado`)

	let itemDaTabelaDePreco: ItemDaTabelaDePreco | null = null

	if (tabelasDePromocao && tabelasDePromocao.length) {
		const itemEmPromocoesDiferentes = await Promise.all(
			tabelasDePromocao.map(async tabelaDePromocao => {
				const itemDaTabela = await findItemTabelaDePrecoUseCase
					.getByIdDoProduto(tabelaDePromocao.id, item.produto.id)
					.catch(() => null)
				return itemDaTabela
			}),
		)
		const itemComMenorPreco = itemEmPromocoesDiferentes
			.filter(item => item !== null)
			.sort((itemA, itemB) => (itemA?.precoCompra || 0) - (itemB?.precoCompra || 0))
		itemDaTabelaDePreco = itemComMenorPreco[0] || null
	}

	if (!itemDaTabelaDePreco && tabelaPadrao) {
		itemDaTabelaDePreco = await findItemTabelaDePrecoUseCase.getByIdDoProduto(tabelaPadrao.id, item.produto.id)
			.catch(() => null)
	}

	const itemComPrecoAplicado: ItemDaComanda = itemDaTabelaDePreco
		? aplicarTabelaNoItemDaVenda(itemDaTabelaDePreco, item)
		: aplicarPrecoBaseDoProdutoNoItemDaVenda(item)

	if (itemComPrecoAplicado.preco === null || itemComPrecoAplicado.preco === 0)
		throw new Error(`Produto ${displayNomeCompletoDoProduto(item.produto)} está com preço zerado`)
	
	return itemComPrecoAplicado 

}

function aplicarTabelaNoItemDaVenda(itemDaTabelaDePreco: ItemDaTabelaDePreco, itemDaComanda: ItemDaComanda): ItemDaComanda {
	return {
		...itemDaComanda,
		preco: itemDaTabelaDePreco.precoFinalVarejo,
		precoDeCusto: itemDaTabelaDePreco.precoCompra || 0,
	}
}

function aplicarPrecoBaseDoProdutoNoItemDaVenda(itemDaComanda: ItemDaComanda): ItemDaComanda {
	return {
		...itemDaComanda,
		preco: itemDaComanda.produto.preco,
		precoDeCusto: 0,
		observacao: '',
	}
}