import request from "@/common/request-tiflux"
import { TicketTiflux } from "@/models"


export const fetchTicketInfo = (numeroTicket: string): Promise<TicketTiflux> => {
	
	return request.get(`/api/v1/tickets/${numeroTicket}`, {
		// eslint-disable-next-line @typescript-eslint/camelcase
		params: { include_filled_entity: true },
	})
}