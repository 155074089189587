import {
	BuscaCepServiceAdapter,
	CepResponse,
	CepResponseError,
} from '@/usecases'
import { injectable } from 'inversify-props'
import axios from 'axios'

@injectable()
export class BuscaCepService implements BuscaCepServiceAdapter {
	busca = async (cep: string): Promise<CepResponse> => {
		const { data } = await axios.get<CepResponse | CepResponseError>(
			`https://viacep.com.br/ws/${cep}/json`,
		)
		if ('erro' in data) throw new Error('CEP não encontrado')
		return data
	}
}
