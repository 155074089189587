import { Inject } from 'inversify-props'
import type { FornecedorServiceAdapter } from './adapter'
import { Fornecedor, FormFornecedor } from '@/models'

export class SaveFornecedorUseCase {
	@Inject('FornecedorServiceAdapter')
	private FornecedorServiceAdapter!: FornecedorServiceAdapter

	create = async (fornecedor: FormFornecedor): Promise<Fornecedor> =>
		await this.FornecedorServiceAdapter.create(fornecedor)

	update = async (fornecedor: FormFornecedor): Promise<Fornecedor> =>
		await this.FornecedorServiceAdapter.update(fornecedor)
}
