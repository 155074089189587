import {Inject} from "inversify-props";
import type { TabelaDePrecoServiceAdapter } from '@/usecases';

export class DeleteItemUseCase {
	@Inject('TabelaDePrecoServiceAdapter')
	private tabelaService!: TabelaDePrecoServiceAdapter

	async execute(idTabela: string, itemId: string): Promise<void> {
		return this.tabelaService.removeItem(idTabela, itemId)
	}

	async desvincularLojaDaTabela(idTabela: string, lojaId: string): Promise<void> {
		return this.tabelaService.desvincularLojaDaTabela(idTabela, lojaId)
	}
}