import { Inject } from 'inversify-props'
import type { AnalyticsServiceAdapter } from "./adapter";
import { AnalyticsData } from "@/models/AnalyticsData";

export class FindAnalyticsUseCase {
	@Inject('AnalyticsServiceAdapter')
	private analyticsServiceAdapter!: AnalyticsServiceAdapter

	find = async (identificador: string, lojas: string[]): Promise<AnalyticsData> =>
		await this.analyticsServiceAdapter.find(identificador, lojas)
}