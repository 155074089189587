import { ItemDaTabelaDePreco, ItemGranelTabelaDePreco } from '@/models'
import {Inject} from "inversify-props"
import type { TabelaDePrecoServiceAdapter } from '@/usecases'
import { AxiosRequestConfig } from 'axios'

export class FindItemDaTabelaDePrecoUseCase {
	@Inject('TabelaDePrecoServiceAdapter')
	private tabelaService!: TabelaDePrecoServiceAdapter

	async getByIdDoProduto(idTabela: string, id: string): Promise<ItemDaTabelaDePreco> {
		return (await this.tabelaService.buscaItensPorTabelaDePreco(idTabela, { produtoId: id })).content[0]
	}

	listarItensGraneisDaTabelaDePreco = async (idTabela: string, params?: any, config?: AxiosRequestConfig): Promise<ItemGranelTabelaDePreco[]> => 
		await this.tabelaService.listarItensGraneisDaTabelaDePreco(idTabela, params, config)

	async buscarQuantidadeCompradaDoItemHaDoisDias(itemTabelaPrecoId: string, cpfOuCnpjDoCliente: string): Promise<number> {
		return (await this.tabelaService.buscarQuantidadeCompradaDoItemHaDoisDias(itemTabelaPrecoId, cpfOuCnpjDoCliente))
	}
}
