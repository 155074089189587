import request from '@/common/request'
import { PontoDeVenda, PontoDeVendaComTurno } from '@/models'

export const editarSituacaoPdv = (pdvId: string, aberto: boolean): Promise<PontoDeVenda> =>
	request.patch(`/pdvs/${pdvId}/abrir-fechar`, {
		pdvId,
		aberto,
	})

export const buscarPdvSemTurno = (lojaId?: string): Promise<PontoDeVendaComTurno[]> =>
	request.get(`/pdvs/pdv-aberto/${lojaId}`);
	
