import { Inject } from 'inversify-props'
import type { MotivoDaMovimentacaoServiceAdapter } from './adapter'

export class DeleteMotivoDaMovimentacaoUseCase {
	@Inject('MotivoDaMovimentacaoServiceAdapter')
	private motivoDaMovimentacaoService!: MotivoDaMovimentacaoServiceAdapter

	delete = async (id: string): Promise<void> =>
		await this.motivoDaMovimentacaoService.delete(id)
}
