import { Inject } from "inversify-props"
import type { AnexoServiceAdapter } from "./adapter/AnexoServiceAdapter"
import { AnexoDosProcessos } from "@/models/anexo/AnexoDosProcessos"

export class AnexoUseCase {
	@Inject('AnexoServiceAdapter')
	private anexoServiceAdapter!: AnexoServiceAdapter

	createOuUpdate = async(anexo: AnexoDosProcessos): Promise<void> =>
		await this.anexoServiceAdapter.createOuUpdate(anexo)

	findAnexoByIdentificador = async(identificador: string): Promise<AnexoDosProcessos> =>
		await this.anexoServiceAdapter.findAnexoByIdentificador(identificador)

	findAnexoIdByIdentificador = async(identificador: string): Promise<string> =>
		await this.anexoServiceAdapter.findAnexoIdByIdentificador(identificador)

	createOuUpdatePublico = async(anexo: AnexoDosProcessos, usuarioId: string): Promise<void> =>
		await this.anexoServiceAdapter.createOuUpdatePublico(anexo, usuarioId)

	findAnexoIdByIdentificadorPublico = async(identificador: string, usuarioId: string): Promise<string> =>
		await this.anexoServiceAdapter.findAnexoIdByIdentificadorPublico(identificador, usuarioId)

}