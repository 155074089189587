import * as versaoApi from '@/api/versao'
import itensDeMenu, { ItemDeMenu } from '@/shareds/itensDeMenu'
import { temPermissaoItemDeMenu } from '@/shareds/permissoes-shareds'
import store from '@/store/vuex'
import { DownloadZipUseCase } from '@/usecases'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import UserLoginStore from '../authentication/UserLoginStore'
import NotificacaoStore from './NotificacaoStore'
import DownloadNFStore from '../fiscal/DownloadStore'
import PedidoStore from './PedidoStore'

const MODO_NOTURNO = 'MODO_NOTURNO'

// 30 SEGUNDOS
const TEMPO_DE_SINCRONIZACAO = 30000

@Module({ name: 'aplicacao', store, dynamic: true, namespaced: true })
class AppStore extends VuexModule {
	showSideBar: boolean | null = null
	mostraSumarioDeTeclas: boolean | null = null
	modoNoturno: boolean = localStorage[MODO_NOTURNO]
		? JSON.parse(localStorage[MODO_NOTURNO])
		: false
	idIntervalSincronizacao: NodeJS.Timeout | null = null
	versaoEmUso = process.env.PACKAGE_VERSION || null
	versaoServidor: string | null = null
	downloadZipUseCase = new DownloadZipUseCase()

	@Mutation
	setShowSideBar(show: AppStore['showSideBar']) {
		this.showSideBar = show
	}

	@Mutation
	setMostraSumarioDeTeclas(mostra: AppStore['mostraSumarioDeTeclas']) {
		this.mostraSumarioDeTeclas = mostra
	}

	@Mutation
	setModoNoturno(modoNoturno: AppStore['modoNoturno']) {
		localStorage[MODO_NOTURNO] = JSON.stringify(modoNoturno)
		this.modoNoturno = modoNoturno
	}

	@Mutation
	setIdIntervalSincronizacao(idIntervalSincronizacao: NodeJS.Timeout | null) {
		this.idIntervalSincronizacao = idIntervalSincronizacao
	}

	@Mutation
	setVersaoServidor(versaoServidor: string) {
		this.versaoServidor = versaoServidor
	}

	get itensDeMenuPermitidos() {
		if (this.temSomenteUmMenuPermitido) return this.itensDeRecursosPermitidos
	
		const itensComMenus = itensDeMenu
			.filter(({ rotaComMenus }) => !!rotaComMenus)
		return [
			...itensComMenus,
			...this.itensDeRecursosPermitidos,
		]
	}

	get requerAtualizacao() {
		return (
			this.versaoServidor !== null && this.versaoEmUso !== this.versaoServidor
		)
	}

	@Action({ rawError: true })
	async inicializaSincronizacao() {
		try {
			await this.sincronizar()
			this.setIdIntervalSincronizacao(setInterval(() => {
				this.sincronizar()
			}, TEMPO_DE_SINCRONIZACAO))
		} catch (e) {
			throw new Error(e as string)
		}
	}

	@Action({ rawError: true })
	async sincronizar() {
		await Promise.all([
			UserLoginStore.atualizarUsuario(),
			NotificacaoStore.atualizarNotificacoes(),
			PedidoStore.atualizarPedidos(),
			this.verificarVersao(),
			this.sincronizarDownloads(),
		])
	}

	@Action
	async sincronizarDownloads() {
		DownloadNFStore.downloadsNF.filter(d => d.status == 'Pendente')
			.forEach(d => this.downloadZipUseCase.baixarXmls(d.id))
	}

	@Action({ rawError: true })
	async verificarVersao() {
		if (process.env.NODE_ENV !== 'production') return
		const versaoServidor = await versaoApi.get()
		this.setVersaoServidor(versaoServidor.versao)
	}

	@Action
	async pararSincronizacao() {
		if (!this.idIntervalSincronizacao) return

		window.clearInterval(this.idIntervalSincronizacao)
		this.setIdIntervalSincronizacao(null)
	}

	get itensDeRecursosPermitidos() {
		const itensDeRecursos = itensDeMenu
			.filter(({ rotaComMenus }) => !rotaComMenus)
		const permissoes = UserLoginStore.perfil?.permissoes || []
		return itensDeRecursos.reduce(reduceItensDeMenuNaoPermitidos(permissoes), [])
	}

	get temSomenteUmMenuPermitido() {
		return this.itensDeRecursosPermitidos.length === 1
	}

	get itemDeMenuEstaPermitido() {
		return (item: ItemDeMenu) => {
			const permissoes = UserLoginStore.perfil?.permissoes || []
			return temPermissaoItemDeMenu(permissoes, item)
		}
	}
}

const reduceItensDeMenuNaoPermitidos = (permissoes: string[]) => (
	itens: ItemDeMenu[],
	item: ItemDeMenu,
) => {
	if (!item.children) {
		if (!temPermissaoItemDeMenu(permissoes, item)) return itens
		return [ ...itens, item ]
	}

	const children = item.children?.reduce(
		reduceItensDeMenuNaoPermitidos(permissoes),
		[],
	)

	if (!children || !children.length) return itens

	return [...itens, { ...item, children }]
}

export default getModule(AppStore)
