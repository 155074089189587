import request from "@/common/request";
import { Page } from "@/models";
import { FormMeta, Meta } from "@/models/Meta";
import { AxiosRequestConfig } from "axios";

const PATH = "/nova-meta" 

export const find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Meta>> => 
	request.get(`${PATH}`, {
		params, 
		...config,
	})


export const get = (id: string): Promise<FormMeta> =>
	request.get(`${PATH}/${id}`)

export const create = (meta: Meta): Promise<FormMeta> =>
	request.post(`${PATH}`, meta)

export const update = (data: Meta): Promise<FormMeta> =>
	request.put(`${PATH}/${data.id}`, data)

export const remove = async (id: string): Promise<void> =>
	request.delete(`${PATH}/${id}`)
	

