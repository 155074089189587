import "reflect-metadata"
import { injectable } from "inversify-props";
import { ManifestoServiceAdapter, ParamsFindManifesto } from '@/usecases/manifesto/adapter';
import { FormManifesto } from '@/models';
import { 
	create,
	update,
	find,
	findBySerieNota,
	findByMotorista,
} from '@/api/manifesto';

@injectable()
export class ManifestoService implements ManifestoServiceAdapter {
	create = async (manifesto: FormManifesto) => await create(manifesto)
	update = async (manifesto: FormManifesto) => await update(manifesto)
	findBySerieNota = async (lojaId: string, nnf: string, serie: string) => await findBySerieNota(lojaId, nnf, serie)
	findByMotorista = async (motoristaId: string) => await findByMotorista(motoristaId)
	find = async (params?: ParamsFindManifesto) => await find(params);
}
