import { FormDeTitulo, Titulo, EntradaDeTitulo, SituacaoDoTitulo, Parcelamento, TituloComParcelamento } from '@/models'
import type { TituloServiceAdapter } from './adapter/TituloServiceAdapter'
import { SaveContasAPagarUseCase } from './contas-a-pagar'
import { SaveContasAReceberUseCase } from './contas-a-receber'
import { Inject } from 'inversify-props'

export class SaveTituloUseCase {
	private saveContasAPagarUseCase = new SaveContasAPagarUseCase()
	private saveContasAReceberUseCase = new SaveContasAReceberUseCase()
	
	@Inject('TituloServiceAdapter')
	private tituloServiceAdapter!: TituloServiceAdapter

	async execute(form: FormDeTitulo, parcelamento: Parcelamento): Promise<Titulo> {
		if (!form) {
			throw new Error('Nenhum formulário informado')
		}
		if (!form.dataDeVencimento) {
			throw new Error('Data de vencimento não informada')
		}
		if(!form.pessoa) {
			throw new Error('Pessoa não informada')
		}
		if (!form.valor) {
			throw new Error('Valor não informado')
		}
		if(!form.loja) {
			throw new Error('Loja não informada')
		}
		if(!form.pessoa.id){
			throw new Error('Pessoa não encontrada')
		}
		
		const titulo: EntradaDeTitulo = {
			...form,
			dataDeVencimento: form.dataDeVencimento,
			dataDeCompetencia: form.dataDeCompetencia || form.dataDeVencimento,
			pessoa: form.pessoa.id,
			loja: form.loja.id,
			valor: form.valor,
		}

		const params: TituloComParcelamento = {
			titulo: titulo,
			formaParcelamento: parcelamento,
		}
		
		if(form.fluxo == 'Saída') {
			return this.saveContasAPagarUseCase.execute(params)
		}
		if(form.fluxo == 'Entrada') {
			return this.saveContasAReceberUseCase.execute(params)
		}

		throw new Error("Fluxo do título inválido");
	}

	async updateTitulos(titulosId: string[], situacaoDoTitulo: SituacaoDoTitulo): Promise<Titulo[]> {
		return this.tituloServiceAdapter.updateTitulos(titulosId, situacaoDoTitulo)
	}
}
