import axios from 'axios'
import { stringify } from 'qs'

export const serializarQueryString = (params: any) => stringify(params, {arrayFormat: 'repeat'})

const request = axios.create({
	baseURL: 'https://api.tiflux.com', 
	paramsSerializer: params => stringify(params, {arrayFormat: 'repeat'}),
})

request.interceptors.request.use(
	config => {			
		config.headers['Authorization'] = `Basic c3Vwb3J0ZUBhbG1vZGUuY29tOjcxYjMyYzdkMzhiOGFhMTgxZjM5MGE2MmEwOGM2YmEx`
		return config
	},
	error => Promise.reject(error),
)

request.interceptors.response.use(
	response => response.data,
	error => {
		if (error.response && (error.response.status === 401 || error.response.status === 403)) {
			// Optional: Add logic to handle authorization errors
		}
		return Promise.reject(error)
	},
)

export default request
