import { Inject } from "inversify-props";
import type { ValePresenteServiceAdapter } from "./adapter/ValePresenteServiceAdapter";
import { ValePresente } from "@/models/views/ValePresente";

export class ValePresenteUseCase {
	@Inject('ValePresenteServiceAdapter')
	private valePresenteServiceAdapter!: ValePresenteServiceAdapter

	findValeValido = async (id: string, grupoEconomicoId: string): Promise<ValePresente> =>
		await this.valePresenteServiceAdapter.findValeValido(id, grupoEconomicoId)

	findValesPresentesByVendaId = async (vendaId: string): Promise<ValePresente[]> =>
		await this.valePresenteServiceAdapter.findValesPresentesByVendaId(vendaId)
}