import request from "@/common/request"
import { Page } from "@/models"
import { Romaneio, RomaneioForm } from "@/models/Romaneio"
import { AxiosRequestConfig } from "axios"

const basePath = '/romaneio'

export const update = async (
	romaneio: RomaneioForm,
): Promise<void> => 
	request.put(`${basePath}`, romaneio)

export const salvarRomaneio = async (
	romaneioForm: RomaneioForm,
): Promise<Romaneio> => 
	request.post(`${basePath}`, romaneioForm)

export const findRomaneio = async (
	depositoId: string,
	params?: any,
	config?: AxiosRequestConfig,
): Promise<Page<Romaneio>> => 
	request.get(`${basePath}/${depositoId}`, { params, ...config })

export const atualizar = async (
	romaneio: RomaneioForm,
): Promise<void> => 
	request.put(`${basePath}/atualizar`, romaneio)