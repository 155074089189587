import { FluxoDoTitulo } from '@/models';
import { TipoDeRecurso } from '@/models/Recurso';
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotasDeTitulos: RouteConfig[] = [
	{
		path: 'contas-a-pagar',
		name: 'Contas a Pagar',
		components: {
			default: () =>
                import('@/views/application/titulos/TelaDeTitulos.vue'),
			toolbar: () =>
                import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
			default: {
				fluxo: 'Saída' as FluxoDoTitulo,
			},
		},
		meta: {
			recurso: 'contas-a-pagar' as TipoDeRecurso,
		},
	},
	{
		path: 'contas-a-receber',
		name: 'Contas a Receber',
		components: {
			default: () =>
                import('@/views/application/titulos/NovaTelaDeTitulos.vue'),
			toolbar: () =>
                import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
			default: {
				fluxo: 'Saída' as FluxoDoTitulo,
			},
		},
		meta: {
			recurso: 'contas-a-receber' as TipoDeRecurso,
		},
	},
]

export default rotasDeTitulos