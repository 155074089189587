import { DownloadModule } from "@/store/vuex/fiscal/DownloadStore"
import { DownloadZipUseCase } from '.'
import { v4 as uuidv4 } from 'uuid'
import { StatusDownload } from "@/models"

export class CreateDownloadUseCase {
	
	private downloadZipUseCase = new DownloadZipUseCase()

	/**
	 * 
	 * @param identificador Texto unico com uma descrição simplificada para facilitar a identificação do download na lista. Também é usado como nome do arquivo ao baixar
	 * @param params Estrutura de filtros usada na tela de consulta de nota fical
	 */
	execute = async (identificador: string, xmls: string[]) => {
		
		const download = {
			id: uuidv4(),
			identificador: identificador,
			itens: xmls.map(xml => ({
				id: uuidv4(),
				url: xml,
				pronto: false,
				erro: false,
			})),
			status: 'Pendente' as StatusDownload,
			createdAt: new Date(),
		}

		await DownloadModule.addDownloadNF(download)
		this.downloadZipUseCase.baixarXmls(download.id)
	}
}
