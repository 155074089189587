import 'reflect-metadata'
import { injectable } from 'inversify-props'
import { RegraPorEstadoServiceAdapter } from '@/usecases'
import {
	findRegraPorEstadoOrigem,
	copiarRegraParaEstadoDestino,
	salvarRegrasPorEstado,
} from '@/api/impostos'
import { AxiosRequestConfig } from 'axios'
import { RegraPorEstado } from '@/models'

@injectable()
export class RegraPorEstadoService implements RegraPorEstadoServiceAdapter {
	find = async (idRegraAplicacao: string, ufOrigem: string, params?: any, config?: AxiosRequestConfig) =>
		await findRegraPorEstadoOrigem(idRegraAplicacao, ufOrigem, params, config)

	salvarTodos = async (idRegraImpoto: string, idRegraAplicacao: string, regrasPorEstado: RegraPorEstado[]) =>
		await salvarRegrasPorEstado(idRegraImpoto, idRegraAplicacao, regrasPorEstado)
	
	copiarRegra = async (idRegraOrigem: string, idRegraDestino: string) => await copiarRegraParaEstadoDestino(idRegraOrigem, idRegraDestino)
}
