import request from "@/common/request"
import { Page } from "@/models"
import { CartaDeCorrecao } from "@/models/fiscal/CartaDeCorrecao"
import { AxiosRequestConfig } from "axios"

const basePath = '/carta-de-correcao'

export const criarCartaDeCorrecao = (carta: CartaDeCorrecao): Promise<void> =>
	request.post(`${basePath}`, carta )

export const findCartasDeCorrecao = (params?: any, config?: AxiosRequestConfig): Promise<Page<CartaDeCorrecao>> =>
	request.get(`${basePath}`,  { params, ...config })
