import { injectable } from 'inversify-props'
import { PedidoServiceAdapter } from '@/usecases/venda/adapter/PedidoServiceAdapter'
import { PedidoDeVenda } from '@/models/venda/PedidoDeVenda'
import { update, findLogsByIdDoPedido, atualizarSituacao } from '@/api/pedido'
import { AxiosRequestConfig } from 'axios'
import { Pageable } from '@/models'

@injectable()
export class PedidoService implements PedidoServiceAdapter {
	update = async (pedido: PedidoDeVenda) => await update(pedido)
	findLogsByIdDoPedido = async (id: string, page: Pageable, config?: AxiosRequestConfig) => await findLogsByIdDoPedido(id, page, config)
	atualizarSituacao = async (pedido: PedidoDeVenda) => await atualizarSituacao(pedido)
}
