import { findNotasEmTransito, receberNotaEmTransito, remover } from "@/api/notasemtransito"
import { NotaEmTransito } from "@/models"
import { NotasEmTransitoServiceAdapter } from "@/usecases/fiscal/adapter/NotasEmTransitoServiceAdapter"
import { AxiosRequestConfig } from "axios"
import { injectable } from "inversify"

@injectable()
export class NotasEmTransitoService implements NotasEmTransitoServiceAdapter {
	find = async (params?: any, config?: AxiosRequestConfig) => await findNotasEmTransito(params, config)
	receberNotaEmTransito = async (nota: NotaEmTransito) => await receberNotaEmTransito(nota)
	remover = async (params: any) => await remover(params)
}