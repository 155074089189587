import { TipoDeRecurso } from '@/models/Recurso';
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotasDeRelatorios: RouteConfig[] = [
	{
		path: 'relatorios/relatoriosDeVendas',
		name: 'Relatórios De Vendas',
		components: {
			default: () =>
				import('@/views/application/relatorios/TelaDeRelatoriosDeVendas.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'relatorios' as TipoDeRecurso,
		},
	},
	{
		path: 'relatorios/relatoriosDeDocumentosFiscais',
		name: 'Relatórios De Documentos Fiscais',
		components: {
			default: () =>
				import('@/views/application/relatorios/TelaDeRelatoriosDeDocumentosFiscais.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'relatorios' as TipoDeRecurso,
		},
	},
	{
		path: 'relatorios/relatoriosDeCurvaAbc',
		name: 'Relatórios De Curva ABC',
		components: {
			default: () =>
				import('@/views/application/relatorios/TelaDeRelatoriosCurvaAbc.vue'),
			toolbar: () =>
			import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'relatorios' as TipoDeRecurso,
		},
	},
	{
		path: 'relatorios/relatoriosDeCurvaAbc',
		name: 'Relatórios De Curva ABC',
		components: {
			default: () =>
				import('@/views/application/relatorios/TelaDeRelatoriosCurvaAbc.vue'),
			toolbar: () =>
			import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: { toolbar: tituloDaToolbarPeloNomeDaRota },
		meta: {
			recurso: 'relatorios' as TipoDeRecurso,
		},
	},
]
export default rotasDeRelatorios
