import request from "@/common/request";
import { Importacao, ItemDeImportacao, Page } from "@/models";
import { AxiosRequestConfig } from "axios";

const basePath = '/importacoes'

export const find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Importacao>> => 
	request.get(`${basePath}`, { params, ...config })

export const findItens = (params?: any, config?: AxiosRequestConfig): Promise<Page<ItemDeImportacao>> =>
	request.get(`${basePath}/itens`, { params, ...config })

