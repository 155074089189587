import { injectable } from 'inversify-props'
import { Tag } from '@/models'
import { TagServiceAdapter } from '@/usecases'
import { findTag, createTag, updateTag, findTagColecao } from '@/api/produtos'
import { AxiosRequestConfig } from 'axios'

@injectable()
export class TagService implements TagServiceAdapter {
	find = async (params?: any) => await findTag(params)
	create = async (tag: Tag) => await createTag(tag)
	update = async (tag: Tag) => await updateTag(tag)
	findTagColecao = async (config?: AxiosRequestConfig) => await findTagColecao(config)
}
