import request from "@/common/request"
import { BandeirasDeCartao, Page } from "@/models"

export const find = async (params?: any): Promise<Page<BandeirasDeCartao>> =>
	request.get(`/bandeira-de-cartao`, {
		params: {
			...params,
		},
	})

export const create = async (bandeira: BandeirasDeCartao): Promise<BandeirasDeCartao> =>
	request.post(`/bandeira-de-cartao`, bandeira)

export const update = async (bandeira: BandeirasDeCartao): Promise<BandeirasDeCartao> =>
	request.put(`/bandeira-de-cartao/${bandeira.id}`, bandeira)