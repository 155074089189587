
import {Inject} from "inversify-props"
import type { ItemDaPreVendaServiceAdapter } from './adapter'
import { ItemDaPreVendaImportacao } from "@/models/pre-venda/PreVenda"

export class ImportarItemDaPreVendaUseCase {
	@Inject('ItemDaPreVendaServiceAdapter')
	private itemDaPreVendaServiceAdapter!: ItemDaPreVendaServiceAdapter

	async importarItens(preVendaId: string, itens: ItemDaPreVendaImportacao[]): Promise<any> {
		this.itemDaPreVendaServiceAdapter.importarItens(preVendaId, itens)
	}
}