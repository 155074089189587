import { CapaDashboardPerfil } from '@/models/views/CapaDashboard'
import { AxiosRequestConfig } from 'axios'
import { Inject } from 'inversify-props'
import type { DashboardsServiceAdapter } from './adapter'

export class SaveDashboardsUseCase {
	@Inject('DashboardsServiceAdapter')
	private dashboardService!: DashboardsServiceAdapter

	salvarDashsPerfis = async (dashboard: CapaDashboardPerfil[], config?: AxiosRequestConfig): Promise<void> =>
		await this.dashboardService.salvarDashsPerfis(dashboard, config)

}