import { RouteConfig } from 'vue-router';

const rotasDeConsulta: RouteConfig [] = [
	{
		path: '/nf/consulta/:chaveDeConsulta',
		name: 'Consulta',
		component: () =>
			import('@/views/consulta/nf/ConsultaNF.vue'),
	},
]

export default rotasDeConsulta