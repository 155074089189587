import { TipoDeRecurso } from '@/models/Recurso';
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotas: RouteConfig[] = [
	{
		path: 'planos',
		name: 'Planos',
		components: {
			default: () =>
				import('@/views/application/planos/TelaDePlanos.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'planos' as TipoDeRecurso,
		},
	},
]

export default rotas