import { Inject } from "inversify-props"
import type { EmailServiceAdapter } from "./adapter"
import { FiltroDeCapaDaNota } from "@/models"
import { AxiosRequestConfig } from "axios"

export class EmailUseCase {
	@Inject('EmailServiceAdapter')
	private emailService!: EmailServiceAdapter
	
	async enviarValesPorEmail(vendaId: string, email: string): Promise<void> {
		await this.emailService.enviarValesPorEmail(vendaId, email)
	}

	async enviarXmlsPorEmail(filtro: FiltroDeCapaDaNota, emailDestino: string,  config?: AxiosRequestConfig): Promise<any> {
		await this.emailService.enviarXmlsPorEmail(filtro, emailDestino, config)
	}
}