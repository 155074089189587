import "reflect-metadata";
import { injectable } from 'inversify-props'
import { LoginServiceAdapter } from "@/usecases";
import { Credenciais } from "@/models";
import { TokenJwt } from "@/models";
import { authentication } from "@/api/usuarios";

@injectable()
export class LoginService implements LoginServiceAdapter {
	signInWithCredentials = async (credential: Credenciais): Promise<TokenJwt> => 
		await authentication(credential);
}
