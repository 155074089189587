import "reflect-metadata";
import { injectable } from 'inversify-props'
import { FormFornecedor } from './../../models/Fornecedor';
import { FornecedorServiceAdapter } from '@/usecases/fornecedor/adapter/FornecedorServiceAdapter'
import { create, update, find, get, remove } from '@/api/fornecedor'
import { AxiosRequestConfig } from 'axios'

@injectable()
export class FornecedorService implements FornecedorServiceAdapter {
	create = async (fornecedor: FormFornecedor) => await create(fornecedor)
	update = async (fornecedor: FormFornecedor) => await update(fornecedor)
	get = async (id: string) => await get(id)
	find = async (params?: any, config?: AxiosRequestConfig) => await find(params, config)
	remove = async (id: string) => await remove(id)
}