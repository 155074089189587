import request from '@/common/request'
import {
	FormTurnoDeVenda,
	TurnoDeVenda,
	Page,
	MovimentacaoDeCaixa,
	FechamentoDeCaixa,
} from '@/models'

const basePath = '/turno-venda'

export const create = (data: FormTurnoDeVenda): Promise<TurnoDeVenda> =>
	request.post(`${basePath}`, data)

export const find = (params?: any): Promise<Page<TurnoDeVenda>> =>
	request.get(`${basePath}`, {
		params: {
			...params,
		},
	})
export const get = (id: string): Promise<TurnoDeVenda> =>
	request.get(`${basePath}/${id}`)

export const createMovimentacao = (
	idTurno: string,
	movimentacao: MovimentacaoDeCaixa,
): Promise<MovimentacaoDeCaixa> =>
	request.post(`${basePath}/${idTurno}/movimentacoes`, movimentacao)

export const updateMovimentacao = (
	idTurno: string,
	idMovimentacao: string,
	movimentacao: MovimentacaoDeCaixa,
): Promise<MovimentacaoDeCaixa> =>
	request.put(`${basePath}/${idTurno}/movimentacoes/${idMovimentacao}`, movimentacao)

export const fechamento = (
	idTurno: string,
	fechamento: FechamentoDeCaixa,
): Promise<TurnoDeVenda> =>
	request.post(`${basePath}/${idTurno}/fechamento`, fechamento)

export const podeAbrirCaixa = (pdvId: string): Promise<Array<string>> => request.get(`${basePath}/podeAbrirCaixaComNotaInvalida/${pdvId}`)
