import { cancelar, findCashbackPorCliente, findItensCashback, findCampanhas, criarCampanha, atualizarCampanha,
	findCampanhasAplicaveis, 
	findListagemDeCashback,
	get,
	importarCashback, criarAcaoDeCashback, findAcoesDeCashback, atualizarAcaoDeCashback} from "@/api/cashback";
import { AcaoDeCashback } from "@/models/AcaoDeCashback";
import { CampanhaCashback } from "@/models/CampanhaCashback";
import { CashbackServiceAdapter } from "@/usecases/cliente/adapter/CashbackServiceAdapter";
import { AxiosRequestConfig } from "axios";
import { injectable } from "inversify-props";

@injectable()
export class CashbackService implements CashbackServiceAdapter {
	findCashBackPorCliente = async (params?: any, config?: AxiosRequestConfig) => await findCashbackPorCliente(params, config)
	findItensCashback = async (params?: any, config?: AxiosRequestConfig) => await findItensCashback(params, config)
	cancelar = async (id: string) => await cancelar(id)
	findCampanhas = async (params?: any, config?: AxiosRequestConfig) => await findCampanhas(params, config)
	criarCampanha = async (campanhaCashback: CampanhaCashback) => await criarCampanha(campanhaCashback)
	atualizarCampanha = async (campanhaCashback: CampanhaCashback) => await atualizarCampanha(campanhaCashback)
	findCampanhasAplicaveis = async (tipoClienteId: string | null, lojaId: string) => await findCampanhasAplicaveis(tipoClienteId, lojaId)
	findListagemDeCashback = async (params?: any, config?: AxiosRequestConfig) => await findListagemDeCashback(params, config)
	get = async (id: string) => await get(id)
	importarCashback = async (arquivoContemCabecalho: boolean, urlDoArquivo: string) => await importarCashback(arquivoContemCabecalho, urlDoArquivo)
	criarAcaoDeCashback = async (acaoDeCashback: AcaoDeCashback) => await criarAcaoDeCashback(acaoDeCashback)
	findAcoesDeCashback = async (params?: any, config?: AxiosRequestConfig) => await findAcoesDeCashback(params, config)
	atualizarAcaoDeCashback = async (acaoDeCashback: AcaoDeCashback) => await atualizarAcaoDeCashback(acaoDeCashback)
}