import { Inject } from 'inversify-props'
import type { CESTServiceAdapter } from '../adapter/CESTServiceAdapter'
import { CodigoEDescricao, Page } from '@/models'

export class FindCESTUseCase {
	@Inject('CESTServiceAdapter')
	private cestService!: CESTServiceAdapter

	findById = async (id: string): Promise<CodigoEDescricao> =>
		await this.cestService.get(id)

	find = async (params?: any): Promise<Page<CodigoEDescricao>> =>
		await this.cestService.find(params)
}
