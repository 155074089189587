import { Faq, Page } from "@/models"
import { AxiosRequestConfig } from "axios"
import { Inject } from 'inversify-props'
import type { FaqServiceAdapter } from './adapter'

export class FindFaqUseCase {
	@Inject('FaqServiceAdapter')
	private FaqService!: FaqServiceAdapter

	findById = async (id: string): Promise<Faq> =>
		await this.FaqService.get(id)

	findFaqs = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Faq>> =>
		await this.FaqService.find(params, config)
}