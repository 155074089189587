import { Inject } from 'inversify-props'
import type { VendaServiceAdapter } from './adapter'
import { Fornecedor, ModalidadeDeFrete, UsuarioBase, Venda } from '@/models'
export class EditarUmaVendaUseCase {
	@Inject('VendaServiceAdapter')
	private vendaService!: VendaServiceAdapter

	async executar(id: string, venda: EdicaoDeUmaVenda): Promise<Venda>  {

		if ((!venda.informacoesDeTransporte?.especie) ||
				(!venda .informacoesDeTransporte?.volume)) {
			if ((venda.informacoesDeTransporte?.especie) ||
							(venda.informacoesDeTransporte?.volume)) {
				throw new Error('Não informado todos os dados do frete');	
			}
		}
		
		return await this.vendaService.update(id, {
			...venda,
			informacoesDeTransporte: {
				...venda.informacoesDeTransporte,
				especie: venda.informacoesDeTransporte?.especie || null,
				volume: venda.informacoesDeTransporte?.volume || null,
				pesoLiquido: venda.informacoesDeTransporte?.pesoLiquido || null,
				pesoBruto: venda.informacoesDeTransporte?.pesoBruto || null,
				fornecedor: venda.informacoesDeTransporte?.fornecedor || null,
			},
			volumes: venda.volumes,
			produtosVerificados: venda.produtosVerificados,
		})
	}

	async patchVendedor(idVenda: string, vendedor: UsuarioBase): Promise<Venda> {
		return await this.vendaService.patchVendedor(idVenda, vendedor)
	}

	async removerVendedor(idVenda: string, vendedor: UsuarioBase): Promise<void> {
		await this.vendaService.removerVendedor(idVenda, vendedor)
	}

	async patchRepresentante(idVenda: string, representante: UsuarioBase): Promise<Venda> {
		return await this.vendaService.patchRepresentante(idVenda, representante)
	}

	async patchVendaOrigem(idVenda: string, vendaOrigemId: string ): Promise<Venda> {
		return await this.vendaService.patchVendaOrigem(idVenda, vendaOrigemId)
	}

	async removerVendaOrigem(idVenda: string ): Promise<Venda> {
		return await this.vendaService.removerVendaOrigem(idVenda)
	}

	async enviarEmail(vendaId: string, email: string): Promise<void> {
		await this.vendaService.enviarEmail(vendaId, email)
	}

	async atualizarModalidade(modalidadeId: string, vendaId: string): Promise<void> {
		await this.vendaService.atualizarModalidade(modalidadeId, vendaId)
	}

	async atualizarVendedores(vendedorEdit: VendedorEdit): Promise<void> {
		await this.vendaService.atualizarVendedores(vendedorEdit)
	}

	async atualizarNumeroDoTicket(numeroTicket: string | null, vendaId: string): Promise<void> {
		await this.vendaService.atualizarNumeroDoTicket(numeroTicket, vendaId)
	}

	async removerNumeroTicket(vendaId: string): Promise<void> {
		await this.vendaService.removerNumeroTicket(vendaId)
	}

}

export interface EdicaoDeUmaVenda {
	informacoesDeTransporte?: EdicaoDeInformacaoDeTransporte
	volumes?: string[]
	produtosVerificados: boolean
}

export interface EdicaoDeInformacaoDeTransporte {
	modalidadeDeFrete: ModalidadeDeFrete
	volume: number | null | ''
	especie: string | null
	pesoLiquido: number | null
	pesoBruto: number | null | ''
	fornecedor: Fornecedor | null
	valorFrete: number | null | ''
}

export interface VendedorEdit {
	id: string
	vendedores: UsuarioBase[] | null
	itens: VendedorItemEdit[]
}

export interface VendedorItemEdit {
	id: string
	vendedor: UsuarioBase | null
}

export interface NumeroDoTicketEdit {
	numeroTicket: string
}
