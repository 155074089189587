import { Faq } from '@/models';
import { FaqServiceAdapter } from '@/usecases/faq/adapter/FaqServiceAdapter';
import {
	get,
	find,
	create,
	update,
	remove,
} from '@/api/faq'
import { AxiosRequestConfig } from 'axios';
import { injectable } from 'inversify-props';


@injectable()
export class FaqService implements FaqServiceAdapter {
	get = async (id: string) => await get(id)
	find = async (params?: any, config?: AxiosRequestConfig) => await find(params, config)
	create = async (faq: Faq) => await create(faq)
	update = async (faq: Faq) => await update(faq)
	remove = async (id: string): Promise<void> => await remove(id)
}