import { onlyRouterGuard } from '@/shareds/router-guards';
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from 'vue-router';

const rotasDeInicio: RouteConfig [] = [
	{
		path: '',
		name: 'Início',
		components: {
			default: () => 
				import('@/views/application/Home.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		beforeEnter: onlyRouterGuard,
	},
]

export default rotasDeInicio