import { RouteConfig } from 'vue-router'

const rotasNaoAutenticadas: RouteConfig [] = [
	{
		path: '/',
		component: () => 
			import('@/views/landing-page/LandingPage.vue'),
		children: [
			{
				path: '',
				name: 'Landing Page',
				component: () =>
					import('@/views/landing-page/Home.vue'),
			},
		],
	},
	{
		path: '/cadastro',
		name: 'Cadastrar',
		component: () => 
			import('@/views/landing-page/SignUp.vue'),
	},
	{
		path: '/entrar',
		name: 'Logar',
		component: () => 
			import('@/views/landing-page/SignIn.vue'),
	},
	{
		path: '/validation',
		name: 'Validar',
		component: () => 
			import('@/views/landing-page/ValidationEmail.vue'),
	},
]

export default rotasNaoAutenticadas