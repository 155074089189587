import { TipoDeRecurso } from '@/models/Recurso';
import { tituloDaToolbarPeloNomeDaRota } from '@/shareds/router-shareds';
import { RouteConfig } from "vue-router";

const rotasDeContas: RouteConfig[] = [
	{
		path: 'conta',
		name: 'Minha Conta',
		components: {
			default: () =>
				import('@/views/application/conta/TelaDeConta.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'contas' as TipoDeRecurso,
		},
	},
]

export default rotasDeContas