import request from '@/common/request'
import { Cliente, ConfiguracaoDeNotificacaoPorEmail, ConfiguracaoDeNotificacaoPorUsuario, FormLoja, Loja, Page, ParametrosControlPay } from '@/models'
import { SerieFiscal } from '@/models/SerieFiscal'
import { AxiosRequestConfig } from 'axios'

export const get = async (id: string, params?: AxiosRequestConfig): Promise<Loja> =>
	request.get(`/lojas/${id}`, params)

export const find = (params?: any, config?: AxiosRequestConfig): Promise<Page<Loja>> =>
	request.get(`/lojas`, {
		params,
		...config,
	})

export const update = async (data: FormLoja): Promise<Loja> =>
	request.put(`/lojas/${data.id}`, data)

export const create = async (data: FormLoja): Promise<Loja> =>
	request.post(`/lojas`, data)

export const patch = async (loja: FormLoja): Promise<Loja> =>
	request.patch(`/lojas/${loja.id}`, loja)

export const remove = async (id: string): Promise<void> =>
	request.delete(`/lojas/${id}`)

export const changeLoja = async (loja: Partial<FormLoja>, rota: string): Promise<Loja> =>
	request.patch(`/lojas/${loja.id}/${rota}`, loja)

export const buscarParametrosControlPayDaLoja = async (lojaId: string): Promise<ParametrosControlPay> =>
	request.get(`/lojas/${lojaId}/controlpay`)

export const gravarParametrosControlPay = async (lojaId: string, parametrosControlPay: ParametrosControlPay): Promise<ParametrosControlPay> =>
	request.post(`/lojas/${lojaId}/controlpay`, parametrosControlPay)

export const buscarSeriesDeDanfes = async (lojaId: string): Promise<SerieFiscal[]> =>
	request.get(`/lojas/${lojaId}/series`)

export const vincularClientesComLoja = async (lojaId: string, clientes: Cliente[]): Promise<void> =>
	request.post(`/lojas/${lojaId}/vincular`, clientes)

export const desvincularLojaECliente = async (idLoja: string, clienteId: string): Promise<void> =>
	request.post(`/lojas/${idLoja}/desvincular/loja-cliente/${clienteId}`)

export const findLojasVinculadasComCliente = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Loja>> =>
	request.get(`/lojas/cliente`, {
		params,
		...config,
	})

export const vincularLojas = async (loja: Partial<FormLoja>): Promise<void> =>
	request.post(`/lojas/${loja.id}/vincular-lojas`, loja)

export const desvincularLoja = async (lojaId: string, lojaDesvincular: string): Promise<void> =>
	request.post(`/lojas/${lojaId}/${lojaDesvincular}/desvincular`)

export const findLojasVinculadasAsLojasDoUsuario = async (): Promise<Loja[]> =>
	request.get(`/lojas/usuarios/vinculadas`)

export const findConfigDeNotificacaoPorUsuario = async (lojaId: string): Promise<ConfiguracaoDeNotificacaoPorUsuario[]> =>
	request.get(`/lojas/${lojaId}/notificacoes/usuario`)

export const salvarConfigDeNotificacaoPorUsuario = async (lojaId: string, configuracaoDeNotificacao: ConfiguracaoDeNotificacaoPorUsuario): Promise<void> =>
	request.post(`/lojas/${lojaId}/notificacoes/usuario`, configuracaoDeNotificacao)

export const deletarConfigDeNotificacaoPorUsuario = async (configId: string): Promise<void> =>
	request.delete(`/lojas/notificacoes/usuario/${configId}`)

export const findConfigDeNotificacaoPorEmail = async (lojaId: string): Promise<ConfiguracaoDeNotificacaoPorEmail[]> =>
	request.get(`/lojas/${lojaId}/notificacoes/email`)

export const salvarConfigDeNotificacaoPorEmail = async (lojaId: string, configuracaoDeNotificacao: ConfiguracaoDeNotificacaoPorEmail): Promise<void> =>
	request.post(`/lojas/${lojaId}/notificacoes/email`, configuracaoDeNotificacao)

export const deletarConfigDeNotificacaoPorEmail = async (configId: string): Promise<void> =>
	request.delete(`/lojas/notificacoes/email/${configId}`)

export const buscarTodasAsLojasPorFiltro = (params?: any, config?: AxiosRequestConfig): Promise<Loja[]> =>
	request.get(`/lojas/lista-completa`, {
		params,
		...config,
	})

export const findLojasByGrupoEconomicoId = (grupoEconomicoId: string, config?: AxiosRequestConfig): Promise<Page<Loja>> =>
	request.get(`/lojas/grupo-economico/${grupoEconomicoId}`, {
		...config,
	})