import { PontoDeVenda } from "@/models";
import { Inject } from "inversify-props";
import type { PdvServiceAdapter } from "./adapter/PdvServiceAdapter";

export class EditarPdvUseCase {

	@Inject("PdvServiceAdapter")
	private pdvService!: PdvServiceAdapter

	async editarSituacaoPdv(pdvId: string, aberto: boolean): Promise<PontoDeVenda> {
		return this.pdvService.editarSituacaoPdv(pdvId, aberto)
	}
}