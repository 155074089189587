import axios from 'axios'
import { JWT_TOKEN } from '@/common/constants'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { backendDomain } from '@/shareds/utils'
import { stringify } from 'qs'

export const serializarQueryString = (params: any) => stringify(params, {arrayFormat: 'repeat'})

const relativePath = backendDomain()

const request = axios.create({
	baseURL: `${relativePath}`,
	paramsSerializer: params => stringify(params, {arrayFormat: 'repeat'}),
})

request.interceptors.request.use(
	config => {
		const token: string = localStorage.getItem(JWT_TOKEN) || ''
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`
		}
		return config
	},
	error => Promise.reject(error),
)

request.interceptors.response.use(
	response => {
		const res = response
		if (res.status === 401 || res.status === 403) {
			UserLoginStore.logout()
		} else {
			return res.data
		}
	},
	error => Promise.reject(error),
)

export default request
