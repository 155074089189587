import request from '@/common/request'
import { Page } from '@/models'
import { CurvaAbc, FiltroDeCurvaAbc, TotalizadoresDeCurvaAbc } from '@/models/CurvaAbc'

const basePath = '/relatorio-curva-abc'

export const listRelatorioCurvaAbc = (params?: FiltroDeCurvaAbc): Promise<Page<CurvaAbc>> =>
	request.get(`${basePath}/relatorio`, {
		params,
	})

export const getTotaisDeCurvaAbc = (params?: FiltroDeCurvaAbc): Promise<TotalizadoresDeCurvaAbc> =>
	request.get(`${basePath}/relatorio/totalizadores`, {
		params,
	})