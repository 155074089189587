import { Cadastro, SignUpResponse, ResponseStatus } from '@/models';
import { Inject } from 'inversify-props';
import type { ContaServiceAdapter } from './adapter/ContaServiceAdapter';


export class CadastroContaUseCase {
	@Inject("ContaServiceAdapter")
	private contaServiceAdapter!: ContaServiceAdapter

	async signUpAccount(cadastro: Cadastro): Promise<SignUpResponse> {
		const response = { status: '', erro: ''}

		try {
			const contaResponse = await this.contaServiceAdapter.signUpAccount(cadastro)
			if (contaResponse) {
				response.status = ResponseStatus.SUCCESS
				response.erro = ''
			}
		} catch (e: any) {
			const erro = e.response.data ? `: ${e.response.data}` : ''
			response.status = ResponseStatus.ERRO
			response.erro = `Não foi possível efetuar o cadastro ${erro}`
		}
		return Promise.resolve(response)
	}
}