import { Inject } from 'inversify-props'
import { AtributosDaVariante, ConsultaPrecoDeProduto, Page, Produto, SituacaoDoProdutoNaLoja, Variante } from '@/models'
import type { EstoqueServiceAdapter, ProdutoServiceAdapter } from '..'
import { AxiosRequestConfig } from 'axios'

export class FindProdutoUseCase {
	@Inject('ProdutoServiceAdapter')
	private produtoService!: ProdutoServiceAdapter

	@Inject('EstoqueServiceAdapter')
	private depositoService!: EstoqueServiceAdapter

	findProdutoById = async (id: string): Promise<Produto> =>
		await this.produtoService.get(id)

	find = async (params?: any, config?: AxiosRequestConfig) => {
		return await this.produtoService.find(params, config)
	}

	findProdutosSeletor = async (params?: any, config?: AxiosRequestConfig) => {
		return await this.produtoService.findProdutosSeletor(params, config)
	}

	findOtimizado = async (params?: any, config?: AxiosRequestConfig) => {
		return await this.produtoService.findOtimizado(params, config)
	}

	async findProdutoComEstoque(identificador: string, loja: string): Promise<Produto> {
		return this.depositoService.encontrarProdutoComEstoque(identificador, loja)
	}

	async validarProduto(identificador: string, loja: string, quantidade: number): Promise<Produto> {
		return this.depositoService.validarProduto(identificador, loja, quantidade)
	}

	async validarProdutoDesativado(produtoId: string): Promise<Produto> {
		return this.produtoService.validarProdutoDesativado(produtoId)
	}

	async findProdutoComEstoqueRomaneio(identificador: string, loja: string, quantidade: number, tipoDeMovimentacao: string): Promise<Produto> {
		return this.depositoService.encontrarProdutoComEstoqueRomaneio(identificador, loja, quantidade, tipoDeMovimentacao)
	}

	async findProdutoPorIdentificador(identificador: string): Promise<Produto> {
		return this.produtoService.encontrarProdutoPorIdentificador(identificador)
	}

	buscaItensPorTabelaDePreco = async (idDaTabelaDePreco: string, params?: any, config?: AxiosRequestConfig): Promise<Page<Produto>> =>
		await this.produtoService.buscaItensSemPrecoNaTabela(idDaTabelaDePreco, params, config)

	buscaSituacaoDosProdutos = async (idLoja: string, params?: any, config?: AxiosRequestConfig): Promise<Page<SituacaoDoProdutoNaLoja>> =>
		await this.produtoService.buscaSituacaoDosProdutos(idLoja, params, config)

	buscarVariantePorAtributos = async (produtoPaiId: string, atributos: AtributosDaVariante[]): Promise<Variante> =>
		await this.produtoService.buscarVariantePorAtributos(produtoPaiId, atributos)

	findConsultaPrecoDeProduto = async (produtoId: string, lojaId: string): Promise<ConsultaPrecoDeProduto[]> =>
		await this.produtoService.findConsultaPrecoDeProduto(produtoId, lojaId)
	
	findConsultaEstoqueDeProduto = async (produtoId: string, lojaId: string): Promise<Record<string, any>[]> =>
		await this.produtoService.findConsultaEstoqueDeProduto(produtoId, lojaId)

	async validaEstoqueNegativando(identificadores: string[], loja: string, quantidades: number[]): Promise<Produto> {
		return this.depositoService.validaEstoqueNegativando(identificadores, loja, quantidades)
	}
}
