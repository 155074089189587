import { DashboardsServiceAdapter } from "@/usecases/dashboard/adapter/DashboardServiceAdapter";
import { injectable } from "inversify-props";
import { listarDashs, listarDashPorPerfil, salvarDashsPerfis, findDashGeral } from '@/api/dashboard'
import { CapaDashboardPerfil } from "@/models/views/CapaDashboard";
import { AxiosRequestConfig } from "axios";

@injectable()
export class DashboardService implements DashboardsServiceAdapter {
	findDashGeral = async (params: any, config?: AxiosRequestConfig) => await findDashGeral(params, config)
	listarDashs = async () => await listarDashs()
	listarDashPorPerfil = async (perfilId: string) => await listarDashPorPerfil(perfilId);
	salvarDashsPerfis = async (dashboard: CapaDashboardPerfil[]) => await salvarDashsPerfis(dashboard)
}