import { Inject } from 'inversify-props'
import type { PerfilDeUsuarioServiceAdapter } from './adapter'
import { Perfil } from '@/models'

export class CreatePerfilUseCase {
	@Inject('PerfilDeUsuarioServiceAdapter')
	private perfilService!: PerfilDeUsuarioServiceAdapter

	create = async (perfil: Perfil): Promise<Perfil> =>
		await this.perfilService.create(perfil)
}
