import request from "@/common/request"
import { RegistroDeCustos, Page, TotalizadoresDeRegistroDeCustos } from "@/models"
import { AxiosRequestConfig } from "axios"

const basePath = '/registros'

export const findRegistros = (params?: any, config?: AxiosRequestConfig): Promise<Page<RegistroDeCustos>> =>
	request.get(`${basePath}`, { params, ...config })

export const findPrecoDeCusto = (params?: any): Promise<number> =>
	request.get(`${basePath}/preco-de-custo`, { params })

export const createPrecoDeCusto = (registroDeCustos?: RegistroDeCustos): Promise<void> =>
	request.post(`${basePath}`, registroDeCustos)
	
export const patchPrecoDeCusto = (registroDeCustos?: RegistroDeCustos): Promise<void> =>
	request.patch(`${basePath}/atualizar`, registroDeCustos)

export const getTotaisPrecoDeCusto = (params?: any): Promise<TotalizadoresDeRegistroDeCustos> =>
	request.get(`${basePath}/totalizador`, { params })