import request from "@/common/request"
import { AnexoDosProcessos } from "@/models/anexo/AnexoDosProcessos"

const basePath = '/anexo'

export const createOuUpdate = (anexo: AnexoDosProcessos): Promise<void> =>
	request.post(`${basePath}`, anexo)

export const findAnexoByIdentificador = (identificador: string): Promise<AnexoDosProcessos> =>
	request.get(`${basePath}/${identificador}`)

export const findAnexoIdByIdentificador = (identificador: string): Promise<string> =>
	request.get(`${basePath}/id/${identificador}`)

export const createOuUpdatePublico = (anexo: AnexoDosProcessos, usuarioId: string): Promise<void> =>
	request.post(`/publico/anexo/${usuarioId}`, anexo)

export const findAnexoIdByIdentificadorPublico = (identificador: string, usuarioId: string): Promise<string> =>
	request.get(`/publico/anexo/${identificador}/${usuarioId}`)