import request from "@/common/request";
import { MotivosCancelamentoFiscal, Page } from "@/models";

export const findMotivo = async (params?: any): Promise<Page<MotivosCancelamentoFiscal>> =>
	request.get(`/motivosCancelamentoFiscal`, {
		params: {
			...params,
		},
	})


export const createMotivo = async (motivo: MotivosCancelamentoFiscal): Promise<MotivosCancelamentoFiscal> =>
	request.post(`/motivosCancelamentoFiscal`, motivo)

export const updateMotivo = async (motivo: MotivosCancelamentoFiscal): Promise<MotivosCancelamentoFiscal> =>
	request.put(`/motivosCancelamentoFiscal/${motivo.id}`, motivo)