import request from "@/common/request";
import { ListagemItensRomaneio } from "@/models/Romaneio";
import { AxiosRequestConfig } from "axios";

const basePath = '/item-romaneio'

export const findItensRomaneio = async (
	params?: any,
	config?: AxiosRequestConfig,
): Promise<ListagemItensRomaneio> => 
	request.get(`${basePath}`, { params, ...config })