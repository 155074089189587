import 'reflect-metadata'
import { injectable } from 'inversify-props'
import { RegraDeAplicacaoServiceAdapter } from '@/usecases'
import {
	getRegraDeAplicacao,
	atualizaRegraAplicacao,
	deletarRegraDeAplicacao,
	findRegraDeAplicacao,
	findRegrasDeAplicacaoByRegraImposto,
	copiarRegraDeAplicacao,
	getEstadosOrigemPorRegraAplicacao,
} from '@/api/impostos'
import { RegraDeAplicacao } from '@/models'
import { AxiosRequestConfig } from 'axios'

@injectable()
export class RegraDeAplicacaoService implements RegraDeAplicacaoServiceAdapter {
	find = async (params?: any, config?: AxiosRequestConfig) =>
		await findRegraDeAplicacao(params, config)

	findByRegraImposto = async (idRegraImposto: string, params?: any, config?: AxiosRequestConfig) =>
		await findRegrasDeAplicacaoByRegraImposto(idRegraImposto,params, config)

	get = async (id: string) => await getRegraDeAplicacao(id)

	update = async (regra: RegraDeAplicacao) => await atualizaRegraAplicacao(regra)

	delete = async (id: string) => await deletarRegraDeAplicacao(id)

	copiar = async (idRegraDestino: string, idRegraOrigem: string) => await copiarRegraDeAplicacao(idRegraDestino,idRegraOrigem)

	getEstadosOrigemPorRegraAplicacao = async (id: string) => await getEstadosOrigemPorRegraAplicacao(id)
}
