import "reflect-metadata"
import { injectable } from "inversify-props";
import { PerfilDeUsuarioServiceAdapter } from '@/usecases/perfil/adapter';
import { Perfil } from '@/models';
import { 
	get,
	findByNome,
	create,
	find,
	remove,
	update,
} from '@/api/perfis';
import { AxiosRequestConfig } from 'axios';

@injectable()
export class PerfilDeUsuarioService implements PerfilDeUsuarioServiceAdapter {
	get = async (id: string) => await get(id)
	find = async (params: any, config?: AxiosRequestConfig) => await find(params, config)
	findByNome = async (nome: string) => await findByNome(nome)
	create = async (perfil: Perfil) => await create(perfil)
	update = async (perfil: Perfil) => await update(perfil)
	remove = async (id: string) => await remove(id)
}
